import { WorkflowState } from "@/models/workflow.model";
import { SidebarTransitionState } from "@/models/transition-state.model";
import { OrganizationUser } from "@/models/user.model";

export default {
  isTransitionAvailableForThisUser
};

function isTransitionAvailableForThisUser(
  transition: SidebarTransitionState | null,
  workflowStates: WorkflowState[] | null,
  currentState: WorkflowState | null,
  currentUserId: number | null,
  canManageWorkflows: boolean
): boolean {
  if (canManageWorkflows) {
    return true;
  }
  if (
    !!currentState &&
    !!currentState.rank &&
    !!currentState.users &&
    !!transition &&
    !!workflowStates
  ) {
    // non admin users can modify the state of a task they are currently assigned to IF it is the currently active task
    if (
      transition.rank === currentState.rank &&
      transition.assignedUsers.some((e: OrganizationUser) => {
        return e.id === currentUserId;
      })
    ) {
      return true;
    }

    // non admin users can modify the state of the preceeding and succeeding tasks IF they are assigned to the currently active task
    const transitionIsWithin1StepOfCurrentState = transition.rank
      ? Math.abs(currentState.rank - transition.rank) === 1
      : false;
    if (
      transitionIsWithin1StepOfCurrentState &&
      ((currentState.users as number[]).some((e: number) => {
        return e === currentUserId;
      }) ||
        transition.assignedUsers.some((e: OrganizationUser) => {
          return e.id === currentUserId;
        }))
    ) {
      return true;
    }

    // non-admin users can modify the state of a task IF they are assigned to next task in the workflow
    const lastWorkflowState: WorkflowState | undefined = [
      ...workflowStates
    ].pop();
    const isLastTaskInWorkflow = lastWorkflowState
      ? currentState.rank === lastWorkflowState.rank
      : true;

    if (!isLastTaskInWorkflow && currentState.rank === transition.rank) {
      const nextWorkflowState = workflowStates.find((wf: WorkflowState) => {
        return currentState.rank ? wf.rank === currentState.rank + 1 : false;
      });
      if (!nextWorkflowState) {
        return false;
      }
      const userIsAssignedToNextTaskInWorkflow = nextWorkflowState.users.some(
        (user: number) => {
          return user === currentUserId;
        }
      );

      if (userIsAssignedToNextTaskInWorkflow) {
        return true;
      }
    }

    // non-admin users can modify the state of a task IF they are assigned to previous task in the workflow
    const isFirstTaskInWorkflow = currentState.rank === 1;
    if (!isFirstTaskInWorkflow && currentState.rank === transition.rank) {
      const prevWorkflowState = workflowStates.find((wf: WorkflowState) => {
        return currentState.rank ? wf.rank === currentState.rank - 1 : false;
      });
      if (!prevWorkflowState) {
        return false;
      }
      const userIsAssignedToPrevTaskInWorkflow = (
        prevWorkflowState.users as number[]
      ).some((user: number) => {
        return user === currentUserId;
      });
      if (userIsAssignedToPrevTaskInWorkflow) {
        return true;
      }
    }
  }
  return false;
}
