<template>
  <form @submit.prevent="handleFileUpload">
    <label class="margin-b-standard">
      <input
        type="file"
        id="file-upload-input"
        ref="file-upload-input"
        data-testing="file-upload-input"
        @change="fetchLocalFile"
      />
    </label>

    <label class="margin-b-standard">
      Name
      <Hint :type="'info'" :position="'right'">
        <p>Good naming makes it easier to find files/references later</p>
      </Hint>
      <input v-model.trim="fileUploadName" />
    </label>
    <button
      data-testing="upload-attachfile-btn"
      :disabled="!fileToUpload"
      class="btn-1 full-width submit-btn"
    >
      <span v-if="uiFilesStore.shouldBeSetAsMediaContent">Set as Media Content</span>
      <span v-else>Attach content</span>
    </button>
  </form>
</template>
<script lang="ts">
import FilesMixin from "@/mixins/files";
import Hint from "@/components/Hint.vue";
import { mapGetters } from "vuex";
import { LibraryFile } from "@/models/files.model";
import { defineComponent } from "vue";
import { mapStores } from "pinia";
import { useUIFilesStore } from "@/stores/ui-files.store";

interface Data {
  fileToUpload: any;
  fileUploadName: string;
}

export default defineComponent({
  name: "UploadForm",
  mixins: [FilesMixin],
  data(): Data {
    return {
      fileToUpload: undefined,
      fileUploadName: ""
    };
  },
  components: {
    Hint
  },
  computed: {
    ...mapStores(useUIFilesStore),
    ...mapGetters("files", ["files"])
  },
  methods: {
    focusOnFileUpload(): void {
      const firstInput = document.getElementById("file-upload-input");
      if (firstInput) {
        firstInput.focus();
      }
    },
    getUploadedFile(): LibraryFile {
      return this.files.data.find((file: LibraryFile): boolean => {
        return file.name === this.fileUploadName;
      });
    },
    fetchLocalFile(): void {
      const fileInput = this.$refs["file-upload-input"] as HTMLInputElement;
      if (fileInput.files) {
        this.fileToUpload = fileInput.files[0];
        const suggestedName =
          this.fileToUpload.name.substr(
            0,
            this.fileToUpload.name.lastIndexOf(".")
          ) || this.fileToUpload.name;
        this.fileUploadName = this.fileUploadName
          ? this.fileUploadName
          : suggestedName;
      }
    },
    uploadFileToSection(): void {
      if (this.uiFilesStore.sectionToAttachFile) {
        const formData = new FormData();
        formData.append("file", this.fileToUpload);
        formData.append("name", this.fileUploadName);
        formData.append("product_id", `${this.uiFilesStore.sectionToAttachFile.product_id}`);
        formData.append("section_id", `${this.uiFilesStore.sectionToAttachFile.id}`);
        if (this.uiFilesStore.shouldBeSetAsMediaContent) {
          formData.append("is_media_content", "true");
        }
        const payload = {
          formData: formData,
          productId: this.uiFilesStore.sectionToAttachFile.product_id
        };
        this.uploadFile(payload);
      }
    },
    uploadFileToProduct(): void {
      if (this.uiFilesStore.productToAttachFile && this.uiFilesStore.fileAttachmentType) {
        const formData = new FormData();
        formData.append("file", this.fileToUpload);
        formData.append("name", this.fileUploadName);
        formData.append("product_id", `${this.uiFilesStore.productToAttachFile.id}`);
        formData.append("attachment_type", `${this.uiFilesStore.fileAttachmentType}`);
        const payload = {
          formData: formData,
          productId: this.uiFilesStore.productToAttachFile.id
        };
        this.uploadFile(payload);
      }
    },
    handleFileUpload(): void {
      if (this.uiFilesStore.sectionToAttachFile) {
        this.uploadFileToSection();
      } else {
        this.uploadFileToProduct();
      }
      this.uiFilesStore.closeAttachFileModal();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.focusOnFileUpload();
    });
  }
});
</script>
<style lang="scss" scoped></style>
