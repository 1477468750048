<template>
  <div>
    <span class="label">Tags:</span>
    <ul class="tags-list list-disk" data-testing="tags-list">
      <li v-for="tag in tags" :key="tag.id">
        <span class="tag__name">{{ tag.name }}</span>
        <button
          v-if="canEditContent"
          class="btn-3 float-right"
          @click.once="$emit('removeTag', tag.id)"
          :data-testing="`remove-concept-tag-${tag.id}`"
        >
          <SVGIcon :path="mdiDelete" />
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { SectionConcepts } from "@/models/product-section.model";
import { mdiDelete } from "@mdi/js";

export default defineComponent({
  name: "TagsList",
  data() {
    return {
      mdiDelete
    };
  },
  props: {
    tags: {
      type: Array as PropType<SectionConcepts[]>,
      default: () => []
    },
    canEditContent: {
      type: Boolean,
      default: false,
      required: true
    }
  }
});
</script>
