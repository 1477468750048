import axios from "axios";
import axiosErrorHandler from "@/utils/axios-error-handler";
import ls from "@/services/local-storage";

const DAO = () => {
  const dao = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: false,
    headers: {
      Authorization: "Bearer " + ls.getToken()
    }
  });
  dao.interceptors.response.use(
    response => response,
    error => axiosErrorHandler(error)
  );
  return dao;
};

export default DAO;
