<template>
  <div id="app" data-testing="main-container">
    <component :is="layout" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PopulateStoreMixin from "@/mixins/populate-store";
import ls from "@/services/local-storage";
import API from "@/api/index";
import { mapActions, mapGetters } from "vuex";
import AuthLayout from "@/layouts/AuthLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import { TrackJS } from "trackjs";
import RouteManagementMixin from "@/mixins/route-management";

export default defineComponent({
  components: {
    AuthLayout,
    MainLayout
  },
  mixins: [PopulateStoreMixin, RouteManagementMixin],
  async mounted() {
    TrackJS.install({
      token: "b784bd4d4eac4f46a055b4b1771f56a7",
      application: "thoth"
    });
    if (this.$router.currentRoute) {
      if (this.isAuthPage) {
        return;
      }
      if (ls.isTokenSet()) {
        const isValid = await this.validateAPIToken();
        if (isValid) {
          this.populateStoreWithGeneralData();
          if (!this.currentUser.id) {
            // @ts-ignore
            await this.$store.dispatch("currentUser/populateCurrentUser");
            TrackJS.configure({
              userId: `${this.currentUser.id}`
            });
            TrackJS.addMetadata("logged-in-via", "browser");
          }
        } else {
          ls.clearToken();
          this.clearLoggedIn();
          await this.$router.push({ name: "login" });
        }
      }
    }
  },
  computed: {
    ...mapGetters("currentUser", ["currentUser"]),
    ...mapGetters("products", ["productSummaries"]),
    layout(): string {
      if (this.$route.meta) {
        return (this.$route.meta.layout || "main") + "-layout";
      }
      return "main-layout";
    },
    isAuthPage(): boolean {
      return this.$route.name === "authStart" || this.$route.name === "authEnd";
    }
  },
  methods: {
    validateAPIToken(): Promise<boolean> {
      return API.product.getAll().then(
        function () {
          return true;
        },
        function (error) {
          return error.response.status !== 401;
        }
      );
    },
    ...mapActions("currentUser", ["clearLoggedIn"])
  }
});
</script>

<style lang="scss"></style>
