export default {
  contentTemplates: {
    folder: 2,
    activity: 3,
    presentation: 8,
    book: 9,
    manuscript: 10,
    graphic: 11,
    illustration: 12,
    layout: 13,
    proof: 14,
    MCQMode: 15,
    QTIQuestionBank: 16,
    isAContentObject(categoryId: number): boolean {
      return 2 === categoryId;
    }
  },
  sectionIcons: {
    icon0: "mdiImageOutline",
    icon2: "mdiFolder",
    icon3: "mdiCheckboxMultipleBlank",
    icon8: "mdiMonitor",
    icon9: "mdiBookOpenVariant",
    icon10: "mdiFileWord",
    icon11: "mdiDrawing",
    icon12: "mdiFileImage",
    icon13: "mdiPencilRuler",
    icon14: "mdiFilePdfBox",
    icon15: "mdiFormatListCheckbox",
    icon16: "mdiFormatListNumbered"
  },
  mcqToolId: 3,
  htmlToolId: 5,
  authoringTools: [
    {
      name: "No Authoring Tool Selected",
      id: 0,
      key: "noToolSelected"
    },
    {
      name: "QTI question bank",
      id: 1,
      key: "qtiQuestionBank"
    },
    // Note: DITA not enabled yet
    // {
    //   name: "DITA document",
    //   id: 2
    // },
    {
      name: "MCQ Mode",
      id: 3,
      key: "McqMode"
    },
    {
      name: "External content",
      id: 4,
      key: "externalContent"
    },
    {
      name: "HTML Editor",
      id: 5,
      key: "htmlEditor"
    }
  ],
  metadataTypes: [
    {
      id: 0,
      name: "Text Free",
      title: "Freetext"
    },
    {
      id: 1,
      name: "Single Option",
      title: "Select single option"
    },
    {
      id: 2,
      name: "Multiple Options",
      title: "Select multiple options"
    }
  ],
  bulkEditOptions: {
    workflowState: {
      id: "1",
      name: "Update workflow state"
    }
  },
  userInterfaceFeatures: {
    assetManagementTier1: {
      id: 3,
      index: 0,
      title: "Asset Management Tier 1"
    },
    assetManagementTier2: {
      id: 4,
      index: 1,
      title: "Asset Management Tier 2"
    },
    workflowManagement: {
      id: 5,
      index: 2,
      title: "Workflow Management"
    },
    contentAuthoringTier1: {
      id: 6,
      index: 3,
      title: "Content Authoring Tier 1"
    },
    contentAuthoringTier2: {
      id: 7,
      index: 4,
      title: "Content Authoring Tier 2"
    },
    curriculumAuthoring: {
      id: 2,
      index: 5,
      title: "Curriculum Authoring"
    }
  },
  isQTIAuthoringToolId(toolId: number): boolean {
    return toolId === 1;
  },
  isMCQAuthoringToolId(toolId: number): boolean {
    return toolId === 3;
  },
  isHTMLAuthoringToolId(toolId: number): boolean {
    return toolId === 5;
  },
  isExternalContentAuthoringToolId(toolId: number): boolean {
    return toolId === 4;
  },
  productFileAttachmentTypes: {
    managerial: 1,
    editorial: 2
  },
  subjects: [
    "Maths",
    "Home Economics",
    "History",
    "Geography",
    "English",
    "Irish"
  ],
  markets: ["Leaving Cert", "Junior Cert", "Primary"],
  productPermissions: {
    editContentStructure: 2,
    approveContentObjects: 3,
    authorContentObjects: 4,
    editPermissions: 5,
    viewPermissions: 8
  },
  orgPermissions: {
    manageUsers: 7,
    manageProducts: 6,
    manageWorkflows: 3
  },
  featuresPermissions: {
    manageProgrammes: 1,
    manageCurriculums: 2
  },
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ],
  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  productTypes: [
    "Textbook",
    "Workbook",
    "Manual/Guide",
    "Digital-Teacher",
    "Digital-Student"
  ],
  deliveries: ["Print", "Print & Ebook", "Online"],
  statuses: ["Draft", "Active", "Archive"],
  userTypes: [
    {
      id: 1,
      name: "External"
    },
    {
      id: 2,
      name: "Internal"
    }
  ],
  xAPITypes: [
    "",
    "http://adlnet.gov/expapi/activities/question",
    "http://adlnet.gov/expapi/activities/survey",
    "http://id.tincanapi.com/activitytype/survey",
    "http://adlnet.gov/expapi/activities/assessment",
    "http://adlnet.gov/expapi/activities/media",
    "http://adlnet.gov/expapi/activities/simulation",
    "http://adlnet.gov/expapi/activities/document"
  ],
  sectionsToSkip: ["Folder", "Generic product"],
  adaptemyNamespace: "https://api.adaptemy.io",
  xAPI_Content: "/content/",
  xAPI_ContentMetadata: "/contentmetadata/",
  xAPI_HTML: "/contentmetadata/display-html",
  xAPIPathExternalLink: "/external-link",
  xAPIConcept: "/concept",
  xAPIQuestion: "question",
  xAPIQuestionHTML: "questionHTML",
  xAPIQuestionSolutionHTML: "solutionHTML",
  xAPIQuestionHintHTML: "hintHTML",
  xAPIQuestionAnswersHTML: "correctAnswers",
  xAPIComponentName: "component-name",
  xAPIDifficulty: "difficulty",
  tasksStatuses: [
    "complete",
    "current",
    "current late",
    "upcoming",
    "upcoming late"
  ],
  taskStatusesDisplayName: {
    current: "In progress",
    complete: "Complete",
    upcoming: "Upcoming",
    def: "not set"
  },
  notSelected: "Not selected",
  assetsGeneralColumns: {
    workflowState: "Workflow State",
    assignedTo: "Assigned To",
    assetsName: "Assets Name",
    thothId: "Thoth Id",
    externalId: "External Id",
    externalPath: "External Path",
    thothPath: "Thoth Path"
  },

  //Success
  deployWasSuccess: "was deployed successfully.",
  deploySuccess: "Deploy successful",
  uploadWasSuccess: "was uploaded successfully.",
  uploadSuccess: "Upload successful",

  //ERRORS
  productNamespaceErrorMessage: "Please enter valid URL",
  userPasswordLengthError: "The password must contain at least 6 characters",
  userNameLengthError: "The username must contain at least 1 character",
  userFirstNameLengthError: "The firs name must contain at least 1 character",
  userLastNameLengthError: "The last name must contain at least 1 character",
  userTypeError: "Select the user type",
  friendlyUrlPattern: /^[a-z0-9-]{1,}$/,
  friendlyUrlReplacePattern: /[^a-z0-9-]/g,
  sgNameSpace: "https://api-sg-demo.adaptemy.io",
  imageFileTypeError: "Please select png, jpg, bmp or gif file type",
  xmlFileTypeError: "Please select xml file type",
  contentWithError: "Show content with validation errors",
  allContent: "Show all content",
  previewContentError: "Unable to preview content",
  deployError: {
    empty: "Nothing to deploy",
    content: "Error deploying the content objects",
    deploy: "There was an error deploying",
    curriculum: "Curriculum is not deployed."
  },
  curriculum: {
    deployError: "Error deploying the curriculum",
    uploadError: "Error uploading the curriculum"
  },
  section: {
    descriptionError: "Section description not updated",
    nameNodeUpdated: "Section name not updated",
    childNodeContent: "A child node of this content contains an error",
    childNodeReview: "A child node of this content needs review",
    notAdded: "Section is not added",
    nonUniqueExternalId:
      "An external id with that value already exists. External id must be unique.",
    notUpdated: "Section not updated"
  },
  productError: {
    notUpdated: "product not updated",
    notDeleted: "product not deleted",
    notCreated: "product not created",
    nameSpaceRequired: "Product Namespace is required",
    loading: "error loading product",
    notFound: "product not found"
  },
  navigationError: "NavigationDuplicated",
  unexpectedError: "Unexpected error",
  loginError: "error logging in",
  poorInternetConn: "",
  failure: "Failure",
  unhandledError: "Unhandled fetch error:",
  consentFailed: "Consent failed:",
  passwordError: {
    passwordLength: "Password must be at least 6 charachters long",
    update: "password not updated"
  },
  error: "error",
  errors: "errors",
  errorLoadingCurriculumTool: "There was an error loading this curriculum",
  errLoadingCurriculum: "error loading curriculums",
  errLoadingMetadata: "error loading assets metadata",
  displaySaveErr_1:
    "There was an error saving your work. This is typically caused by a weak internet connection.",
  displaySaveErr_2: "If the problem persists, you can",
  displaySaveErr_3: "the page to view your saved work.",
  unknownError: "Something went wrong. Try again",
  errorsOccurred: "Errors occurred when updating",

  //xAPI Errors
  xApiTypeNotSet: "xAPI type is not set for",
  invalidExternalLink: "Media content is not valid URL",
  externalLinkNotSet: "Media content is not set",
  metadataFriendlyURlNotSet: "Friendly URl is not set for metadata",
  duplicateMetadataFriendlyUrl:
    'Duplicate metadata "Friendly URL" for metadata',
  metadataValueMissing: "Value missing for metadata",
  noQuestionsAuthored: "No questions authored yet",
  questionsContainErrors: "Questions contain errors",
  noHTMLDocumentAuthored: "No HTML document authored yet",

  //Assets Import Errors
  assetsImport: {
    unassignedColumn(columnName: string): string {
      return `Please map <strong class="error">${columnName}</strong> to a column`;
    },
    columnSelectedTwice(columnName: string): string {
      return `The column <strong class="error">${columnName}</strong>  has been assigned to multiple columns, please assign to a single column.`;
    },
    csvFileTypeError: "Please select a csv file.",
    metadataNotFound: "Metadata not found",
    metadataNotFoundResolution: "Add metadata to asset template.",
    metadataInvalidValue: "Invalid value:",
    metadataInvalidValueResolution:
      "Add option to metadata or remove from csv.",
    userNotFound: "User not found:",
    userNotFoundResolution:
      "Ensure user exists and has permission to access product.",
    workflowStateNotFound: "Workflow state not found:",
    workflowStateNotFoundResolution:
      "Ensure workflow state exists in template.",
    assetItNotFound: "Asset not found:",
    assetItNotFoundResolution:
      "Ensure id matches an asset in this content template.",
    duplicateExternalId: "Duplicate external id:",
    duplicateExternalIdResolution:
      "If updating an existent asset, ensure both thoth_id and external_id match an existing asset. If adding a new asset, ensure external_id is unique to this product and thoth_id is empty.",
    invalidProductName: "Invalid product name in path:",
    invalidProductNameResolution: "Update product name in csv",
    invalidPath: "Invalid Thoth path to asset:",
    invalidPathResolution:
      "Ensure path exists in the product or remove from csv",
    invalidExternalPath: "Invalid External path to asset:",
    invalidExternalPathResolution:
      "Ensure path exists in the product or remove from csv",
    externalPathNotSet:
      "Invalid External path to asset: some part of the path are not set",
    externalPathNotSetResolution:
      "Ensure all assets in this template have an external id or remove external path from csv",
    mismatchedPaths: "External path is not the same as Thoth path",
    mismatchedPathsResolution: "Update external path or Thoth path in csv",
    success: "Assets have been successfully imported"
  },

  // Update internal path
  internalPath: {
    success: "Internal path has been successfully changed"
  }
};
