// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  generateMultipleFilter,
  generateFilterQuery
} from "@/utils/report-table-query";
import {
  ReportTableComparison,
  ReportTableFilter,
  ReportTableFilters
} from "@/models/report-table.model";
import { TaskStatus } from "@/models/task.model";
import { mapGetters } from "vuex";

const GetTasksQueryMixin = {
  computed: {
    ...mapGetters("currentUser", ["currentUser"])
  },
  methods: {
    getTasksQuery(): ReportTableFilters {
      const queryRow = generateFilterQuery([
        {
          field: "responsible",
          value: this.currentUser.displayName,
          comparison: ReportTableComparison.DEFAULT
        },
        ...this.getTaskStatusesQuery()
      ]);
      return { filters: queryRow };
    },
    getTaskStatusesQuery(): ReportTableFilter[] {
      const statuses = Object.values(TaskStatus).filter(status => {
        return (
          status !== TaskStatus.FINAL_STATE && status !== TaskStatus.COMPLETE
        );
      });
      return generateMultipleFilter(
        "statusWithIcon",
        statuses,
        ReportTableComparison.MATCHES
      );
    }
  }
};
export default GetTasksQueryMixin;
