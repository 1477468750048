import {
  AvailableFeature,
  AvailableFeaturesSummary
} from "@/models/available-features.model";
import {
  AvailablePermissionsSummary,
  PermissionPayload
} from "@/models/available-permissions.model";
import {
  OrganizationUser,
  OrganizationUserSummary,
  UserPermission
} from "@/models/user.model";
import cnst from "@/utils/constants";
import axios, { AxiosError, AxiosResponse } from "axios";
import API from "@/api/index";
import { ActionContext, Commit, Dispatch } from "vuex";
import {
  GetAllResponse,
  GetAllResponseUser,
  PasswordToChange,
  UserToCreate,
  UserToUpdate
} from "@/models/api/users.model";
import { OrganizationState } from "@/models/store/organization-state.model";
import { RootState } from "@/models/store/root-state.model";
import { Organization, OrganizationSummary } from "@/models/organization.model";
import { OrganizationResponse } from "@/models/api/organization.model";

export default {
  namespaced: true,
  state: {
    users: {
      data: [],
      loading: true,
      updating: false
    },
    availableOrgPermissions: {
      loading: true,
      data: []
    },
    organization: {
      loading: true,
      data: {}
    }
  } as unknown as OrganizationState,
  getters: {
    users(state: OrganizationState): OrganizationUserSummary {
      return state.users;
    },
    availableOrgPermissions(
      state: OrganizationState
    ): AvailablePermissionsSummary {
      return state.availableOrgPermissions;
    },
    availableOrgFeatures(state: OrganizationState): AvailableFeaturesSummary {
      return {
        loading: state.organization.loading,
        data: state.organization.data.features
      };
    },
    canManageProgrammes(state: OrganizationState): boolean {
      if (state.organization.loading) {
        return false;
      }
      return state.organization.data.features.some(
        (e: AvailableFeature): boolean =>
          e.id === cnst.featuresPermissions.manageProgrammes && e.enabled
      );
    },
    canManageCurriculums(state: OrganizationState): boolean {
      if (state.organization.loading) {
        return false;
      }
      return state.organization.data.features.some(
        (e: AvailableFeature): boolean =>
          e.id === cnst.featuresPermissions.manageCurriculums && e.enabled
      );
    },
    organization(state: OrganizationState): OrganizationSummary {
      return state.organization;
    }
  },
  actions: {
    populateUsers(
      context: ActionContext<OrganizationState, RootState>
    ): Promise<void> | void {
      if (context.getters.users.loading) {
        return context.dispatch("fetchUsers");
      }
    },
    fetchUsers(
      context: ActionContext<OrganizationState, RootState>
    ): Promise<void> {
      return API.user
        .getAll()
        .then((response: AxiosResponse<GetAllResponse>) => {
          context.commit("setUsers", response.data.data.users);
        });
    },
    createUser(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { username, password, firstName, lastName, type }: UserToCreate
    ): Promise<void> {
      commit("flagUsersUpdating");
      return API.user
        .create(username, password, firstName, lastName, type)
        .then(() => dispatch("fetchUsers"))
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
          } else {
            throw new Error(error.message);
          }
        });
    },
    updateUser(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: UserToUpdate
    ): Promise<void> {
      commit("flagUsersUpdating");
      return API.user.update(payload).then(() => {
        return dispatch("fetchUsers");
      });
    },
    disableUser(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      userId: number
    ): Promise<void> {
      commit("flagUsersUpdating");
      return API.user.disable(userId).then(() => {
        return dispatch("fetchUsers");
      });
    },
    changePassword(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: PasswordToChange
    ): Promise<AxiosResponse> {
      return API.user.changePassword(payload);
    },
    addUserPermission(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: PermissionPayload
    ): Promise<void> {
      commit("flagUsersUpdating");
      return API.permissions.addUserPermissions(payload).then(() => {
        return dispatch("fetchUsers");
      });
    },
    removeUserPermission(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: PermissionPayload
    ): Promise<void> {
      commit("flagUsersUpdating");
      return API.permissions.removeUserPermissions(payload).then(() => {
        return dispatch("fetchUsers");
      });
    },
    populateAvailableOrgPermissions(
      context: ActionContext<OrganizationState, RootState>
    ): Promise<void> | void {
      if (context.getters.availableOrgPermissions.loading) {
        return context.dispatch("fetchAvailableOrgPermissions");
      }
    },
    fetchAvailableOrgPermissions(
      context: ActionContext<OrganizationState, RootState>
    ): Promise<void> | void {
      return API.permissions
        .getAvailableOrgPermissions()
        .then((response: AxiosResponse<GetAllResponse>) => {
          context.commit(
            "setAvailableOrgPermissions",
            response.data.data.available_permissions
          );
        });
    },
    flagUsersUpdating({ commit }: { commit: Commit }): void {
      commit("flagUsersUpdating");
    },
    populateOrganization(
      context: ActionContext<OrganizationState, RootState>
    ): Promise<void> | void {
      if (context.getters.organization.loading) {
        return context.dispatch("fetchOrganizationData");
      }
    },
    fetchOrganizationData(
      context: ActionContext<OrganizationState, RootState>
    ): Promise<void> {
      return API.organization
        .get()
        .then((response: AxiosResponse<OrganizationResponse>) => {
          context.commit("setOrgData", response.data.data);
        });
    }
  },
  mutations: {
    setUsers(state: OrganizationState, rawUsers: GetAllResponseUser[]): void {
      state.users.loading = false;
      state.users.updating = false;
      state.users.data = rawUsers.map(
        (rawUser: GetAllResponseUser): OrganizationUser => {
          return {
            id: rawUser.id,
            firstName: rawUser.firstname,
            lastName: rawUser.lastname,
            displayName: rawUser.firstname + " " + rawUser.lastname,
            isEnabled: rawUser.is_enabled,
            allocatedPermissions: rawUser.allocated_permissions,
            username: rawUser.username,
            type: rawUser.type
          };
        }
      );
    },
    flagUsersUpdating(state: OrganizationState): void {
      state.users.updating = true;
    },
    setAvailableOrgPermissions(
      state: OrganizationState,
      data: UserPermission[]
    ): void {
      state.availableOrgPermissions.data = data;
      state.availableOrgPermissions.loading = false;
    },
    setOrgData(state: OrganizationState, data: Organization): void {
      state.organization.data = data;
      state.organization.loading = false;
    }
  }
};
