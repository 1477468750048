import { FileStore, LibraryFile } from "@/models/files.model";
import API from "@/api/index";
import { FileState } from "@/models/store/file-store.model";
import { ActionContext, Commit, Dispatch } from "vuex";
import { RootState } from "@/models/store/root-state.model";
import { AxiosResponse } from "axios";
import {
  FileToUpsertResponse,
  FileToRename,
  GetAllFilesResponse,
  OnlineFileToAttachToSection,
  OnlineFileToAttachToProduct
} from "@/models/api/files.model";

export default {
  namespaced: true,
  state: {
    files: {
      loading: true,
      updating: false,
      data: []
    } as FileStore
  },
  getters: {
    files(state: FileState): FileStore {
      return {
        loading: state.files.loading,
        updating: state.files.updating,
        data: state.files.data.filter((f: LibraryFile) => !f.deleted_on)
      };
    }
  },
  actions: {
    fetchFiles(context: ActionContext<FileState, RootState>): Promise<void> {
      return API.file
        .getAll()
        .then((response: AxiosResponse<GetAllFilesResponse>) => {
          context.commit("setFiles", response.data.data);
        });
    },
    attachOnlineFile(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: OnlineFileToAttachToSection | OnlineFileToAttachToProduct
    ): Promise<AxiosResponse<FileToUpsertResponse>> {
      commit("flagFilesUpdating");
      return API.file
        .upsert(payload)
        .then(async (response: AxiosResponse<FileToUpsertResponse>) => {
          await dispatch("fetchFiles");
          return response;
        });
    },
    renameFile(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: FileToRename
    ): Promise<AxiosResponse<FileToUpsertResponse>> {
      commit("flagFilesUpdating");
      return API.file
        .upsert(payload)
        .then(async (response: AxiosResponse<FileToUpsertResponse>) => {
          await dispatch("fetchFiles");
          return response;
        });
    },
    uploadNewFile(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: FormData
    ): Promise<void> {
      commit("flagFilesUpdating");
      return API.file.upload(payload).then(() => {
        return dispatch("fetchFiles");
      });
    },
    uploadNewFileVersion(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: FormData
    ): Promise<void> {
      commit("flagFilesUpdating");
      return API.file.upload(payload).then(() => {
        return dispatch("fetchFiles");
      });
    },
    flagFilesUpdating({ commit }: { commit: Commit }): void {
      commit("flagFilesUpdating");
    },
    deleteFile(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      fId: number
    ): Promise<void> {
      commit("flagFilesUpdating");
      return API.file.remove(fId).then(() => {
        return dispatch("fetchFiles");
      });
    }
  },
  mutations: {
    setFiles(state: FileState, files: LibraryFile[]): void {
      state.files.loading = false;
      state.files.updating = false;
      state.files.data = files || [];
    },
    flagFilesUpdating(state: FileState): void {
      state.files.updating = true;
    }
  }
};
