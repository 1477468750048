import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1c041090"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal-card",
  "data-testing": "upload-file-card"
}
const _hoisted_2 = { class: "modal-card-header" }
const _hoisted_3 = { class: "margin-b-half" }
const _hoisted_4 = { class: "modal-card-footer" }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, [
        _createTextVNode(" Upload a new version of "),
        _createElementVNode("i", null, _toDisplayString(_ctx.file.name), 1)
      ])
    ]),
    _createElementVNode("form", {
      id: "upload-file-version",
      class: "modal-card-content",
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleFileUpload && _ctx.handleFileUpload(...args)), ["prevent"]))
    }, [
      _createElementVNode("label", _hoisted_3, [
        _createTextVNode(" Select an Attachment "),
        _createElementVNode("input", {
          type: "file",
          id: "file-version-input",
          ref: "file-version-input",
          "data-testing": "file-version-input",
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.fetchLocalFile && _ctx.fetchLocalFile(...args)))
        }, null, 544)
      ])
    ], 32),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        "data-testing": "upload-file-version-btn",
        disabled: !_ctx.fileToUpload,
        class: "btn-1 full-width",
        form: "upload-file-version"
      }, " Upload new version ", 8, _hoisted_5)
    ])
  ]))
}