import { createStore } from "vuex";

import productsModule from "@/store/modules/products";
import organizationsModule from "@/store/modules/organizations";
import currentUserModule from "@/store/modules/current-user";
import filesModule from "@/store/modules/files";

const store = createStore({
  modules: {
    products: productsModule,
    organizations: organizationsModule,
    currentUser: currentUserModule,
    files: filesModule
  }
});
export default store;
