<template>
  <RightSidebar
    @closeSidebar="uiContentStore.closeNewSectionSidebar"
    :is-sidebar-open="!!uiContentStore.newSectionDetails"
    :close-btn-data-testing="'close-curriculum-sidebar'"
    :is-updating="false"
    :css-classes="['curriculum-sidebar']"
    :is-close-btn-visible="true"
    :data-testing="'curriculum-sidebar'"
  >
    <template v-slot:title>
      <input
        id="add-cs-name"
        class="ghost-input"
        ref="section-name"
        aria-label="Enter name"
        v-autofocus
        v-model.trim="newSectionName"
        data-testing="add-content-section"
        placeholder="Enter name ..."
      />
    </template>
    <template v-slot:subtitle>
      <div class="info-cards">
        <span class="inline-info"> {{ type.name }} </span>
      </div>
    </template>
    <template v-slot:body>
      <div class="margin-b-standard">
        <h3 class="inline-block">Location:&nbsp;</h3>
        <span>{{ product.name }}</span>
        <span v-for="section in path" :key="section"> / {{ section }} </span>
      </div>
      <div class="margin-b-standard">
        <h3>Instructions</h3>
        <textarea
          data-testing="add-instruction"
          v-model="instruction"
        ></textarea>
      </div>
      <div>
        <button
          class="full-width btn-1 create-cs"
          type="button"
          :disabled="!newSectionName || isSubmitting"
          data-testing="add-content-section-btn"
          @click="addContentSection"
        >
          {{ "Add " + type.name }}
        </button>
      </div>
    </template>
  </RightSidebar>
</template>

<script lang="ts">
import RightSidebar from "@/components/right-sidebars/ui/RightSidebar.vue";
import { mapActions, mapGetters } from "vuex";
import csHelper from "@/utils/product/content-structure-helpers";
import FeatureFlagsMixin from "@/mixins/feature-flags";
import { GeneratedContentStructure } from "@/models/content-structure.model";
import { ProductItem } from "@/models/product.model";
import { ContentTemplate } from "@/models/content-templates.model";
import cnst from "@/utils/constants";
import UserInterfaceFeatureFlaggerMixin from "@/mixins/user-interface-feature-flagger";
import { defineComponent } from "vue";
import { mapStores } from "pinia";
import { useUIContentStore } from "@/stores/ui-content.store";

const NEW_CONTENT_NAME = "Placeholder";

interface Data {
  newSectionName: string;
  instruction: string;
  isSubmitting: boolean;
}

export default defineComponent({
  name: "CreateSection",
  mixins: [FeatureFlagsMixin, UserInterfaceFeatureFlaggerMixin],
  data(): Data {
    return {
      newSectionName: NEW_CONTENT_NAME,
      instruction: "",
      isSubmitting: false
    };
  },
  components: {
    RightSidebar
  },
  computed: {
    ...mapStores(useUIContentStore),
    structure(): GeneratedContentStructure {
      return this.contentStructures.data.find(
        (e: GeneratedContentStructure): boolean => {
          return !!this.uiContentStore.newSectionDetails && e.product_id === this.uiContentStore.newSectionDetails.productId
        }
      );
    },
    product(): ProductItem {
      return (
        this.productDetails.data.find(
          (e: ProductItem): boolean => {
            return !!this.uiContentStore.newSectionDetails && e.id === this.uiContentStore.newSectionDetails.productId
          }
        ) || ({} as ProductItem)
      );
    },
    path(): string[] {
      if (this.structure && this.structure.rootSection && this.uiContentStore.newSectionDetails) {
        const base =
          csHelper.getPathToSection(
            this.structure.rootSection,
            this.uiContentStore.newSectionDetails.parentId
          ) || [];
        const parent = csHelper.findDescendentSection(
          this.structure.rootSection,
          this.uiContentStore.newSectionDetails.parentId
        );
        return parent && parent.isRoot
          ? base
          : base.concat(parent ? parent.name : "");
      }
      return [];
    },
    type(): ContentTemplate {
      return (
        this.product.component_definitions.find(
          (e: ContentTemplate): boolean => {
            return !!this.uiContentStore.newSectionDetails && e.id === this.uiContentStore.newSectionDetails.componentId
          }
        ) || ({} as ContentTemplate)
      );
    },
    isWorkflowManagementUserFeatureEnabled(): boolean {
      return this.uiContentStore.newSectionDetails?.productId
        ? this.isWorkflowManagementFeatureEnabled(
            this.uiContentStore.newSectionDetails.productId
          )
        : false;
    },
    ...mapGetters("products", ["productDetails", "contentStructures"])
  },
  methods: {
    addContentSection(): void {
      if (this.uiContentStore.newSectionDetails) {

        this.isSubmitting = true;
        this.upsertSections({
          productIds: [this.uiContentStore.newSectionDetails.productId],
          sections: [
            {
              type: this.uiContentStore.newSectionDetails.componentId,
              name: this.newSectionName,
              parent_section_id: this.uiContentStore.newSectionDetails.parentId,
              instruction: this.instruction ? this.instruction : undefined,
              usersToNotify:
                  this.ffShouldTaskNotificationsBeSent &&
                  this.isWorkflowManagementUserFeatureEnabled &&
                  this.type.workflow_states.length > 0
                      ? this.type.workflow_states[0].users
                      : []
            }
          ]
        })
            .catch((error): void => {
              this.$buefy.toast.open({
                message: `${cnst.section.notAdded}: ${error.message}`,
                type: "is-danger",
                duration: 3000
              });
            })
            .then((): void => {
              this.isSubmitting = false;
              this.newSectionName = NEW_CONTENT_NAME;
              this.instruction = "";
              this.uiContentStore.closeNewSectionSidebar();
            });
      }
    },
    ...mapActions("products", ["upsertSections"])
  },
  watch: {
    "uiContentStore.newSectionDetails"(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          const input = this.$refs["section-name"] as HTMLElement;
          input && input.focus();
        });
      }
    }
  }
});
</script>

<style lang="scss" scoped></style>
