import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  class: "files-list card-footer",
  "data-testing": "files-card-footer"
}
const _hoisted_4 = {
  "data-testing": "files-card-footer-btn-add-file",
  class: "card-footer-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Hint = _resolveComponent("Hint")!
  const _component_MediaContent = _resolveComponent("MediaContent")!
  const _component_MCQ = _resolveComponent("MCQ")!
  const _component_QTI = _resolveComponent("QTI")!
  const _component_HTMLEditor = _resolveComponent("HTMLEditor")!
  const _component_Attachments = _resolveComponent("Attachments")!
  const _component_SVGIcon = _resolveComponent("SVGIcon")!
  const _component_Collapse = _resolveComponent("Collapse")!

  return (_openBlock(), _createBlock(_component_Collapse, { "is-content-empty": _ctx.isContentEmpty }, _createSlots({
    title: _withCtx(() => [
      (!_ctx.isAuthoringTool)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Attachments"))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, "Authoring Tool")),
      (_ctx.XAPIErrors.length > 0)
        ? (_openBlock(), _createBlock(_component_Hint, {
            key: 2,
            type: 'error',
            position: 'top'
          }, {
            default: _withCtx(() => [
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.XAPIErrors, (error, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(error), 1))
                }), 128))
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.XAPIWarnings.length > 0)
        ? (_openBlock(), _createBlock(_component_Hint, {
            key: 3,
            type: 'warning',
            position: 'top'
          }, {
            default: _withCtx(() => [
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.XAPIWarnings, (error, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(error), 1))
                }), 128))
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    content: _withCtx(() => [
      (_ctx.hasExternalContentAuthoring)
        ? (_openBlock(), _createBlock(_component_MediaContent, {
            key: 0,
            section: _ctx.section,
            onPreviewAttachment: _ctx.previewAttachment
          }, null, 8, ["section", "onPreviewAttachment"]))
        : _createCommentVNode("", true),
      (_ctx.hasMCQAuthoring)
        ? (_openBlock(), _createBlock(_component_MCQ, {
            key: 1,
            section: _ctx.section,
            "is-content-authoring-tier2-feature-enabled": 
          _ctx.isContentAuthoringTier2UserFeatureEnabled
        
          }, null, 8, ["section", "is-content-authoring-tier2-feature-enabled"]))
        : _createCommentVNode("", true),
      (_ctx.hasQTIAuthoring)
        ? (_openBlock(), _createBlock(_component_QTI, {
            key: 2,
            section: _ctx.section,
            "is-content-authoring-tier2-feature-enabled": 
          _ctx.isContentAuthoringTier2UserFeatureEnabled
        
          }, null, 8, ["section", "is-content-authoring-tier2-feature-enabled"]))
        : _createCommentVNode("", true),
      (_ctx.hasHTMLAuthoring)
        ? (_openBlock(), _createBlock(_component_HTMLEditor, {
            key: 3,
            section: _ctx.section,
            "is-content-authoring-tier2-feature-enabled": 
          _ctx.isContentAuthoringTier2UserFeatureEnabled
        
          }, null, 8, ["section", "is-content-authoring-tier2-feature-enabled"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Attachments, {
        section: _ctx.section,
        onPreviewAttachment: _ctx.previewAttachment
      }, null, 8, ["section", "onPreviewAttachment"])
    ]),
    _: 2
  }, [
    (!_ctx.isAuthoringTool)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createElementVNode("footer", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("button", {
                  class: "btn-3",
                  type: "button",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openFileModal && _ctx.openFileModal(...args)))
                }, [
                  _createVNode(_component_SVGIcon, { path: _ctx.mdiPlus }, null, 8, ["path"]),
                  _createTextVNode(" Add attachment ")
                ])
              ])
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["is-content-empty"]))
}