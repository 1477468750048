<template>
  <router-link
    :data-testing="link.dataTesting"
    :class="['header-link', { highlighted: link.isHighlighted }]"
    :to="link.to"
    >{{ link.title }}</router-link
  >
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { HeaderNavigationLink } from "@/models/header.model";
export default defineComponent({
  name: "HeaderLink",
  props: {
    link: {
      required: true,
      type: Object as PropType<HeaderNavigationLink>
    }
  }
});
</script>
<style lang="scss" scoped>
.header-link {
  margin-right: 1rem;
  font-size: 0.9rem;
  color: $grey60;
  position: relative;
  top: 0.1875rem;
  &.highlighted,
  &:hover,
  &:focus {
    border-bottom: 0.125rem solid $primary-color-light;
    outline: none;
    color: $primary-color;
    text-decoration: none;
  }
  &.highlighted {
    &:hover,
    &:focus {
      border-bottom: 0.125rem solid $primary-color;
    }
  }
}
</style>
