<template>
  <div class="modal-card" v-if="uiContentTemplatesStore.contentTemplateForAddingMetadata">
    <div class="modal-card-header">
      <h3>{{ modalTitle }}</h3>
    </div>
    <div class="modal-card-content">
      <div class="margin-b-standard metadata-type">
        <p class="pad-0-bottom">Type:</p>
        <select
          v-model="type"
          :disabled="uiContentTemplatesStore.contentTemplateForAddingMetadata.type"
          @change="toggleOptions()"
        >
          <option
            v-for="type in metadataTypes"
            :key="type.id"
            :value="type.name"
          >
            {{ type.title }}
          </option>
        </select>
      </div>

      <div class="margin-b-standard metadata-name">
        <p class="pad-0-bottom">Name:</p>
        <input
          v-model.trim="name"
          type="text"
          :class="{ warning: nameError }"
          @input="removeNameError()"
        />
        <p class="pad-0-bottom warning" v-if="nameError">
          <SVGIcon :path="mdiAlert" />
          {{ nameError }}
        </p>
      </div>

      <div
        v-if="uiContentTemplatesStore.contentTemplateForAddingMetadata.isXapi"
        class="margin-b-standard metadata-name-url"
      >
        <p class="pad-0-bottom">URL Friendly Name:</p>
        <input
          v-model="friendlyURL"
          type="text"
          :class="{ warning: friendlyNameError }"
        />
        <p class="pad-0-bottom warning" v-if="friendlyNameError">
          <SVGIcon :path="mdiAlert" />
          {{ friendlyNameError }}</p
        >
      </div>

      <div v-if="areOptionsVisible" class="margin-b-standard metadata-options">
        <p class="pad-0-bottom">Option:</p>
        <input
          v-model="option"
          type="text"
          :class="{ warning: optionError }"
          @input="removeOptionError()"
        />
        <p class="pad-0-bottom warning" v-if="optionError">
          <SVGIcon :path="mdiAlert" />
          {{ optionError }}
        </p>
        <button class="btn-3 margin-b-standard" @click="createOption()">
          <SVGIcon :path="mdiPlus" />
          Add option
        </button>

        <ul class="pad-0-left" v-if="options.length > 0">
          <li
            v-for="option in options"
            :key="option.id"
            :class="['value', 'margin-b-half', { hidden: option.beingDeleted }]"
            ref="existing-option-item"
          >
            <input
              type="text"
              class="value-input"
              v-model="option.display_name"
              :disabled="!option.editable"
              ref="existing-option-input"
              @input="removeOptionsError()"
            />
            <button
              v-if="!option.editable"
              class="btn-3 margin-r-half"
              ref="existing-option-edit-btn"
              @click="startEditing(option)"
            >
              <SVGIcon :path="mdiPencil" />
            </button>
            <button
              v-if="option.editable"
              class="btn-3 margin-r-half"
              ref="existing-option-cancel-editing-btn"
              @click="cancelEditing(option)"
            >
              <SVGIcon :path="mdiClose" />
            </button>
            <button class="btn-3" @click="removeOption(option)">
              <SVGIcon :path="mdiDelete" />
            </button>
          </li>
        </ul>
        <p class="pad-0-bottom warning" v-if="optionsError">
          <SVGIcon :path="mdiAlert" />
          {{ optionsError }}
        </p>
      </div>
    </div>

    <div class="modal-card-footer">
      <button class="btn-2" type="button" @click="uiContentTemplatesStore.closeAddMetadataModal()">
        Cancel
      </button>
      <button
        class="btn-1"
        type="button"
        @click="
          uiContentTemplatesStore.contentTemplateForAddingMetadata.modalType === 'add'
            ? createMetadata()
            : editMetadata()
        "
      >
        Save
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import cnst from "@/utils/constants";
import { sanitizeFriendlyURL } from "@/utils/utils";
import { v4 as uuidv4 } from "uuid";
import {
  MetadataAllowedValues,
  MetadataType
} from "@/models/content-templates.model";
import { AxiosResponse } from "axios";
import { DefinitionToCreateResponse } from "@/models/api/metadata.model";
import { mdiAlert, mdiDelete, mdiClose, mdiPencil, mdiPlus } from "@mdi/js";
import { mapStores } from "pinia";
import { useUIContentTemplatesStore } from "@/stores/ui-content-template.store";
import { ContentTemplateForEditingMetadata } from "@/models/store/ui-state/content-template-for-adding-metadata.model";
import { useAssetsMetadataStore } from "@/stores/assets-metadata.store";

interface Option {
  beingDeleted: boolean;
  created_by?: number;
  created_on?: number;
  display_name: string;
  display_order: number;
  editable: boolean;
  id: number | string;
  isNew: boolean;
  metadata_definition_id?: number;
  oldValue?: string;
  updated_by?: number;
  updated_on?: number;
  oldDisplayName: string;
}

interface Data {
  name: string;
  friendlyURL: string;
  nameError: string;
  friendlyNameError: string;
  metadataTypes: MetadataType[];
  type: string;
  newDefinitionId: number | undefined;
  areOptionsVisible: boolean;
  option: string;
  optionError: string;
  optionsError: string;
  options: Option[];
  mdiAlert: string;
  mdiDelete: string;
  mdiClose: string;
  mdiPencil: string;
  mdiPlus: string;
}

const TEXT_FREE = "Text Free";

export default defineComponent({
  name: "AddMetadata",
  data(): Data {
    return {
      name: "",
      friendlyURL: "",
      nameError: "",
      friendlyNameError: "",
      metadataTypes: cnst.metadataTypes,
      type: TEXT_FREE,
      newDefinitionId: undefined,
      areOptionsVisible: false,
      option: "",
      optionError: "",
      optionsError: "",
      options: [],
      mdiAlert,
      mdiDelete,
      mdiClose,
      mdiPencil,
      mdiPlus
    };
  },
  computed: {
    ...mapStores(useUIContentTemplatesStore, useAssetsMetadataStore),
    modalTitle(): string {
      return this.uiContentTemplatesStore.contentTemplateForAddingMetadata &&
        this.uiContentTemplatesStore.contentTemplateForAddingMetadata.modalType === "edit"
        ? "Edit Metadata"
        : "Add Metadata";
    }
  },
  methods: {
    ...mapActions("products", [
      "createMetadataDefinition",
      "updateMetadataDefinition",
      "createMetadataAllowedValues",
      "deleteMetadataAllowedValues",
      "updateMetadataAllowedValues"
    ]),
    toggleOptions(): void {
      this.type !== TEXT_FREE
        ? (this.areOptionsVisible = true)
        : (this.areOptionsVisible = false);
    },
    createMetadata(): void {
      const template = this.uiContentTemplatesStore.contentTemplateForAddingMetadata;
      if (template) {
        if (this.isValid()) {
          return;
        }

        const payload = {
          metadata: {
            section_type_id: template.sectionTypeId,
            name: this.name,
            type: this.type,
            url_name: this.friendlyURL
          },
          productId: template.productId
        };
        this.createMetadataDefinition(payload).then(
            (response: AxiosResponse<DefinitionToCreateResponse>) => {
              this.newDefinitionId = response.data.data.id;
              if (this.type !== TEXT_FREE) {
                this.createOptions();
              }
              if (this.$route.name === "assets") {
                this.assetsMetadataStore.isAssetsMetadataUpdating = true;
                this.assetsMetadataStore.populateAssetsMetadata(template.sectionTypeId);
              }
              this.uiContentTemplatesStore.closeAddMetadataModal();
            }
        );
      }
    },
    async editMetadata(): Promise<unknown> {
      const template = this.uiContentTemplatesStore.contentTemplateForAddingMetadata as ContentTemplateForEditingMetadata;
      if (template) {
        if (this.isValid()) {
          return;
        }

        if (
            this.name !== template.name ||
            this.friendlyURL !== template.friendlyURL
        ) {
          const payload = {
            metadata: {
              id: template.id,
              name: this.name,
              url_name: this.friendlyURL
            },
            productId: template.productId
          };
          await this.updateMetadataDefinition(payload);
        }
        if (this.type !== TEXT_FREE && this.getNewOptions().length > 0) {
          this.createOptions();
        }
        if (this.type !== TEXT_FREE && this.getEditableOptions().length > 0) {
          await Promise.all(
              this.getEditableOptions()
                  .filter((option: Option): boolean => {
                    return !option.isNew;
                  })
                  .map((option: Option): Promise<void> => {
                    const payload = {
                      value: {
                        id: option.id,
                        display_name: option.display_name,
                        display_order: option.display_order
                      },
                      productId: template.productId
                    };
                    return this.updateMetadataAllowedValues(payload);
                  })
          );
        }
        if (this.type !== TEXT_FREE && this.getBeingDeletedOptions().length > 0) {
          await Promise.all(
              this.getBeingDeletedOptions().map((option: Option): Promise<void> => {
                const payload = {
                  id: option.id,
                  productId: template.productId
                };
                return this.deleteMetadataAllowedValues(payload);
              })
          );
        }
        if (this.$route.name === "assets") {
          this.assetsMetadataStore.isAssetsMetadataUpdating = true;
          await this.assetsMetadataStore.populateAssetsMetadata(template.sectionTypeId);
        }
        this.uiContentTemplatesStore.closeAddMetadataModal();
      }
    },
    isOptionExisting(): boolean {
      if (this.options.length > 0) {
        return this.options.some((option: Option): boolean => {
          return option.display_name === this.option;
        });
      }
      return false;
    },
    createOption(): void {
      if (!this.option) {
        this.optionError = "Option cannot be empty";
        return;
      }
      if (this.isOptionExisting()) {
        this.optionError = "This option already exists";
        return;
      }
      this.optionsError = "";

      this.options.push({
        beingDeleted: false,
        display_name: this.option,
        display_order: this.options.length + 1,
        editable: false,
        id: uuidv4(),
        isNew: true,
        oldDisplayName: this.option
      });
      this.option = "";
    },
    createOptions(): void {
      const template = this.uiContentTemplatesStore.contentTemplateForAddingMetadata;
      if (template) {
        const valuesArray = this.getNewOptions().map((option: Option) => {
          const metadataDefinitionId = this.newDefinitionId
              ? this.newDefinitionId
              : (template as ContentTemplateForEditingMetadata).id;
          return {
            display_name: option.display_name,
            metadata_definition_id: metadataDefinitionId,
            display_order: option.display_order
          };
        });

        const payload = {
          values: valuesArray,
          productId: template.productId
        };
        this.createMetadataAllowedValues(payload);
      }
    },
    removeOption(option: Option): void {
      this.cancelEditing(option);
      option.beingDeleted = true;
      if (option.isNew) {
        this.options = this.options.filter((opt: Option): boolean => {
          return opt.id !== option.id;
        });
      }
    },
    removeNameError(): void {
      this.nameError = "";
    },
    removeOptionError(): void {
      this.optionError = "";
    },
    removeOptionsError(): void {
      this.optionsError = "";
    },
    validateInput(): boolean {
      let isValid = true;
      if (!this.name) {
        this.nameError = "Name cannot be empty";
        isValid = false;
      }
      if (this.friendlyURL === "") {
        this.friendlyURL = this.name;
      }
      if (this.friendlyURL && !cnst.friendlyUrlPattern.test(this.friendlyURL)) {
        this.friendlyURL = sanitizeFriendlyURL(this.friendlyURL);
      }
      return isValid;
    },
    checkDuplicateOptions(): boolean {
      const allNames = this.options
        .filter((opt: Option): boolean => {
          return !opt.beingDeleted;
        })
        .map((option: Option): string => {
          return option.display_name.toLowerCase();
        });
      const hasDuplicates = allNames.some((item: string, index: number) => {
        return allNames.lastIndexOf(item) !== index;
      });

      if (hasDuplicates) {
        this.optionsError = "You have duplicate options";
      } else {
        this.removeOptionsError();
      }
      return hasDuplicates;
    },
    checkOptionsCount(): boolean {
      const isEnoughOptions =
        this.type !== TEXT_FREE &&
        this.options.length - this.getBeingDeletedOptions().length < 2;
      if (isEnoughOptions) {
        this.optionsError = "Please add 2 or more options";
      }
      return isEnoughOptions;
    },
    isValid(): boolean {
      return (
        this.checkDuplicateOptions() ||
        !this.validateInput() ||
        this.checkOptionsCount()
      );
    },
    getNewOptions(): Option[] {
      return this.options.filter((option: Option): boolean => {
        return option.isNew;
      });
    },
    getBeingDeletedOptions(): Option[] {
      return this.options.filter((option: Option): boolean => {
        return option.beingDeleted;
      });
    },
    getEditableOptions(): Option[] {
      return this.options.filter((option: Option): boolean => {
        return option.editable;
      });
    },
    startEditing(option: Option): void {
      option.editable = true;
    },
    cancelEditing(option: Option): void {
      option.editable = false;
      option.display_name = option.oldDisplayName;
      this.removeOptionsError();
    },
    setOptions(): void {
      const template = this.uiContentTemplatesStore.contentTemplateForAddingMetadata as ContentTemplateForEditingMetadata;
      if (template.allowedValues && template.allowedValues.length > 0) {
        this.options = template.allowedValues.map(
          (item: MetadataAllowedValues) => {
            return {
              ...item,
              isNew: false,
              editable: false,
              beingDeleted: false,
              oldDisplayName: item.display_name
            };
          }
        );
      }
    }
  },
  mounted() {
    const template = this.uiContentTemplatesStore.contentTemplateForAddingMetadata;
    if (template && template.modalType === "edit") {
      this.name = (template as ContentTemplateForEditingMetadata).name ?? "";
      this.friendlyURL = (template as ContentTemplateForEditingMetadata).friendlyURL ?? "";
      this.type = (template as ContentTemplateForEditingMetadata).type ?? TEXT_FREE;
      this.areOptionsVisible = this.type !== TEXT_FREE;
      this.setOptions();
    }
  }
});
</script>
<style lang="scss" scoped>
.modal-card {
  width: 420px;
}
.value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > span {
    margin-right: auto;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
  &.hidden {
    display: none;
  }
}
.value-input {
  margin-right: 1rem;
  &:disabled {
    border-color: transparent !important;
    color: $grey100 !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
</style>
