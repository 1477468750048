import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-37338d99"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "card-header",
  role: "button",
  "aria-controls": "contentIdForA11y3",
  "data-testing": "collapse-card-header"
}
const _hoisted_2 = {
  class: "card-header-title",
  "data-testing": "collapse-card-header-title"
}
const _hoisted_3 = { class: "card-header-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGIcon = _resolveComponent("SVGIcon")!
  const _component_b_collapse = _resolveComponent("b-collapse")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_b_collapse, {
      class: "card",
      animation: "slide",
      "aria-id": "contentIdForA11y3",
      "data-testing": "collapse-card"
    }, {
      trigger: _withCtx((props) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "title", {}, undefined, true)
          ]),
          _createElementVNode("a", _hoisted_3, [
            _createVNode(_component_SVGIcon, {
              path: props.open ? _ctx.mdiMenuDown : _ctx.mdiMenuUp,
              size: 24
            }, null, 8, ["path"])
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(['card-content', { empty: _ctx.isContentEmpty }])
        }, [
          _createElementVNode("div", null, [
            _renderSlot(_ctx.$slots, "content", {}, undefined, true)
          ])
        ], 2),
        _renderSlot(_ctx.$slots, "footer", { dataTesting: "slot-footer" }, undefined, true)
      ]),
      _: 3
    })
  ]))
}