import { defineStore } from "pinia";
import { ref } from "vue";

export const useUIAssetsStore = defineStore("uiAssets", () => {
  const isBulkSidebarVisible = ref<boolean>(false);
  const openBulkEditSidebar = () => {
    isBulkSidebarVisible.value = true;
  };
  const closeBulkEditSidebar = () => {
    isBulkSidebarVisible.value = false;
  };

  const isMetadataManagerSidebarVisible = ref<boolean>(false);
  const openMetadataManagerSidebar = () => {
    isMetadataManagerSidebarVisible.value = true;
  };
  const closeMetadataManagerSidebar = () => {
    isMetadataManagerSidebarVisible.value = false;
  };

  const isImportMetadataModalVisible = ref<boolean>(false);
  const openImportMetadataModal = () => {
    isImportMetadataModalVisible.value = true;
  };
  const closeImportMetadataModal = () => {
    isImportMetadataModalVisible.value = false;
  };

  const isImportAssetsModalVisible = ref<boolean>(false);
  const openImportAssetsModal = () => {
    isImportAssetsModalVisible.value = true;
  };
  const closeImportAssetsModal = () => {
    isImportAssetsModalVisible.value = false;
  };

  return {
    isBulkSidebarVisible,
    openBulkEditSidebar,
    closeBulkEditSidebar,
    isMetadataManagerSidebarVisible,
    openMetadataManagerSidebar,
    closeMetadataManagerSidebar,
    isImportMetadataModalVisible,
    openImportMetadataModal,
    closeImportMetadataModal,
    isImportAssetsModalVisible,
    openImportAssetsModal,
    closeImportAssetsModal
  };
});
