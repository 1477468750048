<template>
  <SVGIcon :title="name" :path="iconPath" :size="size" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import cnst from "../utils/constants";
import {
  mdiImageOutline,
  mdiFolder,
  mdiCheckboxMultipleBlank,
  mdiMonitor,
  mdiBookOpenVariant,
  mdiFileWord,
  mdiDrawing,
  mdiFileImage,
  mdiPencilRuler,
  mdiFilePdfBox,
  mdiFormatListCheckbox,
  mdiFormatListNumbered
} from "@mdi/js";

interface Data {
  mdiImageOutline: string;
  mdiFolder: string;
  mdiCheckboxMultipleBlank: string;
  mdiMonitor: string;
  mdiBookOpenVariant: string;
  mdiFileWord: string;
  mdiDrawing: string;
  mdiFileImage: string;
  mdiPencilRuler: string;
  mdiFilePdfBox: string;
  mdiFormatListCheckbox: string;
  mdiFormatListNumbered: string;
}

export default defineComponent({
  props: {
    size: {
      required: false,
      type: Number,
      default: 24
    },
    iconId: {
      type: [Number, String],
      required: true
    },
    name: {
      type: String,
      required: false
    }
  },
  data(): Data {
    return {
      mdiImageOutline,
      mdiFolder,
      mdiCheckboxMultipleBlank,
      mdiMonitor,
      mdiBookOpenVariant,
      mdiFileWord,
      mdiDrawing,
      mdiFileImage,
      mdiPencilRuler,
      mdiFilePdfBox,
      mdiFormatListCheckbox,
      mdiFormatListNumbered
    };
  },
  computed: {
    iconPath(): string {
      return this[
        (cnst.sectionIcons as Record<string, string>)[
          "icon" + this.iconId
        ] as keyof Data
      ];
    }
  }
});
</script>
