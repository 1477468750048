import { defineStore } from "pinia";
import { ref } from "vue";

export const useUICurriculumsStore = defineStore("uiCurriculums", () => {
  const curriculumForDisplay = ref<number | null>(null);
  const openCurriculumSidebar = (curriculumId: number) => {
    curriculumForDisplay.value = curriculumId;
  };
  const closeCurriculumSidebar = () => {
    curriculumForDisplay.value = null;
  };

  return {
    curriculumForDisplay,
    openCurriculumSidebar,
    closeCurriculumSidebar
  };
});
