import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2c540580"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-testing"]
const _hoisted_2 = { class: "sidebar" }
const _hoisted_3 = { class: "sidebar-top" }
const _hoisted_4 = { class: "sidebar-top-in" }
const _hoisted_5 = ["data-testing"]
const _hoisted_6 = { class: "right-sidebar-body no-sticky-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGIcon = _resolveComponent("SVGIcon")!

  return (_openBlock(), _createBlock(_Transition, { name: "sidebar-slide" }, {
    default: _withCtx(() => [
      (_ctx.isSidebarOpen)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(['right-sidebar', { updating: _ctx.isUpdating }, ..._ctx.cssClasses]),
            "data-testing": _ctx.dataTesting
          }, [
            _renderSlot(_ctx.$slots, "additional-content", {}, undefined, true),
            _createElementVNode("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "expand-btn", {}, undefined, true),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "title", {}, undefined, true),
                  _renderSlot(_ctx.$slots, "dropdown", {}, undefined, true),
                  (_ctx.isCloseBtnVisible)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        type: "button",
                        class: "close-btn btn-3",
                        title: "close",
                        "aria-label": "close",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeSidebar'))),
                        "data-testing": _ctx.closeBtnDataTesting
                      }, [
                        _createVNode(_component_SVGIcon, {
                          path: _ctx.mdiClose,
                          type: "is-white",
                          size: 24
                        }, null, 8, ["path"])
                      ], 8, _hoisted_5))
                    : _createCommentVNode("", true)
                ]),
                _renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "body", {}, undefined, true)
              ])
            ]),
            _renderSlot(_ctx.$slots, "modals", {}, undefined, true)
          ], 10, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}