<template>
  <Collapse :is-content-empty="isContentEmpty">
    <template v-slot:title>
      <span v-if="!isAuthoringTool">Attachments</span>
      <span v-else>Authoring Tool</span>
      <Hint v-if="XAPIErrors.length > 0" :type="'error'" :position="'top'">
        <ul>
          <li v-for="(error, index) in XAPIErrors" :key="index">{{ error }}</li>
        </ul>
      </Hint>
      <Hint v-if="XAPIWarnings.length > 0" :type="'warning'" :position="'top'">
        <ul>
          <li v-for="(error, index) in XAPIWarnings" :key="index">{{
            error
          }}</li>
        </ul>
      </Hint>
    </template>
    <template v-slot:content>
      <!--Media Content-->
      <MediaContent
        v-if="hasExternalContentAuthoring"
        :section="section"
        @previewAttachment="previewAttachment"
      />

      <!--MCQ-->
      <MCQ
        :section="section"
        v-if="hasMCQAuthoring"
        :is-content-authoring-tier2-feature-enabled="
          isContentAuthoringTier2UserFeatureEnabled
        "
      />

      <!--QTI-->
      <QTI
        v-if="hasQTIAuthoring"
        :section="section"
        :is-content-authoring-tier2-feature-enabled="
          isContentAuthoringTier2UserFeatureEnabled
        "
      />

      <!--HTML Editor-->
      <HTMLEditor
        v-if="hasHTMLAuthoring"
        :section="section"
        :is-content-authoring-tier2-feature-enabled="
          isContentAuthoringTier2UserFeatureEnabled
        "
      />

      <!--Attachments-->
      <Attachments :section="section" @previewAttachment="previewAttachment" />
    </template>
    <template v-if="!isAuthoringTool" v-slot:footer>
      <footer class="files-list card-footer" data-testing="files-card-footer">
        <div
          data-testing="files-card-footer-btn-add-file"
          class="card-footer-item"
        >
          <button class="btn-3" type="button" @click="openFileModal">
            <SVGIcon :path="mdiPlus" />
            Add attachment
          </button>
        </div>
      </footer>
    </template>
  </Collapse>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";
import cnst from "@/utils/constants";
import Collapse from "@/components/right-sidebars/ui/Collapse.vue";
import Attachments from "@/components/right-sidebars/content-sidebar/authoring-tool/Attachments.vue";
import MediaContent from "@/components/right-sidebars/content-sidebar/authoring-tool/MediaContent.vue";
import MCQ from "@/components/right-sidebars/content-sidebar/authoring-tool/MCQ.vue";
import QTI from "@/components/right-sidebars/content-sidebar/authoring-tool/QTI.vue";
import HTMLEditor from "@/components/right-sidebars/content-sidebar/authoring-tool/HTMLEditor.vue";
import Hint from "@/components/Hint.vue";
import { ProductItem } from "@/models/product.model";
import UserInterfaceFeatureFlaggerMixin from "@/mixins/user-interface-feature-flagger";
import { ContentStructureSection } from "@/models/content-structure.model";
import { mdiPlus } from "@mdi/js";
import { mapStores } from "pinia";
import { useUIFilesStore } from "@/stores/ui-files.store";

export default defineComponent({
  name: "AuthoringTool",
  mixins: [UserInterfaceFeatureFlaggerMixin],
  data() {
    return {
      mdiPlus
    };
  },
  components: {
    Collapse,
    MCQ,
    QTI,
    Hint,
    Attachments,
    MediaContent,
    HTMLEditor
  },
  props: {
    product: {
      type: Object as PropType<ProductItem>,
      required: true
    },
    section: {
      type: Object as PropType<ContentStructureSection>,
      required: true
    },
    isAContentObject: {
      type: Boolean,
      required: true
    },
    XAPIErrors: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => []
    },
    XAPIWarnings: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => []
    }
  },
  computed: {
    ...mapStores(useUIFilesStore),
    ...mapGetters("files", ["files"]),
    isContentEmpty(): boolean {
      return !this.isAuthoringTool && this.section.enabled_files.length === 0;
    },
    hasMCQAuthoring(): boolean {
      return cnst.isMCQAuthoringToolId(
        this.section && this.section.type.tool_id
      );
    },
    hasQTIAuthoring(): boolean {
      return (
        !!this.section && cnst.isQTIAuthoringToolId(this.section.type.tool_id)
      );
    },
    hasHTMLAuthoring(): boolean {
      return (
        !!this.section && cnst.isHTMLAuthoringToolId(this.section.type.tool_id)
      );
    },
    hasExternalContentAuthoring(): boolean {
      return cnst.isExternalContentAuthoringToolId(
        this.section && this.section.type.tool_id
      );
    },
    isAuthoringTool(): boolean {
      return this.section.type.tool_id > 0;
    },
    isContentAuthoringTier2UserFeatureEnabled(): boolean {
      return this.product
        ? this.isContentAuthoringTier2FeatureEnabled(this.product.id)
        : false;
    }
  },
  methods: {
    openFileModal(): void {
      this.uiFilesStore.openAttachFileModal({ section: this.section });
    },
    previewAttachment(fileLocation: string): void {
      this.$emit("previewAttachment", fileLocation);
    }
  }
});
</script>
<style lang="scss" scoped></style>
