import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "link-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.link.to,
    class: _normalizeClass([
      'sidebar-link',
      {
        highlighted: _ctx.link.isHighlighted,
        'not-available': _ctx.link.isNotAvailable,
        open: _ctx.uiGeneralStore.isSidebarOpen
      }
    ]),
    "data-testing": _ctx.link.dataTesting
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: "sidebar-icon",
        title: _ctx.link.title
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 8, _hoisted_1),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.link.title), 1)
    ]),
    _: 3
  }, 8, ["to", "class", "data-testing"]))
}