import QTIParser from "@/utils/qti-adaptemy-libs-copy/qti-parser";
import API from "@/api/index";
import { QTIDocumentVariable, QTIQuestionHTML } from "@/models/qti.model";
import validator from "@/utils/question-validator.js";
import { AxiosResponse } from "axios";
import { QtiImgResponse } from "@/models/api/qti";

const getQuestions = (qtiDocument: string): any[] => {
  return qtiDocument ? QTIParser.convertStringToQuestions(qtiDocument) : [];
};

const getQuestionsImages = async (qnHTML: string): Promise<string> => {
  const imagesPidIds = qnHTML.match(/'pid:::image:::(.*?)'/g) || [];
  const pIds = imagesPidIds.map((pId: string) => {
    return pId.replaceAll("'", "");
  });
  for (const pId of pIds) {
    const src = await API.qti
      .getImageSrcByPID(pId)
      .then((response: AxiosResponse<QtiImgResponse>) => {
        return response.data.results[0].body.metadata.s3.path;
      });
    qnHTML = qnHTML.replace(pId, src);
  }
  return qnHTML;
};

export const buildQuestionsHTMLArray = async (
  qtiDocument: string,
  variables: QTIDocumentVariable[],
  isFullVersionNeeded: boolean = true
): Promise<QTIQuestionHTML[]> => {
  if (getQuestions(qtiDocument) && getQuestions(qtiDocument).length > 0) {
    const tempQns: QTIQuestionHTML[] = [];
    const errors = validateQTI(qtiDocument, variables);
    for (const [index, question] of getQuestions(qtiDocument).entries()) {
      const identifier =
        question.getElementsByTagName("identifier")[0].childNodes;
      if (identifier.length > 0) {
        const questionId = identifier[0].nodeValue;

        const questionDifficulty: string =
          question.getElementsByTagName("difficulty")[0].childNodes.length > 0
            ? question.getElementsByTagName("difficulty")[0].childNodes[0]
                .nodeValue
            : "Not set";
        const qnHTML = QTIParser.convertQuestionToHTML(question, undefined)
          .replace(/\\/g, "'")
          .replace(/"/g, "'");
        if (isFullVersionNeeded) {
          tempQns.push({
            qnHTML: await getQuestionsImages(qnHTML),
            solnHTML: QTIParser.getSolution(question),
            answers: QTIParser.getAnswerArray(question),
            hintHTML: QTIParser.getHint(question),
            questionId,
            difficulty: questionDifficulty,
            errors: errors[index]
          });
        } else {
          tempQns.push({
            questionId,
            difficulty: questionDifficulty,
            errors: errors[index]
          });
        }
      }
    }
    return tempQns;
  }
  return [];
};

export const buildQuestionsStringArray = (qtiDocument: string): string[] => {
  const documentString = qtiDocument
    ? QTIParser.escapeXMLString(qtiDocument)
    : undefined;
  const regex = /(<assessmentItem>.+?<\/assessmentItem>)/g;
  const questionsArray = [...documentString.matchAll(regex)];
  return questionsArray.map((str: string) => str[0]);
};

export const validateQTI = (
  qtiDocument: string,
  variables: QTIDocumentVariable[]
): string[][] => {
  const questions: string[] = buildQuestionsStringArray(qtiDocument);
  const errors: string[][] = [];
  questions.map((qn: string) => {
    const validatedQn = validator(qn, variables);
    let currentErrors = validatedQn.getErrors();
    currentErrors = currentErrors.map(error => {
      const result = error.replace(/([A-Z])/g, " $1").toLowerCase();
      return result.charAt(0).toUpperCase() + result.slice(1);
    });
    errors.push(currentErrors);
  });
  return errors;
};

export const isDifficultyVisible = (difficulty: string): boolean => {
  return (
    !!difficulty &&
    (difficulty.toLowerCase() === "easy" ||
      difficulty.toLowerCase() === "medium" ||
      difficulty.toLowerCase() === "hard" ||
      difficulty.toLowerCase() === "difficult")
  );
};
