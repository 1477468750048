// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { PropType } from "vue";
import { AssetLocation } from "@/models/assets.model";
import { ProductItem } from "@/models/product.model";

const ContentPathMixin = {
  props: {
    product: {
      required: true,
      type: Object as PropType<ProductItem>
    },
    path: {
      required: true,
      type: Array as PropType<AssetLocation[]>
    },
    sectionName: {
      required: true,
      type: String
    },
    sectionId: {
      required: true,
      type: Number
    },
    sectionIconId: {
      required: true,
      type: Number
    },
    sectionExternalId: {
      required: true,
      type: String
    }
  },
  computed: {
    internalPath(): string {
      return this.path.length
        ? `${this.product.name}${this.path
            .map((p: AssetLocation) => {
              return ` / ${p.name}`;
            })
            .join("")} / ${this.sectionName}`
        : `${this.product.name} / ${this.sectionName}`;
    },
    externalPath(): string {
      return this.path.length
        ? `${this.product.name}${this.path
            .map((p: AssetLocation) => {
              return ` / ${p.externalId}`;
            })
            .join("")} / ${this.sectionExternalId}`
        : `${this.product.name} / ${this.sectionExternalId}`;
    },
    productShortName(): string {
      const firstLettersArray = this.product.name.toUpperCase().match(/\b\w/g);
      return firstLettersArray ? firstLettersArray.join("") : "";
    }
  }
};
export default ContentPathMixin;
