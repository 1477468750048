export const isAValidURL = (url: string): boolean => {
  const urlRegex =
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/;
  const matchResult = url.match(urlRegex);
  return Boolean(matchResult);
};

export const getFileNameFromURL = (url: string): string => {
  const URLLastPart = url.split("/").pop();
  const filename = URLLastPart ? URLLastPart.split("#")[0].split("?")[0] : "";
  return filename ? window.decodeURIComponent(filename) : "";
};

export const isAValidPathToTheLocalFile = (path: string): boolean => {
  const localFilePathRegex =
    /^(file:\/\/)?([-a-zA-Z0-9@:%._+~#=!&*' ()/\\]){1,}\.+([a-zA-Z]{1,})$/gi;
  const matchResult = path.match(localFilePathRegex);
  return Boolean(matchResult);
};

export const isValidImageFileType = (type: string): boolean => {
  return (
    type === "image/png" ||
    type === "image/jpeg" ||
    type === "image/bmp" ||
    type === "image/gif"
  );
};

export const isValidCsvFileType = (type: string): boolean => {
  return type === "text/csv";
};

export const isValidXmlFileType = (type: string): boolean => {
  return type === "text/xml";
};

export default {
  isAValidURL,
  getFileNameFromURL,
  isAValidPathToTheLocalFile,
  isValidImageFileType,
  isValidCsvFileType,
  isValidXmlFileType
};
