import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-68866131"),n=n(),_popScopeId(),n)
const _hoisted_1 = { "data-testing": "state-circle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGIcon = _resolveComponent("SVGIcon")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    (_ctx.isStepCompleted)
      ? (_openBlock(), _createBlock(_component_SVGIcon, {
          key: 0,
          title: "complete",
          path: _ctx.mdiCheckboxMarkedCircleOutline,
          type: "is-success",
          "data-testing": "complete"
        }, null, 8, ["path"]))
      : (_ctx.stateRank === _ctx.currentIndex)
        ? (_openBlock(), _createBlock(_component_SVGIcon, {
            key: 1,
            title: "on track",
            path: _ctx.mdiCircle,
            type: "is-warning",
            "data-testing": "on-track"
          }, null, 8, ["path"]))
        : (_openBlock(), _createBlock(_component_SVGIcon, {
            key: 2,
            title: "on track",
            path: _ctx.mdiCircleOutline,
            type: "is-dark",
            "data-testing": "on-track"
          }, null, 8, ["path"]))
  ]))
}