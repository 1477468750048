import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-dcf5ccbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "content-files-list__item",
  "data-testing": "sb-content-files-list"
}
const _hoisted_2 = { class: "name" }
const _hoisted_3 = { class: "name-text" }
const _hoisted_4 = { class: "size" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "date" }
const _hoisted_7 = { class: "info" }
const _hoisted_8 = { class: "open" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "actions" }
const _hoisted_11 = {
  class: "btn-3",
  "data-testing": "sb-open-file-bar"
}
const _hoisted_12 = ["href"]
const _hoisted_13 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileListItemIcon = _resolveComponent("FileListItemIcon")!
  const _component_SVGIcon = _resolveComponent("SVGIcon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, [
      _createVNode(_component_FileListItemIcon, {
        "file-location": _ctx.file.location
      }, null, 8, ["file-location"]),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.file.name), 1)
    ]),
    _createElementVNode("td", _hoisted_4, [
      (_ctx.fileSize > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.fileSize + "kb"), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.dateAdded), 1),
    _createElementVNode("td", _hoisted_7, [
      (_ctx.isAttachmentTable && _ctx.isSetAsMediaContent)
        ? (_openBlock(), _createBlock(_component_SVGIcon, {
            key: 0,
            path: _ctx.mdiStarOutline,
            title: "Set as Media Content"
          }, null, 8, ["path"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", _hoisted_8, [
      (_ctx.isTheFileLocationRemote)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: _ctx.file.location,
            target: "_blank",
            title: "Open attachment in a new tab"
          }, [
            _createVNode(_component_SVGIcon, { path: _ctx.mdiOpenInNew }, null, 8, ["path"])
          ], 8, _hoisted_9))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", _hoisted_10, [
      _createVNode(_component_b_dropdown, {
        position: "is-bottom-left",
        class: "file-btn"
      }, {
        trigger: _withCtx(() => [
          _createElementVNode("button", _hoisted_11, [
            _createVNode(_component_SVGIcon, {
              path: _ctx.mdiDotsHorizontal,
              size: 18
            }, null, 8, ["path"])
          ])
        ]),
        default: _withCtx(() => [
          (_ctx.isAttachmentTable)
            ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                key: 0,
                "aria-role": "listitem",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('previewAttachment', _ctx.file.location)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SVGIcon, { path: _ctx.mdiPresentationPlay }, null, 8, ["path"]),
                  _createTextVNode(" Preview ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isTheFileLocationRemote)
            ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                key: 1,
                "aria-role": "listitem"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    href: _ctx.file.location,
                    target: "_blank"
                  }, [
                    _createVNode(_component_SVGIcon, { path: _ctx.mdiOpenInNew }, null, 8, ["path"]),
                    _createTextVNode(" Open attachment ")
                  ], 8, _hoisted_12)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isTheFileLocationLocal)
            ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                key: 2,
                "aria-role": "listitem",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copyToClipboard(_ctx.file.location)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SVGIcon, { path: _ctx.mdiContentCopy }, null, 8, ["path"]),
                  _createTextVNode(" Copy path ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.showUnsetAsMediaContentOption)
            ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                key: 3,
                "aria-role": "listitem",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('unsetAsMediaContent')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SVGIcon, { path: _ctx.mdiStarOffOutline }, null, 8, ["path"]),
                  _createTextVNode(" Unset as Media Content ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.showSetAsMediaContentOption)
            ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                key: 4,
                "aria-role": "listitem",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('setAsMediaContent', _ctx.file.location, _ctx.file.id)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SVGIcon, { path: _ctx.mdiStarOutline }, null, 8, ["path"]),
                  _createTextVNode(" Set as Media Content ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isFileAvailable)
            ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                key: 5,
                "aria-role": "listitem",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('launchRenamePopup', _ctx.file)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SVGIcon, { path: _ctx.mdiPencil }, null, 8, ["path"]),
                  _createTextVNode(" Rename ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.file.is_external)
            ? (_openBlock(), _createBlock(_component_b_dropdown_item, { key: 6 }, {
                default: _withCtx(() => [
                  _createElementVNode("a", {
                    href: _ctx.file.location,
                    download: "",
                    title: "Download this attachment to your computer",
                    class: "nstyled btn-3 full-width"
                  }, [
                    _createVNode(_component_SVGIcon, { path: _ctx.mdiDownload }, null, 8, ["path"]),
                    _createTextVNode(" Download ")
                  ], 8, _hoisted_13)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isFileAvailable && !_ctx.file.is_external)
            ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                key: 7,
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('openUploadVersionModal', _ctx.file)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SVGIcon, { path: _ctx.mdiUpload }, null, 8, ["path"]),
                  _createTextVNode(" Upload a new version ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isFileAvailable)
            ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                key: 8,
                "aria-role": "listitem",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('showFileInLibrary', _ctx.file)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SVGIcon, { path: _ctx.mdiArrowRight }, null, 8, ["path"]),
                  _createTextVNode(" Open in file library ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isFileAvailable && _ctx.isAttachedFile)
            ? (_openBlock(), _createBlock(_component_b_dropdown_item, {
                key: 9,
                "aria-role": "listitem",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('launchRemoveAttachmentDialog', _ctx.file))),
                "data-testing": "sb-remove-attachment"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SVGIcon, { path: _ctx.mdiDelete }, null, 8, ["path"]),
                  _createTextVNode(" Remove attachment ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}