import DAO from "@/api/DAO";
import { ASSETS_METADATA_URL } from "@/api/URL-constants";
import cnst from "@/utils/constants";
import { AxiosResponse } from "axios";
import { AssetsMetadataResponse } from "@/models/api/assets.model";
import { AssetsMetadataItem } from "@/models/assets.model";

const get = (contentTemplateId: number): Promise<AssetsMetadataItem> => {
  return DAO()
    .get(`${ASSETS_METADATA_URL}/${contentTemplateId}`)
    .then((response: AxiosResponse<AssetsMetadataResponse>) => {
      if (response.status === 200) {
        return response.data.data;
      } else if (response.status === 204) {
        return {
          sections_type_id: contentTemplateId,
          assets_data: []
        };
      } else {
        throw new Error(cnst.errLoadingMetadata);
      }
    });
};

const update = (
  payload: AssetsMetadataItem
): Promise<AxiosResponse<AssetsMetadataResponse>> => {
  return DAO().put(ASSETS_METADATA_URL, payload);
};

export { get, update };
