<template>
  <div class="modal-card">
    <div class="modal-card-header">
      <h3>Edit path</h3>
    </div>
    <div class="modal-card-content">
      <div class="toggle-check-container margin-b-standard">
        <label class="toggle-check">
          <input
            type="checkbox"
            aria-label="edit"
            :value="isLongPathNameEnabled"
            data-testing="toggle-edit-mode"
            class="editable"
            v-model="isLongPathNameEnabled"
          />
          <span
            data-on-text="Long path name"
            data-off-text="Short path name"
          ></span>
        </label>
      </div>
      <div class="margin-b-standard">
        <div>
          <RightSidebarTitle :title="'Select path'" />
          <div class="path-item">
            <select v-model="newParentId">
              <option
                :selected="newParentId === structure.rootSection.id"
                :value="structure.rootSection.id"
                >{{ product.name }}</option
              >
              <option
                v-for="item in sortedPathsToDisplay"
                :key="item.lastChildSectionId"
                :value="item.lastChildSectionId"
                :selected="item.lastChildSectionId === newParentId"
                >{{ item.pathString }}</option
              >
            </select>
          </div>
        </div>
      </div>
      <div class="margin-b-standard">
        <RightSidebarTitle :title="'Old path'" />
        <ContentLocationPath
          :product="product"
          :path="path"
          :section-name="sectionName"
          :section-id="sectionId"
          :section-icon-id="sectionIconId"
          :section-external-id="sectionExternalId"
          :is-clickable="false"
          :is-long-path-name-enabled="isLongPathNameEnabled"
        />
      </div>
      <div>
        <RightSidebarTitle :title="'New path'" />
        <ContentLocationPath
          :product="product"
          :path="newPath"
          :section-name="sectionName"
          :section-id="sectionId"
          :section-icon-id="sectionIconId"
          :section-external-id="sectionExternalId"
          :is-clickable="false"
          :is-long-path-name-enabled="isLongPathNameEnabled"
        />
      </div>
      <p v-if="serverError" class="error"
        ><SVGIcon :path="mdiAlert" /> {{ serverError }}</p
      >
    </div>
    <div class="modal-card-footer">
      <button class="btn-2" type="button" @click="uiContentStore.closeEditContentPathModal">
        Cancel
      </button>
      <button
        class="btn-1"
        type="button"
        @click="updatePath"
        :disabled="isSaveBtnDisabled"
      >
        Save
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import ContentPathMixin from "@/mixins/content-path";
import { mapActions } from "vuex";
import ContentLocationPath from "@/components/right-sidebars/content-sidebar/content-location/ContentLocationPath.vue";
import { defineComponent, PropType } from "vue";
import cnst from "@/utils/constants";
import csHelper from "@/utils/product/content-structure-helpers";
import { GeneratedContentStructure } from "@/models/content-structure.model";
import { AssetLocation } from "@/models/assets.model";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import { ProductSection } from "@/models/product-section.model";
import { mdiAlert } from "@mdi/js";
import { mapStores } from "pinia";
import { useUIContentStore } from "@/stores/ui-content.store";

interface PathToDisplay {
  lastChildSectionId: number;
  pathString: string;
}
interface Data {
  oldParentId: number | null;
  newParentId: number | null;
  serverError: string;
  isLongPathNameEnabled: boolean;
  mdiAlert: string;
}

export default defineComponent({
  name: "EditContentPath",
  mixins: [ContentPathMixin],
  props: {
    structure: {
      required: true,
      type: Object as PropType<GeneratedContentStructure>
    }
  },
  data(): Data {
    return {
      oldParentId: null,
      newParentId: null,
      serverError: "",
      isLongPathNameEnabled: true,
      mdiAlert
    };
  },
  components: {
    ContentLocationPath,
    RightSidebarTitle
  },
  computed: {
    ...mapStores(useUIContentStore),
    isSelectVisible(): boolean {
      return (
        !!this.newParentId &&
        !!this.structure.rootSection &&
        this.newParentId !== this.structure.rootSection.id
      );
    },
    isSaveBtnDisabled(): boolean {
      return (
        this.newParentId === this.oldParentId ||
        this.newParentId === 0 ||
        // @ts-ignore
        this.newParentId === this.sectionId
      );
    },
    currentPath(): AssetLocation[] {
      return !!this.structure.rootSection
        ? csHelper.getPathToSectionWithIds(
            this.structure.rootSection,
            // @ts-ignore
            this.sectionId
          )
        : [];
    },
    possiblePaths(): AssetLocation[][] {
      // @ts-ignore
      return this.product.sections
        .filter(
          // remove root section, current section, child sections of current section from possible paths
          (section: ProductSection): boolean => {
            return !!section.parent_section_id;
          }
        )
        .map((section: ProductSection): AssetLocation[] => {
          const path = this.structure.rootSection
            ? csHelper.getPathToSectionWithIds(
                this.structure.rootSection,
                section.id
              )
            : [];
          if (path) {
            path.push({
              name: section.name,
              id: section.id,
              iconId: section.type.icon_id,
              externalId: section.client_external_id
                ? section.client_external_id
                : ""
            });
          }
          return path;
        })
        .filter((path: AssetLocation[]): boolean => {
          // remove all paths to child sections of the current section
          return !path.some((pathItem: AssetLocation): boolean => {
            // @ts-ignore
            return pathItem.id === this.sectionId;
          });
        });
    },
    pathsToDisplay(): PathToDisplay[] {
      return this.possiblePaths.map((path: AssetLocation[]) => {
        const pathString = path.map((pathItem: AssetLocation) => {
          return pathItem.name;
        });
        return {
          lastChildSectionId: path[path.length - 1].id,
          pathString: `${this.product.name}/${pathString.join("/")}`
        };
      });
    },
    filteredPathsToDisplay(): PathToDisplay[] {
      // remove duplicate paths
      return this.pathsToDisplay.reduce(
        (acc: PathToDisplay[], item: PathToDisplay): PathToDisplay[] => {
          const prevItem = acc.find((prevItem: PathToDisplay): boolean => {
            return prevItem.pathString === item.pathString;
          });
          if (!prevItem) {
            acc.push(item);
          }
          return acc;
        },
        []
      );
    },
    sortedPathsToDisplay(): PathToDisplay[] {
      // sort in alphabetical order
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.filteredPathsToDisplay.sort(
        (a: PathToDisplay, b: PathToDisplay) => {
          return ("" + a.pathString).localeCompare(b.pathString);
        }
      );
    },
    newPath(): AssetLocation[] | undefined {
      if (this.newParentId !== this.oldParentId) {
        if (
          this.structure.rootSection &&
          this.newParentId === this.structure.rootSection.id
        ) {
          return [];
        }
        return this.possiblePaths.find(
          (possiblePath: AssetLocation[]): boolean => {
            return (
              !!this.newParentId &&
              possiblePath[possiblePath.length - 1].id === this.newParentId
            );
          }
        );
      }
      // @ts-ignore
      return this.path;
    }
  },
  methods: {
    ...mapActions("products", ["upsertSections"]),
    setParentIds(): void {
      if (this.structure.rootSection) {
        if (this.currentPath.length > 0) {
          this.oldParentId = this.currentPath[this.currentPath.length - 1].id;
          this.newParentId = this.oldParentId;
        } else {
          this.oldParentId = this.structure.rootSection.id;
          this.newParentId = this.oldParentId;
        }
      }
    },
    updatePath() {
      const payload = {
        // @ts-ignore
        productIds: [this.product.id],
        sections: [
          {
            id: this.sectionId,
            new_parent_section_id: this.newParentId
          }
        ]
      };
      this.upsertSections(payload)
        .then(response => {
          if (response.status === 200) {
            this.$buefy.toast.open({
              message: cnst.internalPath.success,
              duration: 3000
            });
            this.uiContentStore.closeEditContentPathModal();
          }
        })
        .catch(error => {
          this.serverError = error.message;
        });
    }
  },
  mounted() {
    this.setParentIds();
  }
});
</script>
<style scoped lang="scss">
.toggle-check-container {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid $border-color;
  padding-bottom: 0.5rem;
  .toggle-check {
    transform: scale(0.75);
  }
}
.current-path {
}
.path-item {
  display: flex;
  align-items: center;
}
</style>
