<template>
  <span class="icon-container">
    <SVGIcon v-if="isDocument" :path="mdiFileWordBox" />
    <SVGIcon v-else-if="isSpreadsheet" :path="mdiFileExcelBox" />
    <SVGIcon v-else-if="isPresentation" :path="mdiFilePowerpointBox" />
    <SVGIcon v-else-if="isPDF" :path="mdiFilePdfBox" />
    <SVGIcon v-else-if="isImage" :path="mdiImage" />
    <SVGIcon v-else-if="isText" :path="mdiTextBox" />
    <SVGIcon v-else-if="isVideo" :path="mdiVideoOutline" />
    <SVGIcon v-else-if="isYouTubeVideo" :path="mdiYoutube" />
    <SVGIcon v-else-if="isDropbox" :path="mdiDropbox" />
    <SVGIcon v-else :path="mdiPaperclip" />
  </span>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {
  mdiFileWordBox,
  mdiFileExcelBox,
  mdiFilePowerpointBox,
  mdiFilePdfBox,
  mdiImage,
  mdiTextBox,
  mdiVideoOutline,
  mdiYoutube,
  mdiDropbox,
  mdiPaperclip
} from "@mdi/js";

export default defineComponent({
  name: "FileListItemIcon",
  data() {
    return {
      mdiFileWordBox,
      mdiFileExcelBox,
      mdiFilePowerpointBox,
      mdiFilePdfBox,
      mdiImage,
      mdiTextBox,
      mdiVideoOutline,
      mdiYoutube,
      mdiDropbox,
      mdiPaperclip
    };
  },
  props: {
    fileLocation: {
      type: String,
      required: true
    }
  },
  computed: {
    isImage(): boolean {
      return this.fileLocation.match(/\.(jpeg|jpg|gif|png|webp)$/) !== null;
    },
    isPDF(): boolean {
      return this.fileLocation.match(/\.(pdf)$/) !== null;
    },
    isVideo(): boolean {
      return (
        this.fileLocation.match(/\.(wmv|mpeg|mpg|mov|avi|mp4|m4v)$/) !== null
      );
    },
    isYouTubeVideo(): boolean {
      return (
        this.fileLocation.includes("youtube.com") ||
        this.fileLocation.includes("youtu.be")
      );
    },
    isDropbox(): boolean {
      return this.fileLocation.includes("dropbox.com");
    },
    isText(): boolean {
      return this.fileLocation.match(/\.(txt)$/) !== null;
    },
    isDocument(): boolean {
      return (
        this.fileLocation.match(/\.(doc|docx|rtf)$/) !== null ||
        this.fileLocation.includes("docs.google.com/document/") ||
        this.fileLocation.includes("sharepoint.com/:w:/")
      );
    },
    isSpreadsheet(): boolean {
      return (
        this.fileLocation.match(/\.(xls|xlsx|csv)$/) !== null ||
        this.fileLocation.includes("docs.google.com/spreadsheets/") ||
        this.fileLocation.includes("sharepoint.com/:x:/")
      );
    },
    isPresentation(): boolean {
      return (
        this.fileLocation.match(/\.(ppt|pptx|ppsx|pps)$/) !== null ||
        this.fileLocation.includes("docs.google.com/presentation/") ||
        this.fileLocation.includes("sharepoint.com/:p:/")
      );
    }
  }
});
</script>
<style scoped lang="scss">
.icon-container {
  margin-right: 0.25rem;
  svg {
    width: 12px !important;
    height: 12px !important;
  }
}
</style>
