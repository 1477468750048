<template>
  <RightSidebar
    v-if="section"
    @closeSidebar="closeSidebar"
    :is-sidebar-open="!!uiContentStore.sectionToDisplay"
    :data-testing="'section-sidebar'"
    :close-btn-data-testing="'close-right-sidebar'"
    :is-updating="isUpdating"
    :is-close-btn-visible="
      !!uiContentStore.sectionToDisplay && uiContentStore.sectionToDisplay.hasCloseButton
    "
    :css-classes="[
      'content-sidebar',
      {
        externalContentOpened: uiContentStore.fileLocationForPreview && !section.document,
        expanded: uiContentStore.isContentSidebarExpanded
      }
    ]"
  >
    <template v-slot:additional-content>
      <ContentPreview
        v-if="uiContentStore.fileLocationForPreview && !section.document"
        :section="section"
        :path="path[1]"
        @closePreview="uiContentStore.setFileLocationToPreview(undefined)"
      />
    </template>
    <template v-slot:expand-btn>
      <button
        v-if="!uiContentStore.fileLocationForPreview"
        class="expand-btn btn-1"
        type="button"
        @click="uiContentStore.toggleContentSidebarWidth"
      >
        <SVGIcon
          v-if="!uiContentStore.isContentSidebarExpanded"
          :path="mdiChevronLeft"
          :size="18"
          type="is-white"
        />
        <SVGIcon v-else :path="mdiChevronRight" :size="18" type="is-white" />
      </button>
    </template>
    <template v-slot:title>
      <ContentName
        :content-name="section.name"
        :product-id="uiContentStore.sectionToDisplay.productId"
        :section-id="uiContentStore.sectionToDisplay.sectionId"
        :can-edit-content="canEditContent"
      />
    </template>
    <template v-slot:dropdown>
      <b-dropdown
        class="is-block sidebar-dropdown-menu"
        aria-role="menu"
        position="is-bottom-left"
        hoverable
        :mobile-modal="false"
        v-if="canManageWorkflows && canEditContent"
      >
        <template #trigger>
          <button class="btn-3 right-icon">
            <SVGIcon :path="mdiDotsHorizontal" type="is-white" :size="36" />
          </button>
        </template>
        <div>
          <b-dropdown-item
            aria-role="menu-item"
            :focusable="false"
            custom
            v-if="isDeployButtonShown"
          >
            <button @click="deployToQA" class="btn-unstyled btn-3 full-width">
              <SVGIcon :path="mdiPackageUp" />
              Deploy to QA
            </button></b-dropdown-item
          >
          <b-dropdown-item aria-role="menu-item" :focusable="false" custom>
            <button
              class="btn-unstyled btn-3 full-width"
              type="button"
              data-testing="state-delete-btn"
              @click="confirmRemoval"
            >
              <SVGIcon :path="mdiDelete" />
              Delete Content
            </button>
          </b-dropdown-item>
        </div>
      </b-dropdown>
    </template>
    <template v-slot:body>
      <div class="columns is-multiline">
        <div :class="['content-item-wrapper', 'column', 'is-4']">
          <!-- AUTHORING TOOL -->
          <div :class="['content-item', 'padding-b-standard']">
            <AuthoringTool
              :section="section"
              :product="product"
              :is-a-content-object="isAContentObject"
              :XAPIErrors="XAPIAuthoringToolErrors"
              :XAPIWarnings="XAPIQuestionsWarnings"
              @previewAttachment="previewAttachment"
            />
          </div>

          <!-- IDENTIFIERS -->
          <div :class="['content-item', 'padding-b-standard']">
            <ContentIdentifiers
              :internal-id="section.id"
              :external-id="
                section.client_external_id ? section.client_external_id : ''
              "
              :sections="product.sections"
              :can-edit-content="canEditContent"
              :product-id="uiContentStore.sectionToDisplay.productId"
            />
          </div>

          <!-- LOCATION -->
          <div :class="['content-item', 'padding-b-standard']">
            <ContentLocation
              :product-id="product.id"
              :product-name="product.name"
              :path="path"
              :section-name="section.name"
              :section-id="section.id"
              :section-icon-id="section.type.icon_id"
              :product="product"
              :section-external-id="sectionExternalId"
              :can-edit-content="canEditContent"
            />
          </div>

          <!-- DESCRIPTION -->
          <div
            v-if="showContentDescription"
            :class="['content-item', 'padding-b-standard']"
          >
            <ContentDescription
              :product-id="uiContentStore.sectionToDisplay.productId"
              :section-id="uiContentStore.sectionToDisplay.sectionId"
              :section="section"
              :can-edit-content="canEditContent"
            />
          </div>
        </div>

        <div :class="['content-item-wrapper', 'column', 'is-4']">
          <!-- METADATA -->
          <div
            v-if="isMetadataVisible"
            :class="['content-item', 'padding-b-standard']"
          >
            <ContentMetadata
              :is-updating="isUpdating"
              :section="section"
              :product-id="uiContentStore.sectionToDisplay.productId"
              :displayed-metadata="displayedMetadata"
              :can-edit-content="canEditContent"
              :XAPIErrors="XAPIMetadataErrors"
            />
          </div>
        </div>

        <div :class="['content-item-wrapper', 'column', 'is-4']">
          <!-- TASKS IN CONTENT -->
          <div
            v-if="canShowWorkflow"
            :class="['content-item', 'padding-b-standard']"
            style="order: 3"
          >
            <ContentWorkflow
              :workflowStates="workflowStates"
              :current-state="currentState"
              :product-id="uiContentStore.sectionToDisplay.productId"
              :section-id="uiContentStore.sectionToDisplay.sectionId"
              :workflow-state-from-title-id="uiContentStore.sectionToDisplay.workflowStateId"
              :section="section"
              :is-updating="isUpdating"
            />
          </div>

          <!-- ACTIVITY LOG -->
          <div
            v-if="hasWorkflowStates && workflowStates.length && section"
            :class="['content-item', 'padding-b-standard']"
          >
            <ContentActivityLog
              :section="section"
              :workflowStates="workflowStates"
              :product-id="uiContentStore.sectionToDisplay.productId"
              :section-id="uiContentStore.sectionToDisplay.sectionId"
            />
          </div>

          <!-- CURRICULUM TAGS -->
          <div
            v-if="isCurriculumTagsVisible"
            :class="['content-item', 'padding-b-standard']"
          >
            <CurriculumTags
              :section="section"
              :product-curriculum-id="product.curriculum_id"
              :is-loading="isCurriculumLoading"
              :topics-from-xml="topicsFromXml"
              :concepts-from-xml="conceptsFromXml"
              :xml-parsed-successfully="xmlParsedSuccessfully"
              :can-edit-content="canEditContent"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:modals>
      <b-modal
        :model-value="uiContentStore.isEditContentPathModalOpen"
        :has-modal-card="true"
        :trap-focus="true"
        aria-role="dialog"
        :aria-modal="true"
        @cancel="uiContentStore.closeEditContentPathModal"
        class="full-screen-modal"
      >
        <EditContentPath
          :product-id="product.id"
          :product-name="product.name"
          :path="path"
          :section-name="section.name"
          :section-id="section.id"
          :section-icon-id="section.type.icon_id"
          :product="product"
          :structure="structure"
          :section-external-id="sectionExternalId"
        />
      </b-modal>
    </template>
  </RightSidebar>
</template>
<script lang="ts">
import { mapActions, mapGetters } from "vuex";
import csHelper from "@/utils/product/content-structure-helpers";
import cnst from "@/utils/constants";
import contentAPI from "@/services/content-api";
import CurriculumJsonManagerMixin from "@/mixins/curriculum-json-manager";
import { WorkflowState } from "@/models/workflow.model";
import ContentName from "@/components/right-sidebars/content-sidebar/ContentName.vue";
import ContentLocation from "@/components/right-sidebars/content-sidebar/content-location/ContentLocation.vue";
import ContentDescription from "@/components/right-sidebars/content-sidebar/ContentDescription.vue";
import ContentMetadata from "@/components/right-sidebars/content-sidebar/ContentMetadata.vue";
import ContentWorkflow from "@/components/right-sidebars/content-sidebar/ContentWorkflow.vue";
import ContentActivityLog from "@/components/right-sidebars/content-sidebar/ContentActivityLog.vue";
import AuthoringTool from "@/components/right-sidebars/content-sidebar/authoring-tool/AuthoringTool.vue";
import CurriculumTags from "@/components/right-sidebars/content-sidebar/curriculum/CurriculumTags.vue";
import {
  ContentStructureSection,
  GeneratedContentStructure
} from "@/models/content-structure.model";
import { ProductItem, ProductPermission } from "@/models/product.model";
import { OrganizationUser } from "@/models/user.model";
import * as xApiHelper from "@/utils/product/x-api-helper";
import { XAPIError, XAPIErrors } from "@/models/x-api.model";
import ContentPreview from "@/components/right-sidebars/content-sidebar/ContentPreview.vue";
import { isEqual } from "lodash";
import RightSidebar from "@/components/right-sidebars/ui/RightSidebar.vue";
import FeatureFlagsMixin from "@/mixins/feature-flags";
import ContentIdentifiers from "@/components/right-sidebars/content-sidebar/ContentIdentifiers.vue";
import EditContentPath from "@/components/popups/EditContentPath.vue";
import { AssetLocation } from "@/models/assets.model";
import { AxiosResponse } from "axios";
import { MetadataInstance } from "@/models/product-section.model";
import { getDeadlineByWorkflowState } from "@/utils/tasks-helpers";
import {
  mdiDelete,
  mdiPackageUp,
  mdiDotsHorizontal,
  mdiChevronLeft,
  mdiChevronRight
} from "@mdi/js";
import { defineComponent } from "vue";
import { mapStores } from "pinia";
import { useUIGeneralStore } from "@/stores/ui-general.store";
import { useUIContentStore } from "@/stores/ui-content.store";

interface Data {
  currentXAPIErrors: string[];
  XAPIMetadataErrors: string[];
  XAPIAuthoringToolErrors: string[];
  currentXAPIWarnings: string[];
  XAPIQuestionsWarnings: string[];
  mdiDelete: string;
  mdiPackageUp: string;
  mdiDotsHorizontal: string;
  mdiChevronLeft: string;
  mdiChevronRight: string;
}

export default defineComponent({
  mixins: [CurriculumJsonManagerMixin, FeatureFlagsMixin],
  data(): Data {
    return {
      currentXAPIErrors: [],
      XAPIMetadataErrors: [],
      XAPIAuthoringToolErrors: [],
      currentXAPIWarnings: [],
      XAPIQuestionsWarnings: [],
      mdiDelete,
      mdiPackageUp,
      mdiDotsHorizontal,
      mdiChevronLeft,
      mdiChevronRight
    };
  },
  components: {
    RightSidebar,
    ContentPreview,
    ContentName,
    ContentLocation,
    ContentDescription,
    ContentMetadata,
    ContentWorkflow,
    ContentActivityLog,
    AuthoringTool,
    CurriculumTags,
    ContentIdentifiers,
    EditContentPath
  },
  computed: {
    ...mapStores(useUIGeneralStore, useUIContentStore),
    ...mapGetters("products", ["productDetails", "contentStructures"]),
    ...mapGetters("organizations", ["users"]),
    ...mapGetters("currentUser", ["canManageWorkflows"]),
    product(): ProductItem | undefined {
      if (this.productDetails.loading || !this.uiContentStore.sectionToDisplay) {
        return undefined;
      }
      return this.productDetails.data.find(
        (e: ProductItem): boolean => {
          return !!this.uiContentStore.sectionToDisplay && e.id === Number(this.uiContentStore.sectionToDisplay.productId)
        }
      );
    },
    structure(): GeneratedContentStructure | undefined {
      if (this.contentStructures.loading || !this.uiContentStore.sectionToDisplay) {
        return undefined;
      }
      return this.contentStructures.data.find(
        (e: GeneratedContentStructure): boolean => {
          return !!this.uiContentStore.sectionToDisplay && e.product_id === this.uiContentStore.sectionToDisplay.productId
        }
      );
    },
    section(): ContentStructureSection | undefined {
      if (this.structure && this.structure.rootSection && this.uiContentStore.sectionToDisplay) {
        const descendentSection: ContentStructureSection | null =
          csHelper.findDescendentSection(
            this.structure.rootSection,
            this.uiContentStore.sectionToDisplay.sectionId
          );
        return descendentSection ? descendentSection : undefined;
      }
      return undefined;
    },
    isAContentObject(): boolean {
      return this.section
        ? cnst.contentTemplates.isAContentObject(this.section.type.category)
        : false;
    },
    hasWorkflowStates(): boolean {
      return !!this.workflowStates.length && this.isAContentObject;
    },
    showContentDescription(): boolean {
      return (
        this.hasWorkflowStates &&
        (this.canEditContent || (!!this.section && !!this.section.instruction))
      );
    },
    path(): AssetLocation[] {
      if (this.section && this.structure && this.structure.rootSection) {
        return csHelper.getPathToSectionWithIds(
          this.structure.rootSection,
          this.section.id
        );
      }
      return [];
    },
    hasEditPermission(): boolean {
      return !!this.product
        ? this.product.allocated_permissions.some(
            (e: ProductPermission): boolean =>
              e.id === cnst.productPermissions.editContentStructure
          )
        : false;
    },
    canEditContent(): boolean {
      return this.uiGeneralStore.isEditModeEnabled && this.hasEditPermission;
    },
    canShowWorkflow(): boolean {
      return (
        this.hasWorkflowStates && !!this.workflowStates.length && !!this.product
      );
    },
    workflowStates(): WorkflowState[] {
      return this.product && this.section && this.section.workflow_id
        ? csHelper.getWorkflowStatesForSection(
            this.product,
            this.section.workflow_id
          )
        : [];
    },
    currentState(): WorkflowState | undefined {
      return this.section && this.product && this.section.workflow_id
        ? csHelper.getCurrentState(
            this.product,
            this.section.state_transitions,
            this.section.workflow_id
          )
        : undefined;
    },
    hasQTIAuthoring(): boolean {
      return (
        !!this.section && cnst.isQTIAuthoringToolId(this.section.type.tool_id)
      );
    },
    isDeployButtonShown(): boolean | undefined {
      if (this.uiContentStore.sectionToDisplay) {
        return this.hasDeployButton(
            this.hasQTIAuthoring,
            this.uiContentStore.sectionToDisplay.productId
        );
      }
      return false;
    },
    assignedUsers(): OrganizationUser[] {
      return this.users.data.filter((userToCheck: OrganizationUser) => {
        if (this.currentState) {
          return this.currentState.users.some(
            (assignedUserId: number): boolean => {
              return assignedUserId === userToCheck.id;
            }
          );
        }
        return false;
      });
    },
    deadline(): number | undefined {
      if (this.section && this.currentState && this.product) {
        return getDeadlineByWorkflowState(
          this.section,
          this.currentState,
          this.product
        );
      }
      return undefined;
    },
    isUpdating(): boolean {
      return this.productDetails.updating || this.contentStructures.updating;
    },
    displayedMetadata(): MetadataInstance[] {
      return this.section
        ? this.section.metadata_instances.filter(
            (instance: MetadataInstance) => {
              return (
                (instance.metadata_definition.type === "Text Free" &&
                  instance.authored_text) ||
                (instance.metadata_definition.type !== "Text Free" &&
                  instance.metadata_definition.metadata_allocated_values
                    .length > 0)
              );
            }
          )
        : [];
    },
    isMetadataVisible(): boolean {
      return (
        this.displayedMetadata.length > 0 ||
        (this.canEditContent &&
          !!this.section &&
          this.section.metadata_instances.length > 0)
      );
    },
    isCurriculumTagsVisible(): boolean {
      if (
        !!this.product &&
        this.doesProductHaveACurriculum &&
        this.isCurriculumEnabledForSectionType
      ) {
        return (
          !!this.curriculumConceptsGuidsFromProductSections(this.product)
            .length ||
          (!this.curriculumConceptsGuidsFromProductSections(this.product)
            .length &&
            this.canEditContent)
        );
      }
      return false;
    },
    doesProductHaveACurriculum(): boolean {
      return !!this.product && this.product.curriculum_id !== null;
    },
    isCurriculumEnabledForSectionType(): boolean {
      return (
        !!this.section &&
        !this.section.isRoot &&
        this.section.type.name !== "Folder"
      );
    },
    sectionExternalId(): string {
      return !!this.section && this.section.client_external_id
        ? this.section.client_external_id
        : "Not set";
    }
  },
  methods: {
    ...mapActions("products", ["upsertSections", "deleteSection"]),
    deployToQA(): void {
      if (this.section && this.section.authored_document_id) {
        contentAPI
          .deployToQA(this.section.authored_document_id, this.section.name)
          .then((response: AxiosResponse): void => {
            if (response.status === 200) {
              this.$buefy.snackbar.open({
                duration: 10000,
                message: this.section
                  ? `${this.section.name} ${cnst.deployWasSuccess}`
                  : `${cnst.deployWasSuccess}`,
                position: "is-bottom-right",
                actionText: "close",
                queue: false
              });
            } else {
              this.$buefy.snackbar.open({
                duration: 10000,
                message: this.section
                  ? `${cnst.deployError.deploy} ${this.section.name}.`
                  : `${cnst.deployError.deploy}`,
                type: "is-danger",
                position: "is-bottom-right",
                actionText: "close",
                queue: false
              });
            }
          })
          .catch((): void => {
            this.$buefy.snackbar.open({
              duration: 10000,
              message: this.section
                ? `${cnst.deployError.deploy} ${this.section.name}.`
                : `${cnst.deployError.deploy}`,
              type: "is-danger",
              position: "is-bottom-right",
              actionText: "close",
              queue: false
            });
          });
      }
    },
    async checkXAPI(): Promise<void> {
      if (this.uiContentStore.sectionToDisplay) {
        const allErrors: XAPIErrors = this.product
            ? await xApiHelper.validateBuildXAPI(this.product)
            : {
              contentErrors: [],
              contentTemplatesErrors: [],
              contentWarnings: []
            };
        this.uiContentStore.setXAPIErrors({
          productId: this.uiContentStore.sectionToDisplay.productId,
          ...allErrors
        });
        this.collectXAPIErrors();
      }
    },
    collectXAPIErrors(): void {
      this.currentXAPIErrors = [];
      this.currentXAPIWarnings = [];
      const currentErrors = this.uiContentStore.XAPIErrors
        ? this.uiContentStore.XAPIErrors.contentErrors.find((error: XAPIError): boolean => {
            return !!this.section && error.sectionId === this.section.id;
          })
        : undefined;
      const currentWarnings = this.uiContentStore.XAPIErrors
        ? this.uiContentStore.XAPIErrors.contentWarnings.find((error: XAPIError): boolean => {
            return !!this.section && error.sectionId === this.section.id;
          })
        : undefined;
      this.currentXAPIErrors = currentErrors ? [...currentErrors.errors] : [];
      this.currentXAPIWarnings = currentWarnings
        ? [...currentWarnings.errors]
        : [];
      this.XAPIAuthoringToolErrors = [
        ...this.getExternalLinkErrors(),
        ...this.getHTMLErrors()
      ];
      this.XAPIMetadataErrors = this.getMetadataErrors();
      this.XAPIQuestionsWarnings = this.getQTIWarnings();
    },
    getMetadataErrors(): string[] {
      return this.currentXAPIErrors.filter((error: string): boolean => {
        return error.includes(cnst.metadataValueMissing);
      });
    },
    getExternalLinkErrors(): string[] {
      return this.currentXAPIErrors.filter((error: string): boolean => {
        return (
          error.includes(cnst.externalLinkNotSet) ||
          error.includes(cnst.invalidExternalLink)
        );
      });
    },
    getHTMLErrors(): string[] {
      return this.currentXAPIErrors.filter((error: string): boolean => {
        return error.includes(cnst.noHTMLDocumentAuthored);
      });
    },
    getQTIWarnings(): string[] {
      return this.currentXAPIWarnings.filter((error: string): boolean => {
        return (
          error.includes(cnst.noQuestionsAuthored) ||
          error.includes(cnst.questionsContainErrors)
        );
      });
    },
    previewAttachment(fileLocation: string): void {
      if (this.uiContentStore.isContentSidebarExpanded) {
        this.uiContentStore.toggleContentSidebarWidth();
      }
      this.uiContentStore.setFileLocationToPreview(fileLocation);
    },
    closeSidebar(): void {
      if (this.uiContentStore.fileLocationForPreview) {
        this.uiContentStore.setFileLocationToPreview(undefined);
      }
      this.uiContentStore.closeContentSidebar();
    },
    removeContent() {
      if (this.section && this.uiContentStore.sectionToDisplay) {
        this.deleteSection({
          productId: this.uiContentStore.sectionToDisplay.productId,
          sectionsId: this.section.id
        });
      }
      this.uiContentStore.closeContentSidebar();
    },
    confirmRemoval(): void {
      this.$buefy.dialog.confirm({
        message: "Are you sure you want to continue?",
        cancelText: "No",
        confirmText: "Yes",
        onConfirm: () => this.removeContent()
      });
    }
  },
  created() {
    if (!this.curriculumJson && this.product && this.product.curriculum_id) {
      this.setCurriculumJson(this.product.curriculum_id);
    }
  },
  mounted() {
    if (this.product && this.product.is_xapi) {
      if (this.$route.name !== "content") {
        this.checkXAPI();
      } else {
        this.collectXAPIErrors();
      }
    }
  },
  watch: {
    product() {
      if (this.product && this.product.is_xapi) {
        if (this.$route.name !== "content") {
          this.checkXAPI();
        }
      }
    },
    "uiContentStore.sectionToDisplay"() {
      if (this.uiContentStore.sectionToDisplay && this.$route.name === "content") {
        this.collectXAPIErrors();
      }
    },
    "uiContentStore.XAPIErrors"(newVal, oldVal) {
      if (this.$route.name === "content" && !isEqual(newVal, oldVal)) {
        this.collectXAPIErrors();
      }
    },
    "product.curriculum_id"() {
      this.resetCurriculumData();
      if (this.product && this.product.curriculum_id) {
        this.setCurriculumJson(this.product.curriculum_id);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
ul {
  padding-left: 0;
}
li {
  list-style: none;
  padding-bottom: 0.1rem;
}
button {
  &:hover[title]::after {
    white-space: pre;
  }
}
</style>

<style lang="scss">
.expand-btn {
  position: absolute;
  top: 5.5rem;
  left: -0.8125rem;
  z-index: 10;
  border-radius: 50% !important;
  padding: 0.25rem !important;
  min-width: 0 !important;
  line-height: 1rem !important;
}
.right-sidebar.content-sidebar {
  overflow: visible !important;
  .sidebar-top {
    h3 {
      margin-right: 3rem;
    }
    .sidebar-dropdown-menu {
      position: fixed;
      z-index: 1;
      right: 3.5rem;
    }
    .dropdown-trigger button {
      color: #ffffff;
    }
  }
  .right-sidebar-body {
    padding: 1.75rem;
  }
  .content-item-wrapper {
    display: contents;
  }
  .content-item {
    width: 100%;
  }
}
.right-sidebar.content-sidebar.externalContentOpened {
  overflow: visible;
  width: 100% !important;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 902;
  .sidebar {
    margin-left: auto;
  }
}
.right-sidebar.content-sidebar.expanded {
  width: 100% !important;
  pointer-events: none;
  .sidebar {
    width: 80%;
    pointer-events: visible;
    @media (min-width: $breakpoint-md) {
      width: 80%;
    }
  }
  .right-sidebar-top-in {
    margin-right: 10.5rem;
  }
  .right-sidebar-body {
    .content-item-wrapper {
      display: block;
      &:empty {
        display: none;
      }
    }
  }
}
</style>
