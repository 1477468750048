import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3fa1688a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "sidebar-inner",
  "data-testing": "sidebar-inner"
}
const _hoisted_2 = {
  key: 0,
  class: "sidebar-logo"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "icon-close"
}
const _hoisted_5 = { class: "primary-links" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGIcon = _resolveComponent("SVGIcon")!
  const _component_SidebarLink = _resolveComponent("SidebarLink")!
  const _component_SidebarPrograms = _resolveComponent("SidebarPrograms")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidebar", { open: _ctx.uiGeneralStore.isSidebarOpen }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", {
        class: _normalizeClass([{ 'padding-standard': _ctx.uiGeneralStore.isSidebarOpen }, "lineheight-1 sidebar-header"])
      }, [
        (_ctx.uiGeneralStore.isSidebarOpen)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "THOTH"))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.uiGeneralStore.toggleSidebarWidth())),
          class: "btn-unstyled btn-toggle",
          "data-testing": "sidebar-hamburger"
        }, [
          (_ctx.uiGeneralStore.isSidebarOpen)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _createVNode(_component_SVGIcon, {
                  path: _ctx.mdiBackburger,
                  size: 24
                }, null, 8, ["path"])
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, [
                _createVNode(_component_SVGIcon, {
                  path: _ctx.mdiMenu,
                  size: 24
                }, null, 8, ["path"])
              ]))
        ])
      ], 2),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
          return (_openBlock(), _createBlock(_component_SidebarLink, {
            link: link,
            key: link.title
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(link.icon)))
            ]),
            _: 2
          }, 1032, ["link"]))
        }), 128))
      ]),
      (_ctx.uiGeneralStore.isSidebarOpen)
        ? (_openBlock(), _createBlock(_component_SidebarPrograms, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ], 2))
}