<template>
  <tippy
    :class="['hint', type]"
    :placement="position"
    :interactive="true"
    :arrow="true"
    :theme="'hint'"
  >
    <button type="button" class="btn-unstyled">
      <SVGIcon
        v-if="type === 'info'"
        :path="mdiInformationOutline"
        :size="iconSize"
        type="is-light"
      />
      <SVGIcon
        v-else-if="type === 'warning'"
        :path="mdiAlert"
        :size="iconSize"
        type="is-warning"
      />
      <SVGIcon
        v-else-if="type === 'error'"
        :path="mdiAlert"
        :size="iconSize"
        type="is-danger"
      />
    </button>
    <template #content>
      <slot></slot>
    </template>
  </tippy>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mdiAlert, mdiInformationOutline } from "@mdi/js";

export default defineComponent({
  name: "Hint",
  data() {
    return {
      mdiAlert,
      mdiInformationOutline
    };
  },
  props: {
    position: {
      required: false,
      default: "bottom",
      type: String,
      validator: (value: string) => {
        return [
          "top",
          "bottom",
          "left",
          "right",
          "top-end",
          "top-start",
          "left-start",
          "left-end",
          "right-start",
          "right-end",
          "bottom-start",
          "bottom-end"
        ].includes(value);
      }
    },
    type: {
      required: true,
      default: "info",
      type: String,
      validator: (value: string) => {
        return ["info", "warning", "error"].includes(value);
      }
    },
    iconSize: {
      // possible options - any numbers
      type: Number,
      required: false,
      default: 24
    }
  }
});
</script>
<style lang="scss">
.hint {
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: middle;
  cursor: pointer;
  &.info {
    svg path {
      fill: $border-color-dark !important;
    }
  }
}
.tippy-box[data-theme~="hint"] {
  background-color: $primary-color;
  > * {
    font-weight: 400;
  }
  ul {
    padding-left: 1rem;
    list-style-type: disc;
    text-align: left;
    font-size: 0.85rem;
    li {
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  p {
    text-align: left;
    font-size: 0.85rem;
    &:last-child {
      padding-bottom: 0;
    }
  }
  a {
    text-decoration: underline;
    color: #ffffff;
    &:hover,
    &:active {
      text-decoration: none;
      color: #ffffff;
      opacity: 0.8;
    }
  }
  &[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: $primary-color;
  }
  &[data-placement^="bottom"] > .tippy-arrow::before {
    border-bottom-color: $primary-color;
  }
  &[data-placement^="left"] > .tippy-arrow::before {
    border-left-color: $primary-color;
  }
  &[data-placement^="right"] > .tippy-arrow::before {
    border-right-color: $primary-color;
  }
}
</style>
