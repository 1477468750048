import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-63be2ecc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "path" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "path-btn-text"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "path-btn-text"
}
const _hoisted_9 = {
  key: 1,
  class: "path-btn-text"
}
const _hoisted_10 = { key: 3 }
const _hoisted_11 = {
  key: 0,
  class: "path-btn-text"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  key: 0,
  class: "path-btn-text"
}
const _hoisted_14 = {
  key: 1,
  class: "path-btn-text"
}
const _hoisted_15 = { class: "current-section" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SectionIcon = _resolveComponent("SectionIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", { title: _ctx.internalPath }, [
      (_ctx.isClickable)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: `/product/${_ctx.product.id}`,
            class: "btn-unstyled"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.product.name), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.product.name), 1)),
      _createTextVNode("  / "),
      (_ctx.path.length && _ctx.isClickable)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.path, (item) => {
              return (_openBlock(), _createElementBlock("button", {
                key: item.id,
                class: "btn-unstyled",
                onClick: ($event: any) => (
            _ctx.uiContentStore.openContentSidebar({
              sectionId: item.id,
              productId: _ctx.product.id,
              workflowStateId: 0,
              hasCloseButton: true
            })
          )
              }, [
                _createVNode(_component_SectionIcon, {
                  class: "mr-1",
                  "icon-id": item.iconId,
                  size: 16
                }, null, 8, ["icon-id"]),
                (_ctx.showAsExternal)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.externalId), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_7, [
                      (_ctx.isLongPathNameEnabled)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(item.name), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.productShortName) + "-" + _toDisplayString(item.id), 1))
                    ])),
                _createTextVNode(" /  ")
              ], 8, _hoisted_5))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.path.length && !_ctx.isClickable)
        ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.path, (item) => {
              return (_openBlock(), _createElementBlock("span", {
                key: item.id
              }, [
                _createVNode(_component_SectionIcon, {
                  class: "mr-1",
                  "icon-id": item.iconId,
                  size: 16
                }, null, 8, ["icon-id"]),
                (_ctx.showAsExternal)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(item.externalId), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_12, [
                      (_ctx.isLongPathNameEnabled)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(item.name), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.productShortName) + "-" + _toDisplayString(item.id), 1))
                    ])),
                _createTextVNode(" /  ")
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_15, [
        _createVNode(_component_SectionIcon, {
          class: "mr-1",
          "icon-id": _ctx.sectionIconId,
          size: 16
        }, null, 8, ["icon-id"]),
        (_ctx.showAsExternal)
          ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
              _createElementVNode("span", null, _toDisplayString(_ctx.sectionExternalId), 1)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_17, [
              (_ctx.isLongPathNameEnabled)
                ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.sectionName.trim()), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.productShortName) + "-" + _toDisplayString(_ctx.sectionId), 1))
            ]))
      ])
    ], 8, _hoisted_2)
  ]))
}