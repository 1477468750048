import { defineStore } from "pinia";
import API from "@/api";
import { ref } from "vue";
import { AssetsMetadataItem } from "@/models/assets.model";
import { AxiosResponse } from "axios";
import { AssetsMetadataResponse } from "@/models/api/assets.model";

export const useAssetsMetadataStore = defineStore("assetsMetadata", () => {
  const isAssetsMetadataLoading = ref<boolean>(true);
  const isAssetsMetadataUpdating = ref<boolean>(false);
  const assetsMetadata = ref<AssetsMetadataItem[]>([]);

  const populateAssetsMetadata = (contentTemplateId: number) => {
    return API.assets
      .get(contentTemplateId)
      .then((response: AssetsMetadataItem) => {
        assetsMetadata.value = assetsMetadata.value.filter(
          (item: AssetsMetadataItem) => {
            return item.sections_type_id !== response.sections_type_id;
          }
        );
        assetsMetadata.value.push(response);
        isAssetsMetadataLoading.value = false;
        isAssetsMetadataUpdating.value = false;
      });
  };

  const updateAssetsMetadata = (
    payload: AssetsMetadataItem
  ): Promise<AxiosResponse<AssetsMetadataResponse>> => {
    isAssetsMetadataUpdating.value = true;
    const contentTypeId = payload.sections_type_id;
    return API.assets
      .update(payload)
      .then(async (response: AxiosResponse<AssetsMetadataResponse>) => {
        await populateAssetsMetadata(contentTypeId);
        return response;
      });
  };

  return {
    isAssetsMetadataLoading,
    isAssetsMetadataUpdating,
    assetsMetadata,
    populateAssetsMetadata,
    updateAssetsMetadata
  };
});
