<template>
  <label
    :class="[
      'toggle-check',
      {
        'right-sidebar-open': uiGeneralStore.isRightSidebarOpen,
        'content-sidebar-expanded': isContentSidebarWiden,
        'modals-open': areModalsOpen
      }
    ]"
    data-testing="edit-mode"
  >
    <input
      type="checkbox"
      aria-label="edit"
      data-testing="toggle-edit-mode"
      :value="uiGeneralStore.isEditModeEnabled"
      class="editable"
      @change="uiGeneralStore.toggleEditModeEnabled"
    />
    <span data-off-text="Edit mode off" data-on-text="Edit mode on"></span>
  </label>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { mapStores } from "pinia";
import { useUIGeneralStore } from "@/stores/ui-general.store";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useUIContentStore } from "@/stores/ui-content.store";

export default defineComponent({
  name: "ToggleEditMode",
  computed: {
    ...mapStores(useUIGeneralStore, useUIFilesStore, useUIContentStore),
    ...mapGetters("currentUser", ["canManageProducts"]),
    isContentSidebarWiden(): boolean {
      return !!this.uiContentStore.sectionToDisplay && this.uiContentStore.isContentSidebarExpanded;
    },
    areModalsOpen(): boolean {
      return this.uiFilesStore.isFileModalOpen || this.uiContentStore.isEditContentPathModalOpen;
    }
  }
});
</script>
<style lang="scss" scoped>
$position-right: 3rem;
.toggle-check {
  position: fixed;
  top: 0.7rem;
  right: $position-right;
  margin-left: 0;
  z-index: 901;
  transform: scale(0.75);
  transition: right 0.3s ease-out;
  &.modals-open {
    z-index: 900;
  }
  &.right-sidebar-open {
    right: $right-sidebar-width-sm + $position-right;
    @media (min-width: $breakpoint-md) {
      right: $right-sidebar-width-md + $position-right;
    }
  }
  &.content-sidebar-expanded {
    top: 1.6rem;
    right: 6.5rem;
    @media (min-width: $breakpoint-md) {
      right: 5.5rem;
    }
  }
}
</style>
