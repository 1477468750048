// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import API from "@/api/index";
import { getCurriculumJsonFromXml } from "@/utils/curriculum/get-curriculum-json-from-xml";
import {
  ConceptJson,
  CurriculumJson,
  TopicJson
} from "@/models/curriculum.model";
import { ProductItem } from "@/models/product.model";
import {
  SectionConcepts,
  ProductSection
} from "@/models/product-section.model";
const { uniq } = require("lodash");

interface Data {
  isCurriculumLoading: boolean;
  curriculumJson: CurriculumJson | null;
  topicsFromXml: TopicJson[] | null;
  conceptsFromXml: ConceptJson[] | null;
  xmlParsedSuccessfully: boolean;
}

const CurriculumJSONManagerMixin = {
  data(): Data {
    return {
      isCurriculumLoading: true,
      curriculumJson: null,
      topicsFromXml: null,
      conceptsFromXml: null,
      xmlParsedSuccessfully: false
    };
  },
  methods: {
    async setCurriculumJson(curriculumId: number): Promise<void> {
      const response = await API.curriculum.get(curriculumId);
      const curriculumXml = response.curriculum;
      try {
        this.curriculumJson = getCurriculumJsonFromXml(curriculumXml);
        this.topicsFromXml = this.curriculumJson.topicsJSON.sort(
          (topicA: TopicJson, topicB: TopicJson) =>
            topicA.displayOrder - topicB.displayOrder
        );
        this.conceptsFromXml = this.curriculumJson.conceptsJSON;
        this.xmlParsedSuccessfully = true;
      } catch (error) {
        this.$buefy.toast.open("Curriculum file is invalid");
      }
      this.isCurriculumLoading = false;
    },
    resetCurriculumData(): void {
      this.curriculumJson = null;
      this.topicsFromXml = null;
      this.conceptsFromXml = null;
      this.xmlParsedSuccessfully = false;
    },
    curriculumConceptsGuidsFromProductSections(product: ProductItem): string[] {
      const productSectionsConceptsGuids: string[] = [];
      if (this.curriculumJson && this.curriculumJson.conceptsJSON) {
        const conceptsGuidsFromCurriculum =
          this.curriculumJson.conceptsJSON.map(conceptJson => conceptJson.guid);
        product.sections.forEach((productSection: ProductSection) => {
          productSection.concepts.forEach((sectionConcept: SectionConcepts) => {
            if (
              conceptsGuidsFromCurriculum.includes(sectionConcept.concept_guid)
            ) {
              productSectionsConceptsGuids.push(sectionConcept.concept_guid);
            }
          });
        });
      }
      return uniq(productSectionsConceptsGuids);
    }
  }
};
export default CurriculumJSONManagerMixin;
