import { toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_editable = _resolveDirective("editable")!

  return _withDirectives((_openBlock(), _createElementBlock("h3", {
    "data-testing": "edit-section-name",
    onEdited: _cache[0] || (_cache[0] = ($event: any) => (_ctx.saveContentName($event))),
    textContent: _toDisplayString(_ctx.contentName)
  }, null, 40, _hoisted_1)), [
    [_directive_editable, _ctx.canEditContent]
  ])
}