<template>
  <div class="rename-file modal-card" data-testing="rename-file-card">
    <div class="modal-card-header">
      <h3> Rename attachment </h3>
    </div>
    <form
      id="rename-attachment"
      class="modal-card-content"
      @submit.prevent="rename"
    >
      <label class="margin-b-standard">
        Enter a new attachment name
        <input
          v-model.trim="newName"
          type="text"
          data-testing="rename-file-input"
        />
      </label>
    </form>
    <div class="modal-card-footer">
      <button
        type="submit"
        data-testing="rename-file-btn"
        class="btn-1 full-width"
        form="rename-attachment"
        :disabled="!isSubmitBtnEnabled"
      >
        Rename
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import FilesMixin from "@/mixins/files";
import { LibraryFile } from "@/models/files.model";
import { mapStores } from "pinia";
import { useUIFilesStore } from "@/stores/ui-files.store";

interface Data {
  newName: string;
}

export default defineComponent({
  name: "RenameAttachment",
  props: {
    file: {
      type: Object as PropType<LibraryFile>,
      required: true
    }
  },
  mixins: [FilesMixin],
  data: (): Data => {
    return {
      newName: ""
    };
  },
  computed: {
    ...mapStores(useUIFilesStore),
    isSubmitBtnEnabled(): boolean {
      return this.newName !== this.file.name && this.newName !== "";
    }
  },
  methods: {
    rename() {
      this.renameAttachment(
        {
          id: this.file.id,
          name: this.newName
        },
        this.file
      );
      this.uiFilesStore.toggleFileModal();
    }
  },
  mounted() {
    this.newName = this.file.name;
  }
});
</script>
<style lang="scss" scoped>
.rename-file {
  width: auto;
}
form {
  min-width: 23rem;
}
</style>
