// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { ProductSummaryItem } from "@/models/product.model";
import { mapGetters } from "vuex";
import { getAllowedProducts } from "@/utils/filter-products-helpers";
import { mapStores } from "pinia";
import { useCurriculumsStore } from "@/stores/curriculums.store";

const PopulateStoreMixin = {
  computed: {
    ...mapGetters("currentUser", ["currentUser"]),
    ...mapGetters("products", ["productSummaries"]),
    ...mapStores(useCurriculumsStore)
  },
  methods: {
    populateStoreWithGeneralData(): void {
      this.populateProductDetails();
      this.$store.dispatch("products/populateProgrammes");
      this.$store.dispatch("organizations/populateUsers");
      this.$store.dispatch("organizations/populateAvailableOrgPermissions");
      this.$store.dispatch("organizations/populateOrganization");
      this.curriculumsStore.populateCurriculums();
      this.$store.dispatch("files/fetchFiles");
    },

    async populateProductDetails(): Promise<void> {
      await this.$store.dispatch("currentUser/populateCurrentUser");
      await this.$store.dispatch("products/populateProductSummaries");
      if (this.currentUser.id && this.productSummaries.data.length > 0) {
        let allowedProducts = getAllowedProducts(
          this.productSummaries.data,
          this.currentUser.permissions
        );
        if (this.$route.params.productId) {
          allowedProducts = allowedProducts.filter(
            (product: ProductSummaryItem) => {
              return product.id !== Number(this.$route.params.productId);
            }
          );
          await this.$store.dispatch(
            "products/fetchProductDetails",
            Number(this.$route.params.productId)
          );
        }
        allowedProducts.forEach((product: ProductSummaryItem): void => {
          this.$store.dispatch("products/fetchProductDetails", product.id);
        });
      }
    }
  }
};
export default PopulateStoreMixin;
