// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  LibraryFile,
  LibraryFileProduct,
  LibraryFileSection
} from "@/models/files.model";
import { mapActions, mapGetters } from "vuex";
import { ContentStructureSection } from "@/models/content-structure.model";
import {
  FileToLinkToProduct,
  FileToLinkToSection,
  FileToUploadForMixin,
  FileToRename,
  FileToUpsertResponse,
  FileToUnlinkFromProduct,
  FileToUnlinkFromSection,
  OnlineFileToAttachToSection,
  OnlineFileToAttachToProduct
} from "@/models/api/files.model";
import { AxiosResponse } from "axios";
import { mapStores } from "pinia";
import { useUIFilesStore } from "@/stores/ui-files.store";

const FilesMixin = {
  computed: {
    ...mapStores(useUIFilesStore),
    ...mapGetters("products", ["productDetails", "contentStructures"]),
  },
  methods: {
    ...mapActions("products", [
      "flagProductDetailsUpdating",
      "flagContentStructuresUpdating",
      "fetchProductDetails",
      "linkExistingFileToProduct",
      "linkExistingFileToSection",
      "deleteFileLinkFromProduct",
      "deleteFileLinkFromSection",
      "upsertSections"
    ]),
    ...mapActions("files", [
      "attachOnlineFile",
      "renameFile",
      "uploadNewFile",
      "flagFilesUpdating",
      "fetchFiles",
      "deleteFile",
      "uploadNewFileVersion"
    ]),
    getProductIdsToUpdate(file: LibraryFile): number[] {
      const productIds = [
        ...file.sections.map((e: LibraryFileSection) => e.product_id),
        ...file.products.map((e: LibraryFileProduct) => e.product_id)
      ];
      return [...new Set(productIds)]; // Unique elements only
    },
    renameAttachment(
      payload: FileToRename,
      file: LibraryFile
    ): Promise<AxiosResponse<FileToUpsertResponse>> {
      const productIds = this.getProductIdsToUpdate(file);
      this.flagProductDetailsUpdating();
      this.flagContentStructuresUpdating();
      this.flagFilesUpdating();
      return this.renameFile(payload).then(
        (response: AxiosResponse<FileToUpsertResponse>) => {
          productIds.forEach((id: number) => this.fetchProductDetails(id));
          return response;
        }
      );
    },
    addOnlineAttachment(
      payload: OnlineFileToAttachToSection | OnlineFileToAttachToProduct
    ): Promise<AxiosResponse<FileToUpsertResponse>> {
      this.flagProductDetailsUpdating();
      this.flagContentStructuresUpdating();
      this.flagFilesUpdating();
      return this.attachOnlineFile(payload).then(
        (response: AxiosResponse<FileToUpsertResponse>) => {
          this.fetchProductDetails(payload.productId);
          return response;
        }
      );
    },
    linkFileToProduct(payload: FileToLinkToProduct): Promise<void> {
      this.flagFilesUpdating();
      return this.linkExistingFileToProduct(payload).then(() => {
        this.fetchFiles();
      });
    },
    linkFileToSection(payload: FileToLinkToSection): Promise<void> {
      this.flagFilesUpdating();
      return this.linkExistingFileToSection(payload).then(() => {
        this.fetchFiles();
      });
    },
    unlinkFileFromProduct(payload: FileToUnlinkFromProduct): Promise<void> {
      this.flagFilesUpdating();
      return this.deleteFileLinkFromProduct(payload);
    },
    unlinkFileFromSection(payload: FileToUnlinkFromSection): Promise<void> {
      this.flagFilesUpdating();
      return this.deleteFileLinkFromSection(payload);
    },
    uploadFileVersion(payload: FileToUploadForMixin): Promise<void> {
      const productId = payload.productId;
      this.flagProductDetailsUpdating();
      this.flagContentStructuresUpdating();
      this.flagFilesUpdating();
      return this.uploadNewFileVersion(payload.formData).then(() => {
        if (productId) {
          this.fetchProductDetails(productId);
        }
      });
    },
    uploadFile(payload: FileToUploadForMixin): Promise<void> {
      const productId = payload.productId;
      this.flagProductDetailsUpdating();
      this.flagContentStructuresUpdating();
      this.flagFilesUpdating();
      return this.uploadNewFile(payload.formData).then(() => {
        if (productId) {
          this.fetchProductDetails(productId);
        }
      });
    },
    removeFile(file: LibraryFile) {
      const productIds = this.getProductIdsToUpdate(file);
      this.flagProductDetailsUpdating();
      this.flagContentStructuresUpdating();
      this.flagFilesUpdating();
      return this.deleteFile(file.id).then(() => {
        productIds.forEach((id: number) => {
          this.fetchProductDetails(id);
        });
      });
    },
    setAsMediaContent(
      fileLocation: string,
      fileId: number,
      section?: ContentStructureSection
    ): void {
      const sectionToSet = section ? section : this.uiFilesStore.sectionToAttachFile;
      if (
        !sectionToSet.authored_content ||
        fileId !== sectionToSet.authored_content.externalContent.attachmentId ||
        fileLocation !== sectionToSet.authored_content.externalContent
      ) {
        const sectionAuthContent = sectionToSet.authored_content
          ? sectionToSet.authored_content
          : {};
        const newAuthContent = Object.assign({}, sectionAuthContent, {
          externalContent: {
            attachmentId: fileId
          }
        });
        this.upsertSections({
          productIds: [sectionToSet.product_id],
          sections: [
            {
              id: sectionToSet.id,
              authored_content: newAuthContent
            }
          ]
        });
      }
    },
    unsetAsMediaContent(section?: ContentStructureSection): void {
      const sectionToUnset = section ? section : this.uiFilesStore.sectionToAttachFile;
      const newAuthContent = sectionToUnset.authored_content
        ? Object.assign({}, sectionToUnset.authored_content)
        : {};
      delete newAuthContent.externalContent;
      this.upsertSections({
        productIds: [sectionToUnset.product_id],
        sections: [
          {
            id: sectionToUnset.id,
            authored_content: newAuthContent
          }
        ]
      });
    }
  }
};

export default FilesMixin;
