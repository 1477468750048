import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6c7120db"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Collapse = _resolveComponent("Collapse")!

  return (_openBlock(), _createBlock(_component_Collapse, null, {
    title: _withCtx(() => [
      _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
    ]),
    content: _withCtx(() => [
      (!_ctx.canEditContent)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.section.instruction), 1))
        : _withDirectives((_openBlock(), _createElementBlock("textarea", {
            key: 1,
            id: "description-text",
            ref: "description-text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.section.instruction) = $event)),
            onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveDescriptionText($event))),
            "data-testing": "description"
          }, null, 544)), [
            [_vModelText, _ctx.section.instruction]
          ])
    ]),
    _: 1
  }))
}