import DAO from "@/api/DAO";
import {
  LESSONS_URL,
  STRATEGY_URL,
  CONTENT_OBJECTS_URL,
  COURSE_DETAILS_URL,
  CONCEPT_MAP_URL,
  PREVIEW_CONTENT_URL
} from "@/api/URL-constants";
import axios, { AxiosError, AxiosResponse } from "axios";
import {
  ContentPreviewLink,
  ContentPreviewLinkResponse,
  StrategyResponse
} from "@/models/api/adaptive-learning-systems.model";
import { Strategy } from "@/models/adaptive-learning-systems.model";
import { Lesson, LessonDetailsResponse } from "@/models/api/lesson.model";
import {
  ContentObject,
  ContentObjectsResponse
} from "@/models/api/content-objects.model";
import {
  CourseDetails,
  CourseDetailsResponse
} from "@/models/api/course-details.model";
import { ConceptMap, ConceptMapResponse } from "@/models/api/concept-map.model";

const getStrategies = (courseId: number, productId: number) => {
  return DAO()
    .get(`${STRATEGY_URL}/${courseId}/${productId}`)
    .then((response: AxiosResponse<StrategyResponse>): Strategy[] => {
      return response.data.data;
    });
};

const getLessonReviewDetails = (alsId: number, account: string) => {
  return DAO()
    .get(`${LESSONS_URL}/${alsId}?account=${account}`)
    .then((response: AxiosResponse<LessonDetailsResponse>): Lesson[] => {
      return JSON.parse(response.data.data);
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        throw error.response.data.message;
      } else {
        throw error.message;
      }
    });
};

const getDeployedContentObjects = (alsId: number) => {
  return DAO()
    .get(`${CONTENT_OBJECTS_URL}/${alsId}`)
    .then(
      (response: AxiosResponse<ContentObjectsResponse>): ContentObject[] => {
        return JSON.parse(response.data.data);
      }
    )
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        throw error.response.data.message;
      } else {
        throw error.message;
      }
    });
};

const getCourseDetails = (alsId: number) => {
  return DAO()
    .get(`${COURSE_DETAILS_URL}/${alsId}`)
    .then((response: AxiosResponse<CourseDetailsResponse>): CourseDetails => {
      return response.data.data;
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        throw error.response.data.message;
      } else {
        throw error.message;
      }
    });
};

const getMapLink = (alsId: number, nodeGuid: string) => {
  return DAO()
    .get(`${CONCEPT_MAP_URL}/${alsId}/${nodeGuid}`)
    .then((response: AxiosResponse<ConceptMapResponse>): ConceptMap => {
      return response.data.data;
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error) && error.response) {
        throw error.response.data.message;
      } else {
        throw error.message;
      }
    });
};

const getPreviewContentLink = (alsId: number) => {
  return DAO()
    .get(`${PREVIEW_CONTENT_URL}/${alsId}`)
    .then(
      (
        response: AxiosResponse<ContentPreviewLinkResponse>
      ): ContentPreviewLink => {
        return response.data.data;
      }
    );
};

export {
  getStrategies,
  getLessonReviewDetails,
  getDeployedContentObjects,
  getCourseDetails,
  getMapLink,
  getPreviewContentLink
};
