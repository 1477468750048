import DAO from "@/api/DAO";
import { ORGANIZATIONS_URL } from "@/api/URL-constants";
import { AxiosResponse } from "axios";
import { OrganizationResponse } from "@/models/api/organization.model";

const get = (): Promise<AxiosResponse<OrganizationResponse>> => {
  return DAO().get(ORGANIZATIONS_URL);
};

export { get };
