<template>
  <div class="sidebar" :class="{ open: uiGeneralStore.isSidebarOpen }">
    <div class="sidebar-inner" data-testing="sidebar-inner">
      <h2
        :class="{ 'padding-standard': uiGeneralStore.isSidebarOpen }"
        class="lineheight-1 sidebar-header"
      >
        <span v-if="uiGeneralStore.isSidebarOpen" class="sidebar-logo">THOTH</span>
        <button
          @click="uiGeneralStore.toggleSidebarWidth()"
          class="btn-unstyled btn-toggle"
          data-testing="sidebar-hamburger"
        >
          <span v-if="uiGeneralStore.isSidebarOpen">
            <SVGIcon :path="mdiBackburger" :size="24" />
          </span>
          <span v-else class="icon-close">
            <SVGIcon :path="mdiMenu" :size="24" />
          </span>
        </button>
      </h2>
      <div class="primary-links">
        <SidebarLink v-for="link in links" :link="link" :key="link.title">
          <component :is="link.icon"></component>
        </SidebarLink>
      </div>
      <SidebarPrograms v-if="uiGeneralStore.isSidebarOpen" />
    </div>
  </div>
</template>

<script lang="ts">
// @ts-nocheck
import { mapGetters } from "vuex";
import HomeIcon from "@/components/svg-icons/HomeIcon.vue";
import TasksIcon from "@/components/svg-icons/TasksIcon.vue";
import ProductsIcon from "@/components/svg-icons/ProductsIcon.vue";
import ProgrammesIcon from "@/components/svg-icons/ProgrammesIcon.vue";
import CurriculumIcon from "@/components/svg-icons/CurriculumIcon.vue";
import FilesIcon from "@/components/svg-icons/FilesIcon.vue";
import TeamIcon from "@/components/svg-icons/TeamIcon.vue";
import GetTasksQueryMixin from "@/mixins/get-tasks-query";
import { SidebarNavigationLink } from "@/models/sidebar.model";
import SidebarLink from "@/components/sidebar/SidebarLink.vue";
import SidebarPrograms from "@/components/sidebar/SidebarPrograms.vue";
import { getProductsForDisplay } from "@/utils/filter-products-helpers";
import { ProductSummaryItem } from "@/models/product.model";
import { defineComponent } from "vue";
import { mdiMenu, mdiBackburger } from "@mdi/js";
import { mapStores } from "pinia";
import { useUIGeneralStore } from "@/stores/ui-general.store";

interface Data {
  views: {
    home: string[];
    tasks: string[];
    programmes: string[];
    products: string[];
    users: string[];
    files: string[];
    curriculums: string[];
  };
  mdiMenu: string;
  mdiBackburger: string;
}

export default defineComponent({
  name: "Sidebar",
  mixins: [GetTasksQueryMixin],
  components: {
    HomeIcon,
    TasksIcon,
    TeamIcon,
    ProductsIcon,
    ProgrammesIcon,
    CurriculumIcon,
    FilesIcon,
    SidebarLink,
    SidebarPrograms
  },
  data(): Data {
    return {
      views: {
        home: ["home"],
        tasks: ["tasks"],
        programmes: [
          "programmes",
          "products",
          "createProgramme",
          "createProductInProgramme",
          "createProduct",
          "product",
          "content",
          "progress",
          "product-permissions",
          "productTasks",
          "planning",
          "content-templates",
          "assets",
          "qti-question-bank",
          "qti-editor",
          "qti-preview"
        ],
        products: ["all-products"],
        users: ["organizations", "createUser"],
        files: ["files"],
        curriculums: ["curriculums", "createCurriculum"]
      },
      mdiMenu,
      mdiBackburger
    };
  },
  computed: {
    ...mapStores(useUIGeneralStore),
    ...mapGetters("products", ["productSummaries"]),
    ...mapGetters("currentUser", [
      "currentUser",
      "canManageUsers",
      "canManageProducts",
      "canManageWorkflows"
    ]),
    ...mapGetters("files", ["files"]),
    ...mapGetters("organizations", [
      "canManageProgrammes",
      "canManageCurriculums"
    ]),
    currentRoute(): string | symbol | undefined {
      return this.$route.name;
    },
    hasAProgrammesPermission(): boolean {
      return this.canManageProgrammes;
    },
    hasACurriculumsPermission(): boolean {
      return this.canManageCurriculums;
    },
    productsForDisplay(): ProductSummaryItem[] {
      return getProductsForDisplay(
        this.productSummaries.data,
        this.currentUser.permissions
      );
    },
    links(): SidebarNavigationLink[] {
      return [
        {
          title: "Home",
          isHighlighted: this.currentRoute
            ? // @ts-ignore
              this.views.home.includes(this.currentRoute)
            : false,
          to: {
            name: "home",
            query: this.getTasksQuery() as unknown as Record<string, string>
          },
          icon: "HomeIcon"
        },
        {
          title: "Tasks",
          dataTesting: "sidebar-tasks",
          isHighlighted: this.currentRoute
            ? // @ts-ignore
              this.views.tasks.includes(this.currentRoute)
            : false,
          isNotAvailable:
            !this.hasAProgrammesPermission || this.currentUser.loading,
          to: {
            name: "tasks",
            query: this.getTasksQuery() as unknown as Record<string, string>
          },
          icon: "TasksIcon"
        },
        {
          title: "Products",
          dataTesting: "sidebar-products",
          isHighlighted: this.currentRoute
            ? // @ts-ignore
              this.views.products.includes(this.currentRoute)
            : false,
          isNotAvailable: this.productsForDisplay.length === 0,
          to: {
            name: "all-products"
          },
          icon: "ProductsIcon"
        },
        {
          title: "Programmes",
          dataTesting: "sidebar-programmes",
          isHighlighted: this.currentRoute
            ? // @ts-ignore
              this.views.programmes.includes(this.currentRoute)
            : false,
          isNotAvailable: !this.hasAProgrammesPermission,
          to: {
            name: "programmes"
          },
          icon: "ProgrammesIcon"
        },
        {
          title: "Curriculums",
          dataTesting: "sidebar-curriculums",
          isHighlighted: this.currentRoute
            ? // @ts-ignore
              this.views.curriculums.includes(this.currentRoute)
            : false,
          isNotAvailable: !this.hasACurriculumsPermission,
          to: {
            name: "curriculums"
          },
          icon: "CurriculumIcon"
        },
        {
          title: "Attachments",
          dataTesting: "sidebar-files",
          isHighlighted: this.currentRoute
            ? // @ts-ignore
              this.views.files.includes(this.currentRoute)
            : false,
          isNotAvailable: !this.hasAProgrammesPermission,
          to: {
            name: "files"
          },
          icon: "FilesIcon"
        },
        {
          title: "Users",
          dataTesting: "sidebar-manage-permissions",
          isHighlighted: this.currentRoute
            ? // @ts-ignore
              this.views.users.includes(this.currentRoute)
            : false,
          to: {
            name: "users"
          },
          icon: "TeamIcon"
        }
      ];
    }
  }
});
</script>

<style scoped lang="scss">
.sidebar {
  overflow: hidden;
  float: left;
  height: 100vh;
  color: white;
  position: sticky;
  top: 0;
  width: $sidebar-width-sm;
  background-color: $primary-color;
  z-index: 901; // Must be greater than the z-index of the header
  overflow-y: auto;
  transition: width 0.3s ease-out;

  &.open {
    width: $sidebar-width;
    @media (max-width: $breakpoint-md) {
      width: $sidebar-width-md;
    }
  }
}
.sidebar-inner {
  width: $sidebar-width;
  @media (max-width: $breakpoint-md) {
    width: $sidebar-width-md;
  }
}
.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid $blue100;
}
.sidebar-logo {
  font-weight: 800;
  font-size: 20px;
  color: $blue100;
}
.btn-toggle {
  transition: color 0.2s ease-out;
  color: $blue20;
  &:hover,
  &:active,
  &:focus {
    color: $blue40;
  }
}
.icon-close {
  padding: 1rem 0;
  width: $sidebar-width-sm;
  display: flex;
  justify-content: center;
}
a {
  color: white;
  &:hover,
  &:focus {
    color: white;
    text-decoration: underline;
    outline: none;
  }
}
.primary-links {
  overflow: hidden;
  padding: 1rem 0;
}
hr {
  margin: 0;
  border: 1px solid $primary-color;
  border-bottom: 1px solid $primary-color-light;
}
</style>
