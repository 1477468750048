<template>
  <section>
    <b-collapse
      class="card"
      animation="slide"
      aria-id="contentIdForA11y3"
      data-testing="collapse-card"
    >
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          aria-controls="contentIdForA11y3"
          data-testing="collapse-card-header"
        >
          <div
            class="card-header-title"
            data-testing="collapse-card-header-title"
          >
            <slot name="title"></slot>
          </div>
          <a class="card-header-icon">
            <SVGIcon :path="props.open ? mdiMenuDown : mdiMenuUp" :size="24" />
          </a>
        </div>
      </template>

      <div :class="['card-content', { empty: isContentEmpty }]">
        <div>
          <slot name="content"></slot>
        </div>
      </div>
      <slot name="footer" data-testing="slot-footer"></slot>
    </b-collapse>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mdiMenuDown, mdiMenuUp } from "@mdi/js";

export default defineComponent({
  name: "Collapse",
  data() {
    return {
      mdiMenuDown,
      mdiMenuUp
    };
  },
  props: {
    isContentEmpty: {
      type: Boolean,
      required: false
    }
  }
});
</script>
<style lang="scss" scoped>
.card-header-title {
  padding: 0.75rem;
  font-weight: 700;
}
.card-content {
  padding: 0.75rem;
}
.card-content.empty {
  padding: 0;
}
</style>
