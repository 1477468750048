<template>
  <div class="main-view">
    <router-view />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AuthLayout"
});
</script>
<style scoped></style>
