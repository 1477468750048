import axios, { AxiosError, AxiosResponse } from "axios";
import {
  ContentStructureSummary,
  GeneratedContentStructure
} from "@/models/content-structure.model";
import { Programme, ProgrammeSummary } from "@/models/programme.model";
import {
  ProductDetailsSummary,
  ProductItem,
  ProductSummary,
  ProductSummaryItem
} from "@/models/product.model";
import csHelper from "@/utils/product/content-structure-helpers";
import API from "@/api/index";
import {
  BatchToCreate,
  BatchToRemove,
  BatchToUpdate,
  DeadlineToUpdate,
  SectionToAddToBatch,
  SectionToRemoveFromBatch
} from "@/models/api/batches.model";
import {
  ContentToDelete,
  MessageToAdd,
  MessageToDelete,
  SectionsToUpsert,
  StateTransitionToSubmit,
  UpsertResponse
} from "@/models/api/content.model";
import { ActionContext, Commit, Dispatch } from "vuex";
import {
  ContentTemplateToCreate,
  ContentTemplateToRemove,
  ContentTemplateToUpdate,
  ContentTemplateToUpdateWorkflows,
  CreateContentTemplateResponse
} from "@/models/api/content-templates.model";
import {
  UserToAddToStateForStore,
  UserToRemoveFromStateForStore
} from "@/models/api/users.model";
import { ProductState } from "@/models/store/product-state.model";
import { RootState } from "@/models/store/root-state.model";
import {
  AllocatedValueToCreateForStore,
  AllocatedValueToDeleteForStore,
  AllowedValueToCreateForStore,
  AllowedValueToDeleteForStore,
  AllowedValueToUpdateForStore,
  DefinitionToCreateForStore,
  DefinitionToCreateResponse,
  DefinitionToDeleteForStore,
  DefinitionToImport,
  DefinitionToUpdateForStore,
  InstanceToUpdateForStore
} from "@/models/api/metadata.model";
import {
  ProgramToCreate,
  ProgramToCreateResponse,
  ProgramToUpdate
} from "@/models/api/program.model";
import {
  CreateProductResponse,
  ProductFeatureToUpdate,
  ProductImageForStore,
  ProductToCreate,
  ProductToUpdate
} from "@/models/api/product.model";
import {
  FileToLinkToProduct,
  FileToLinkToSection,
  FileToUnlinkFromProduct,
  FileToUnlinkFromSection
} from "@/models/api/files.model";
import { DocumentToUpdateStore } from "@/models/api/document.model";

export default {
  namespaced: true,
  state: {
    programmes: {
      loading: true,
      updating: false,
      data: []
    },
    products: {
      data: [],
      loading: true,
      updating: false
    },
    productDetails: {
      data: [],
      loading: true,
      updating: false
    },
    contentStructures: {
      data: [],
      updating: false,
      loading: true
    }
  } as ProductState,
  getters: {
    programmes(state: ProductState): ProgrammeSummary {
      return state.programmes;
    },
    productSummaries(state: ProductState): ProductSummary {
      return state.products;
    },
    productDetails(state: ProductState): ProductDetailsSummary {
      return state.productDetails;
    },
    contentStructures(state: ProductState): ContentStructureSummary {
      return state.contentStructures;
    }
  },
  actions: {
    //Programmes
    fetchProgrammes(
      context: ActionContext<ProductState, RootState>
    ): Promise<void> | void {
      return API.program.getAll().then((programmes: Programme[]) => {
        context.commit("setProgrammes", programmes);
      });
    },
    populateProgrammes(
      context: ActionContext<ProductState, RootState>
    ): Promise<void> | void {
      if (context.getters.programmes.loading) {
        return context.dispatch("fetchProgrammes");
      }
    },
    createProgramme(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: ProgramToCreate
    ): Promise<AxiosResponse<ProgramToCreateResponse>> {
      commit("flagProgrammeUpdating");
      return API.program
        .create(payload)
        .then(async (response: AxiosResponse<ProgramToCreateResponse>) => {
          await dispatch("fetchProgrammes");
          return response;
        });
    },
    updateProgramme(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: ProgramToUpdate
    ): Promise<AxiosResponse> {
      commit("flagProgrammeUpdating");
      return API.program
        .update(payload)
        .then(async (response: AxiosResponse) => {
          await dispatch("fetchProgrammes");
          return response;
        });
    },
    deleteProgramme(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: number
    ): Promise<AxiosResponse> {
      commit("flagProgrammeUpdating");
      return API.program
        .remove(payload)
        .then(async (response: AxiosResponse) => {
          await dispatch("fetchProgrammes");
          return response;
        });
    },

    //Products
    flagProductsUpdating({ commit }: { commit: Commit }): void {
      commit("flagProductsUpdating");
    },
    flagProductDetailsUpdating({ commit }: { commit: Commit }): void {
      commit("flagProductDetailsUpdating");
    },
    async populateProductSummaries(
      context: ActionContext<ProductState, RootState>
    ): Promise<void> {
      if (context.getters.productSummaries.loading) {
        await context.dispatch("fetchProductSummaries");
      }
    },
    fetchProductSummaries(
      context: ActionContext<ProductState, RootState>
    ): Promise<void> {
      return API.product.getAll().then((products: ProductSummaryItem[]) => {
        context.commit("setProducts", products);
      });
    },
    fetchProductDetails(
      { commit }: { commit: Commit },
      productId: number
    ): Promise<void> {
      return API.product.get(productId).then((product: ProductItem) => {
        commit("setProductDetails", product);
        commit("setContentStructure", product);
      });
    },
    createProduct(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: ProductToCreate
    ): Promise<AxiosResponse<CreateProductResponse>> {
      commit("flagProductsUpdating");
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.product
        .create(payload)
        .then(async (response: AxiosResponse<CreateProductResponse>) => {
          await Promise.all([
            dispatch("fetchProductSummaries"),
            dispatch("fetchProductDetails", response.data.data.product_id)
          ]);
          return response;
        });
    },
    updateProduct(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: ProductToUpdate
    ): Promise<void> {
      commit("flagProductsUpdating");
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.product.update(payload).then(async () => {
        await Promise.all([
          dispatch("fetchProductSummaries"),
          dispatch("fetchProductDetails", payload.product_id)
        ]);
      });
    },
    deleteProduct(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      pId: number
    ): Promise<void> {
      commit("flagProductsUpdating");
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.product.remove(pId).then(async () => {
        await dispatch("fetchProductSummaries");
        commit("clearProductDetails", pId);
        commit("clearContentStructure", pId);
      });
    },
    addProductImage(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: ProductImageForStore
    ): Promise<void> {
      commit("flagProductsUpdating");
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.product.addImage(payload.formData).then(async () => {
        await Promise.all([
          dispatch("fetchProductSummaries"),
          dispatch("fetchProductDetails", payload.product_id)
        ]);
      });
    },
    updateProductFeature(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: ProductFeatureToUpdate
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.product.updateFeature(payload).then(() => {
        return dispatch("fetchProductDetails", payload.product_id);
      });
    },

    //Users
    putUser(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: UserToAddToStateForStore
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.user.addToState(payload.user).then(() => {
        return dispatch("fetchProductDetails", payload.product_id);
      });
    },
    removeUser(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: UserToRemoveFromStateForStore
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.user.removeFromState(payload.user).then(() => {
        return dispatch("fetchProductDetails", payload.product_id);
      });
    },

    //Files
    linkExistingFileToSection(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: FileToLinkToSection
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const productId = payload.productId;
      return API.file.linkToSection(payload).then(() => {
        return dispatch("fetchProductDetails", productId);
      });
    },
    linkExistingFileToProduct(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: FileToLinkToProduct
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const productId = payload.product_id;
      return API.file.linkToProduct(payload as FileToLinkToProduct).then(() => {
        return dispatch("fetchProductDetails", productId);
      });
    },
    deleteFileLinkFromSection(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: FileToUnlinkFromSection
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const productId = payload.productId;
      return API.file.unlinkFromSection(payload.linkId).then(() => {
        return dispatch("fetchProductDetails", productId);
      });
    },
    deleteFileLinkFromProduct(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: FileToUnlinkFromProduct
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const productId = payload.productId;
      return API.file.unlinkFromProduct(payload.linkId).then(() => {
        return dispatch("fetchProductDetails", productId);
      });
    },

    //Content Templates (=== Workflows === SectionTypes === Components === Asset Template)
    async createContentTemplate(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: ContentTemplateToCreate
    ): Promise<AxiosResponse<CreateContentTemplateResponse>> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const data = await API.contentTemplate.create(payload);
      const product = await API.product.get(payload.product_id);
      commit("setProductDetails", product);
      commit("setContentStructure", product);
      return data;
    },
    updateContentTemplate(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: ContentTemplateToUpdate
    ): Promise<void | AxiosResponse> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.contentTemplate.update(payload).then(() => {
        return dispatch("fetchProductDetails", payload.product_id);
      });
    },
    removeContentTemplate(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: ContentTemplateToRemove
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.contentTemplate.remove(payload.workflowId).then(() => {
        return dispatch("fetchProductDetails", payload.productId);
      });
    },
    putWorkflow(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: ContentTemplateToUpdateWorkflows
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.contentTemplate.updateWorkflow(payload).then(() => {
        return dispatch("fetchProductDetails", payload.product_id);
      });
    },

    //Content (=== ContentObject === Section)
    deleteSection(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: ContentToDelete
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const productId = payload.productId;
      return API.content.remove(payload.sectionsId).then(() => {
        return dispatch("fetchProductDetails", productId);
      });
    },
    upsertSections(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: SectionsToUpsert
    ): Promise<AxiosResponse<UpsertResponse>> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.content
        .upsert(payload.sections)
        .then((response: AxiosResponse<UpsertResponse>) => {
          return response;
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
          } else {
            throw new Error(error.message);
          }
        })
        .finally(() => {
          payload.productIds.forEach((id: number) => {
            return dispatch("fetchProductDetails", id);
          });
        });
    },
    submitMessage(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: MessageToAdd
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const productId = payload.productId;
      const apiPayload = {
        section_id: payload.sectionId,
        data: payload.message
      };
      return API.content.createMessage(apiPayload).then(() => {
        return dispatch("fetchProductDetails", productId);
      });
    },
    deleteMessage(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      params: MessageToDelete
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.content.removeMessage(params.messageId).then(() => {
        return dispatch("fetchProductDetails", params.productId);
      });
    },
    submitStateTransition(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: StateTransitionToSubmit
    ): Promise<AxiosResponse> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const productId = payload.productId;
      const apiPayload = {
        state_id: payload.stateId,
        sections_id: payload.sectionId,
        users_to_notify: payload.users_to_notify,
        status_after: payload.status ? payload.status.statusAfter : "",
        status_before: payload.status ? payload.status.statusBefore : "",
        task_name: payload.status ? payload.status.taskName : ""
      };
      return API.content
        .updateStateTransition(apiPayload)
        .then((response: AxiosResponse) => {
          return response;
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
          } else {
            throw new Error(error.message);
          }
        })
        .finally(() => {
          // to reduce the number of get product requests during bulk editing
          if (payload.shouldProductBeUpdated) {
            return dispatch("fetchProductDetails", productId);
          }
        });
    },
    updateDocument(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: DocumentToUpdateStore
    ): Promise<AxiosResponse> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.document
        .update({ section_id: payload.section_id, document: payload.document })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error) && error.response) {
            throw new Error(error.response.data.message);
          } else {
            throw new Error(error.message);
          }
        })
        .finally(() => {
          return dispatch("fetchProductDetails", payload.product_id);
        });
    },

    //Content Structures
    flagContentStructuresUpdating({ commit }: { commit: Commit }): void {
      commit("flagContentStructuresUpdating");
    },
    clearContentStructure(
      { commit }: { commit: Commit },
      productId: number
    ): void {
      commit("clearContentStructure", productId);
    },

    //Bathes
    createBatch(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: BatchToCreate
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const productId = payload.product_id;
      return API.batch.create(payload).then(() => {
        return dispatch("fetchProductDetails", productId);
      });
    },
    updateBatch(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: BatchToUpdate
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const productId = payload.productId;
      return API.batch.update(payload).then(() => {
        return dispatch("fetchProductDetails", productId);
      });
    },
    removeBatch(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: BatchToRemove
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const productId = payload.productId;
      return API.batch.remove(payload.batchId).then(() => {
        return dispatch("fetchProductDetails", productId);
      });
    },
    putSectionsToBatch(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: SectionToAddToBatch
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const productId = payload.productId;
      return API.batch.addSectionToBatch(payload).then(() => {
        return dispatch("fetchProductDetails", productId);
      });
    },
    removeSectionsFromBatch(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: SectionToRemoveFromBatch
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const productId = payload.productId;
      return API.batch.removeSectionFromBatch(payload.sections_id).then(() => {
        return dispatch("fetchProductDetails", productId);
      });
    },
    upsertDeadline(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: DeadlineToUpdate
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const productId = payload.productId;
      return API.batch.updateDeadline(payload).then(() => {
        return dispatch("fetchProductDetails", productId);
      });
    },

    //Metadata
    async updateMetadataInstance(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: InstanceToUpdateForStore
    ): Promise<AxiosResponse> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const data = await API.metadata.updateInstance(payload.metadataInstance);
      await dispatch("fetchProductDetails", payload.productId);
      return data;
    },
    async createMetadataDefinition(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: DefinitionToCreateForStore
    ): Promise<AxiosResponse<DefinitionToCreateResponse>> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const data = await API.metadata.createDefinition(payload.metadata);
      const product = await API.product.get(payload.productId);
      commit("setProductDetails", product);
      commit("setContentStructure", product);
      return data;
    },
    async updateMetadataDefinition(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: DefinitionToUpdateForStore
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      await API.metadata.updateDefinition(payload.metadata);
      await dispatch("fetchProductDetails", payload.productId);
    },
    async deleteMetadataDefinition(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: DefinitionToDeleteForStore
    ): Promise<AxiosResponse> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      const data = await API.metadata.removeDefinition(payload.id);
      await dispatch("fetchProductDetails", payload.productId);
      return data;
    },
    createMetadataAllowedValues(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: AllowedValueToCreateForStore
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.metadata.createAllowedValues(payload.values).then(() => {
        return dispatch("fetchProductDetails", payload.productId);
      });
    },
    async updateMetadataAllowedValues(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: AllowedValueToUpdateForStore
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      await API.metadata.updateAllowedValues(payload.value);
      await dispatch("fetchProductDetails", payload.productId);
    },
    async deleteMetadataAllowedValues(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: AllowedValueToDeleteForStore
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      await API.metadata.removeAllowedValues(payload.id);
      await dispatch("fetchProductDetails", payload.productId);
    },
    async createMetadataAllocatedValues(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: AllocatedValueToCreateForStore
    ): Promise<AxiosResponse> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.metadata.createAllocatedValues(payload.value).finally(() => {
        return dispatch("fetchProductDetails", payload.productId);
      });
    },
    async deleteMetadataAllocatedValues(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: AllocatedValueToDeleteForStore
    ): Promise<AxiosResponse> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      return API.metadata.removeAllocatedValues(payload.id).finally(() => {
        return dispatch("fetchProductDetails", payload.productId);
      });
    },
    importMetadataDefinitions(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      payload: DefinitionToImport
    ): Promise<void> {
      commit("flagProductDetailsUpdating");
      commit("flagContentStructuresUpdating");
      commit("flagAssetsMetadataUpdating");
      return API.metadata.importDefinitions(payload).then(async () => {
        await Promise.all([
          dispatch("fetchAssetsMetadata", payload.to_section_type_id),
          dispatch("fetchProductDetails", payload.product_id)
        ]);
      });
    },
  },
  mutations: {
    //Programmes
    flagProgrammeUpdating(state: ProductState): void {
      state.programmes.updating = true;
    },
    setProgrammes(state: ProductState, programmes: Programme[]): void {
      state.programmes.loading = false;
      state.programmes.updating = false;
      state.programmes.data = programmes;
    },

    //Products
    flagProductsUpdating(state: ProductState): void {
      state.products.updating = true;
    },
    flagProductDetailsUpdating(state: ProductState): void {
      state.productDetails.updating = true;
    },
    setProducts(state: ProductState, products: ProductSummaryItem[]): void {
      state.products.loading = false;
      state.products.updating = false;
      state.products.data = products;
    },
    setProductDetails(state: ProductState, product: ProductItem): void {
      state.productDetails.data = state.productDetails.data.filter(
        (item: ProductItem): boolean => {
          return item.id !== product.id;
        }
      );
      state.productDetails.data.push(product);
      state.productDetails.loading = false;
      state.productDetails.updating = false;
    },
    clearProductDetails(state: ProductState, productId: number): void {
      state.productDetails.data = state.productDetails.data.filter(
        (item: ProductItem) => {
          return item.id !== productId;
        }
      );
      state.productDetails.loading = false;
      state.productDetails.updating = false;
    },

    //Content Structures
    flagContentStructuresUpdating(state: ProductState): void {
      state.contentStructures.updating = true;
    },
    setContentStructure(state: ProductState, params: ProductItem): void {
      state.contentStructures.data = (
        state.contentStructures.data as GeneratedContentStructure[]
      ).filter((e: GeneratedContentStructure) => {
        return e.product_id !== params.id;
      });
      state.contentStructures.data.push(
        csHelper.generateContentStructure(params.sections, params.id)
      );
      state.contentStructures.updating = false;
      state.contentStructures.loading = false;
    },
    clearContentStructure(state: ProductState, productId: number): void {
      state.contentStructures.data = (
        state.contentStructures.data as GeneratedContentStructure[]
      ).filter((e: GeneratedContentStructure) => {
        return e.product_id !== productId;
      });
      state.contentStructures.updating = false;
      state.contentStructures.loading = false;
    }
  }
};
