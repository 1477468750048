import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3f7d1344"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGIcon = _resolveComponent("SVGIcon")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_ctx.isDocument)
      ? (_openBlock(), _createBlock(_component_SVGIcon, {
          key: 0,
          path: _ctx.mdiFileWordBox
        }, null, 8, ["path"]))
      : (_ctx.isSpreadsheet)
        ? (_openBlock(), _createBlock(_component_SVGIcon, {
            key: 1,
            path: _ctx.mdiFileExcelBox
          }, null, 8, ["path"]))
        : (_ctx.isPresentation)
          ? (_openBlock(), _createBlock(_component_SVGIcon, {
              key: 2,
              path: _ctx.mdiFilePowerpointBox
            }, null, 8, ["path"]))
          : (_ctx.isPDF)
            ? (_openBlock(), _createBlock(_component_SVGIcon, {
                key: 3,
                path: _ctx.mdiFilePdfBox
              }, null, 8, ["path"]))
            : (_ctx.isImage)
              ? (_openBlock(), _createBlock(_component_SVGIcon, {
                  key: 4,
                  path: _ctx.mdiImage
                }, null, 8, ["path"]))
              : (_ctx.isText)
                ? (_openBlock(), _createBlock(_component_SVGIcon, {
                    key: 5,
                    path: _ctx.mdiTextBox
                  }, null, 8, ["path"]))
                : (_ctx.isVideo)
                  ? (_openBlock(), _createBlock(_component_SVGIcon, {
                      key: 6,
                      path: _ctx.mdiVideoOutline
                    }, null, 8, ["path"]))
                  : (_ctx.isYouTubeVideo)
                    ? (_openBlock(), _createBlock(_component_SVGIcon, {
                        key: 7,
                        path: _ctx.mdiYoutube
                      }, null, 8, ["path"]))
                    : (_ctx.isDropbox)
                      ? (_openBlock(), _createBlock(_component_SVGIcon, {
                          key: 8,
                          path: _ctx.mdiDropbox
                        }, null, 8, ["path"]))
                      : (_openBlock(), _createBlock(_component_SVGIcon, {
                          key: 9,
                          path: _ctx.mdiPaperclip
                        }, null, 8, ["path"]))
  ]))
}