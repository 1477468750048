<template>
  <div class="programme-links padding-standard">
    <h3>Programmes</h3>
    <ul
      :class="{
        'not-available': !hasAProgrammesPermission,
        updating: programmes.updating
      }"
    >
      <li
        v-for="programme in displayProducts"
        :key="programme.id"
        class="clearfix"
      >
        <button
          @click="toggleProgramme(programme.id)"
          v-if="programme.products.length > 0"
          class="btn-3 show-programmes-button margin-r-half"
          >{{ openProgrammes.includes(programme.id) ? "-" : "+" }}</button
        >
        <span
          v-if="programme.products.length === 0"
          class="show-programmes-button margin-r-half"
        ></span>
        <router-link
          class="programme-link"
          :to="`/programme/${programme.id}/products`"
          data-testing="sidebar-product-name"
          >{{ programme.name }}</router-link
        >
        <ul
          v-if="
            programme.products.length > 0 &&
            openProgrammes.includes(programme.id)
          "
        >
          <li
            class="product-link"
            v-for="product in programme.products"
            :key="product.id"
          >
            <small>
              <router-link :to="`/product/${product.id}`">
                {{ product.name }}
              </router-link>
            </small>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { Programme } from "@/models/programme.model";
import { ProductItem } from "@/models/product.model";
import { getUnarchivedProducts } from "@/utils/filter-products-helpers";

interface Data {
  openProgrammes: number[];
}
interface DisplayProduct {
  name: string;
  id: number;
  products: ProductItem[];
}

export default defineComponent({
  name: "SidebarPrograms",
  data(): Data {
    return {
      openProgrammes: []
    };
  },
  computed: {
    ...mapGetters("organizations", ["canManageProgrammes"]),
    ...mapGetters("products", [
      "programmes",
      "productSummaries",
      "productDetails"
    ]),
    hasAProgrammesPermission(): boolean {
      return this.canManageProgrammes;
    },
    productDetailsForDisplay(): ProductItem[] {
      if (!this.productDetails.data.length) {
        return [];
      }
      return this.productDetails.data.filter(
        (product: ProductItem) => product.allocated_permissions.length > 0
      );
    },
    unarchivedProductDetailsForDisplay(): ProductItem[] {
      if (!this.productDetailsForDisplay.length) {
        return [];
      }
      return getUnarchivedProducts(
        this.productDetailsForDisplay as ProductItem[]
      );
    },
    displayProducts(): DisplayProduct[] {
      return this.programmes.data.map((programme: Programme) => {
        const products: ProductItem[] =
          this.unarchivedProductDetailsForDisplay.filter(
            (e: ProductItem): boolean =>
              e.programme_id === programme.programme_id
          );
        return {
          name: programme.name,
          id: programme.programme_id,
          products: products
        };
      });
    }
  },
  methods: {
    toggleProgramme(id: number): void {
      if (this.openProgrammes.includes(id)) {
        this.openProgrammes = this.openProgrammes.filter(
          (e: number): boolean => e !== id
        );
      } else {
        this.openProgrammes.push(id);
      }
    }
  }
});
</script>
<style lang="scss" scoped>
h3 {
  color: $blue20;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.programme-link.router-link-active,
.product-link a.router-link-active {
  color: $dark;
  font-weight: bold;
}

.programme-link {
  width: calc(100% - 2.5rem);
  display: block;
  float: left;
  font-size: 0.9rem;
  color: $blue20;
  transition: all 0.2s ease-in-out;
  text-decoration-color: transparent;
  &:not(.router-link-active) {
    &:hover,
    &:active,
    &:focus {
      color: $blue40;
      text-decoration-color: $blue40;
    }
  }
}
.product-link {
  padding-left: 1.75rem;
  font-size: 0.75rem;
  a {
    color: $blue40;
    text-decoration-color: transparent;
    transition: text-decoration-color 0.2s ease-in-out;
    &:not(.router-link-active) {
      &:hover,
      &:active,
      &:focus {
        color: $blue40;
        text-decoration-color: $blue40;
      }
    }
  }
}
.programme-links {
  border-top: 1px solid $primary-color-light;
  ul {
    clear: both;
    list-style: none;
    padding: 0;
  }

  .show-programmes-button {
    float: left;
    color: $blue20;
    width: 0.75rem;
    height: 1.25rem;
    line-height: 1rem;
    clear: both;
    transition: all 0.2s ease-in-out;
    &:hover,
    &:active,
    &:focus,
    &:hover ~ .programme-link:not(.router-link-active) {
      color: $blue40;
    }
  }
}
</style>
