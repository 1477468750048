<template>
  <div class="margin-b-standard">
    <RightSidebarTitle :title="'HTML Editor'" />

    <div v-if="isStartMessageVisible" class="start-message">
      <p
        >Click the <strong>Open editor</strong> button to start authoring
        HTML.</p
      >
    </div>

    <div class="tools-buttons">
      <!--Editor button-->
      <span
        v-if="!isContentAuthoringTier2FeatureEnabled"
        class="btn-3 disabled margin-r-standard"
      >
        <SVGIcon :path="mdiPencil" :size="16" />
        Open editor
      </span>
      <router-link
        v-else
        class="btn-3 margin-r-standard"
        :to="{
          name: 'html-editor',
          params: {
            productId: uiContentStore.sectionToDisplay.productId,
            sectionId: uiContentStore.sectionToDisplay.sectionId
          }
        }"
      >
        <SVGIcon :path="mdiPencil" :size="16" />
        Open editor
      </router-link>

      <!--Preview button-->
      <span
        v-if="!isStartMessageVisible && !isContentAuthoringTier2FeatureEnabled"
        class="btn-3 disabled margin-r-standard"
        data-testing="qti-card-btn-preview"
      >
        <SVGIcon :path="mdiPresentationPlay" :size="16" />
        Preview
      </span>
      <router-link
        v-if="!isStartMessageVisible && isContentAuthoringTier2FeatureEnabled"
        class="btn-3"
        data-testing="qti-card-btn-preview"
        :disabled="!isContentAuthoringTier2FeatureEnabled"
        :event="isContentAuthoringTier2FeatureEnabled ? 'click' : ''"
        :to="{
          name: 'html-preview',
          params: {
            productId: uiContentStore.sectionToDisplay.productId,
            sectionId: uiContentStore.sectionToDisplay.sectionId
          }
        }"
      >
        <SVGIcon :path="mdiPresentationPlay" :size="16" />
        Preview
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import { ContentStructureSection } from "@/models/content-structure.model";
import { mdiPresentationPlay, mdiPencil } from "@mdi/js";
import { mapStores } from "pinia";
import { useUIContentStore } from "@/stores/ui-content.store";

export default defineComponent({
  name: "HTMLEditor",
  data() {
    return {
      mdiPresentationPlay,
      mdiPencil
    };
  },
  props: {
    section: {
      type: Object as PropType<ContentStructureSection>,
      required: true
    },
    isContentAuthoringTier2FeatureEnabled: {
      required: true,
      type: Boolean
    }
  },
  components: {
    RightSidebarTitle
  },
  computed: {
    ...mapStores(useUIContentStore),
    isStartMessageVisible(): boolean {
      return !!this.section && !this.section.document;
    }
  }
});
</script>

<style lang="scss" scoped>
.start-message {
  p {
    padding-bottom: 0;
    font-size: 0.875rem;
  }
}
.tools-buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
  padding: 0;
}
</style>
