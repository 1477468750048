<template>
  <form @submit.prevent="attachExistingFile">
    <label class="margin-b-standard">
      Search your library of attachments
      <input
        @keyup="clearExistingFileSelection"
        id="existing-file-filter"
        v-model="existingFileFilter"
        data-testing="attach-existing-file-name"
      />
    </label>
    <ul class="available-files margin-b-standard">
      <li v-for="file in filesData" :value="file.id" :key="file.id">
        <label data-testing="existing-file-name">
          <input
            :data-testing="`existing-file-name-${file.id}`"
            type="radio"
            :value="file.id"
            v-model="existingFileId"
          />
          {{ file.name }}
        </label>
      </li>
    </ul>

    <button
      data-testing="attach-existing-file-btn"
      :disabled="!existingFileId"
      class="btn-1 full-width submit-btn"
    >
      <span v-if="uiFilesStore.shouldBeSetAsMediaContent">Set as Media Content</span>
      <span v-else>Attach content</span>
    </button>
  </form>
</template>
<script lang="ts">
import FilesMixin from "@/mixins/files";
import { mapGetters } from "vuex";
import { LibraryFile } from "@/models/files.model";
import { SectionFile } from "@/models/product-section.model";
import { defineComponent } from "vue";
import { mapStores } from "pinia";
import { useUIFilesStore } from "@/stores/ui-files.store";

interface Data {
  existingFileFilter: string;
  existingFileId: number | undefined;
}

export default defineComponent({
  name: "FileLibraryForm",
  mixins: [FilesMixin],
  data(): Data {
    return {
      existingFileFilter: "",
      existingFileId: undefined
    };
  },
  computed: {
    ...mapStores(useUIFilesStore),
    ...mapGetters("files", ["files"]),
    filesData(): LibraryFile[] {
      let files = this.files.data;
      files = files.filter((file: LibraryFile) => {
        return !this.isFileInSection(file);
      });
      files = files.filter(this.doesFileNameMatchFilter);
      return files;
    }
  },
  methods: {
    isFileInSection(file: LibraryFile): boolean {
      if (!this.uiFilesStore.sectionToAttachFile) {
        return false;
      }
      return this.uiFilesStore.sectionToAttachFile.enabled_files.some(
        (sectionFile: SectionFile): boolean => {
          return sectionFile.id === file.id;
        }
      );
    },
    doesFileNameMatchFilter(file: LibraryFile): boolean {
      return file.name
        .toLowerCase()
        .includes(this.existingFileFilter.toLowerCase());
    },
    attachFileToProduct(attachedFileId: number): void {
      if (this.uiFilesStore.productToAttachFile && this.uiFilesStore.fileAttachmentType) {
        this.linkFileToProduct({
          product_id: this.uiFilesStore.productToAttachFile.id,
          attachment_type: this.uiFilesStore.fileAttachmentType,
          file_id: attachedFileId
        });
      }
    },
    attachFileToSection(attachedFileId: number): void {
      if (this.uiFilesStore.sectionToAttachFile) {
        this.linkFileToSection({
          productId: this.uiFilesStore.sectionToAttachFile.product_id,
          section_id: this.uiFilesStore.sectionToAttachFile.id,
          file_id: attachedFileId,
          is_media_content: this.uiFilesStore.shouldBeSetAsMediaContent
        });
      }
    },
    attachExistingFile(): void {
      if (this.existingFileId) {
        if (this.uiFilesStore.sectionToAttachFile) {
          this.attachFileToSection(this.existingFileId);
        } else {
          this.attachFileToProduct(this.existingFileId);
        }
      }

      this.uiFilesStore.closeAttachFileModal();
    },
    clearExistingFileSelection(): void {
      this.existingFileId = undefined;
    },
    focusOnExistingFileFilter(): void {
      const firstInput = document.getElementById("existing-file-filter");
      if (firstInput) {
        firstInput.focus();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.focusOnExistingFileFilter();
    });
  }
});
</script>
<style lang="scss" scoped>
.available-files {
  padding-left: 0;
  max-height: 9rem; // Should be a multiple of 1.5 so that list items are not cut off half way
  overflow-y: auto;
}
</style>
