import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    "data-testing": _ctx.link.dataTesting,
    class: _normalizeClass(['header-link', { highlighted: _ctx.link.isHighlighted }]),
    to: _ctx.link.to
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.link.title), 1)
    ]),
    _: 1
  }, 8, ["data-testing", "class", "to"]))
}