<template>
  <Collapse>
    <template v-slot:title>
      <span>Path</span>
    </template>
    <template v-slot:content>
      <div class="margin-b-standard is-relative">
        <RightSidebarTitle :title="'Internal path'" />
        <label class="toggle-check">
          <input
            type="checkbox"
            aria-label="edit"
            :value="isLongPathNameEnabled"
            data-testing="toggle-edit-mode"
            class="editable"
            v-model="isLongPathNameEnabled"
          />
          <span
            data-on-text="Long path name"
            data-off-text="Short path name"
          ></span>
        </label>
        <ContentLocationPath
          :product="product"
          :path="path"
          :section-name="sectionName"
          :section-id="sectionId"
          :section-icon-id="sectionIconId"
          :section-external-id="sectionExternalId"
          :is-long-path-name-enabled="isLongPathNameEnabled"
        />
        <button class="btn-3" @click="copyToClipboard(internalPath)">
          <SVGIcon :path="mdiLinkVariant" />
          Copy
        </button>
        <button
          class="btn-3 margin-l-standard"
          v-if="isEditButtonVisible"
          @click="uiContentStore.openEditContentPathModal()"
        >
          <SVGIcon :path="mdiPencil" :size="16" />
          Edit
        </button>
      </div>
      <div>
        <RightSidebarTitle :title="'External path reference'" />
        <ContentLocationPath
          :product="product"
          :path="path"
          :section-name="sectionName"
          :section-id="sectionId"
          :section-icon-id="sectionIconId"
          :section-external-id="sectionExternalId"
          :show-as-external="true"
          :is-long-path-name-enabled="false"
        />
        <button class="btn-3" @click="copyToClipboard(externalPath)">
          <SVGIcon :path="mdiLinkVariant" :size="16" />
          Copy
        </button>
      </div>
    </template>
  </Collapse>
</template>

<script lang="ts">
import CopyToClipboardMixin from "@/mixins/copy-to-clipboard";
import ContentPathMixin from "@/mixins/content-path";
import Collapse from "@/components/right-sidebars/ui/Collapse.vue";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import ContentLocationPath from "@/components/right-sidebars/content-sidebar/content-location/ContentLocationPath.vue";
import { mdiLinkVariant, mdiPencil } from "@mdi/js";
import { defineComponent } from "vue";
import { mapStores } from "pinia";
import { useUIContentStore } from "@/stores/ui-content.store";

interface Data {
  isLongPathNameEnabled: boolean;
  mdiLinkVariant: string;
  mdiPencil: string;
}

export default defineComponent({
  name: "ContentLocation",
  mixins: [ContentPathMixin, CopyToClipboardMixin],
  props: {
    canEditContent: {
      required: true,
      type: Boolean
    }
  },
  data(): Data {
    return {
      isLongPathNameEnabled: true,
      mdiLinkVariant,
      mdiPencil
    };
  },
  components: {
    Collapse,
    RightSidebarTitle,
    ContentLocationPath
  },
  computed: {
    ...mapStores(useUIContentStore),
    isEditButtonVisible(): boolean {
      // user can move section, if product contains not only root section and current section
      // @ts-ignore
      return this.canEditContent && this.product.sections.length > 2;
    }
  }
});
</script>
<style lang="scss" scoped>
.toggle-check {
  position: absolute;
  right: 0;
  top: -0.125rem;
  z-index: 1;
  transform: scale(0.75);
}
</style>
