import {
  ContentStructureSection,
  GeneratedContentStructure
} from "@/models/content-structure.model";
import {
  BatchDeadline,
  BatchDetails,
  ContentStructureBatch
} from "@/models/content-structure-batch.model";
import { WorkflowState } from "@/models/workflow.model";
import { ProductItem } from "@/models/product.model";
import cnst from "../constants";
import { ContentTemplate } from "@/models/content-templates.model";
import { AssetLocation } from "@/models/assets.model";
import {
  ProductSection,
  SectionFile,
  StateTransition
} from "@/models/product-section.model";
const that = {
  generateContentStructure,
  findDescendentSection,
  getPathToSection,
  getAllSections,
  getWorkflowStatesForSection,
  getCurrentState,
  getMostRecentStateTransition,
  getBatchDeadlineForWorkflow,
  getSectionWithAssignedBatch,
  findEnabledFiles,
  getPathToSectionWithIds
};
function generateContentStructure(
  rawSections: ProductSection[],
  productId: number
): GeneratedContentStructure {
  const rootSection = getRootSection(rawSections);
  return {
    product_id: productId,
    rootSection: rootSection || undefined
  };
}

function getRootSection(
  sections: ProductSection[]
): ContentStructureSection | undefined {
  const root: ProductSection | undefined = sections.find(
    (e: ProductSection) => e.parent_section_id === null
  );
  return root
    ? {
        ...root,
        batch_details: undefined,
        isRoot: true,
        sections: getChildSections(sections, root.id),
        enabled_files: []
      }
    : undefined;
}

function getChildSections(
  sections: ProductSection[],
  parentId: number
): ContentStructureSection[] {
  return sections
    .filter((e: ProductSection) => e.parent_section_id === parentId)
    .map((section: ProductSection): ContentStructureSection => {
      const batchDetails = getBatchDetails(sections, section);
      return {
        ...section,
        isRoot: false,
        authored_content: JSON.parse(section.authored_content),
        batch_id: batchDetails ? batchDetails.id : null,
        batch_details: batchDetails,
        enabled_files: findEnabledFiles(section.files),
        sections: getChildSections(sections, section.id)
      };
    })
    .sort((a: ContentStructureSection, b: ContentStructureSection): number => {
      return a.rank - b.rank;
    });
}
function getBatchDetails(
  rawSections: ProductSection[],
  rawSection: ProductSection
): BatchDetails | undefined {
  if (cnst.contentTemplates.isAContentObject(rawSection.type.category)) {
    const sectionWithBatch = getSectionWithAssignedBatch(
      rawSections,
      rawSection
    );
    if (sectionWithBatch) {
      return {
        id: sectionWithBatch.batch_id,
        isInherited: sectionWithBatch.id !== rawSection.id,
        inheritedFrom: {
          name: sectionWithBatch.name,
          id: sectionWithBatch.id
        }
      };
    }
  }
  return undefined;
}
function getSectionWithAssignedBatch(
  rawSections: ProductSection[],
  rawSection: ProductSection
): ProductSection | undefined {
  if (rawSection.batch_id) {
    return rawSection;
  } else if (!rawSection.parent_section_id) {
    return undefined;
  } else {
    const parent = rawSections.find(
      (s: ProductSection) => s.id === rawSection.parent_section_id
    );
    return parent
      ? getSectionWithAssignedBatch(rawSections, parent)
      : undefined;
  }
}

function findDescendentSection(
  section: ContentStructureSection,
  sId: number
): ContentStructureSection | null {
  if (section.id == sId) {
    return section;
  } else if (section.sections) {
    let i;
    let result = null;
    for (i = 0; result == null && i < section.sections.length; i++) {
      result = findDescendentSection(section.sections[i], sId);
    }
    return result;
  }
  return null;
}
function getPathToSection(
  section: ContentStructureSection,
  sId: number
): string[] {
  const basePath = section.isRoot ? [] : [section.name]; // Root section name isn't shown
  const sectionContainingSection = getSectionContainingSection(section, sId);
  if (isDirectChildSection(section, sId)) {
    return basePath;
  } else if (sectionContainingSection) {
    return basePath.concat(getPathToSection(sectionContainingSection, sId));
  } else {
    return [];
  }
}

function isDirectChildSection(
  section: ContentStructureSection,
  sId: number
): boolean {
  return section.sections.some((e: ContentStructureSection) => {
    return e.id === sId;
  });
}
function getSectionContainingSection(
  section: ContentStructureSection,
  ceId: number
) {
  return section.sections.find((thisSection: ContentStructureSection) => {
    return isSectionContained(thisSection, ceId);
  });
}
function isSectionContained(
  section: ContentStructureSection,
  ceId: number
): boolean {
  return !!(
    isDirectChildSection(section, ceId) ||
    getSectionContainingSection(section, ceId)
  );
}
function getPathToSectionWithIds(
  section: ContentStructureSection,
  sId: number
): AssetLocation[] {
  const sectionContainingSection = getSectionContainingSection(section, sId);
  const basePath = section.isRoot
    ? []
    : [
        {
          name: section.name,
          id: section.id,
          iconId: section.type.icon_id,
          externalId: section.client_external_id
            ? section.client_external_id
            : "Not set"
        }
      ]; // Root section name isn't shown
  if (isDirectChildSection(section, sId)) {
    return basePath;
  } else if (sectionContainingSection) {
    return basePath.concat(
      getPathToSectionWithIds(sectionContainingSection, sId)
    );
  } else {
    return [];
  }
}

function getAllSections(
  structure: GeneratedContentStructure
): ContentStructureSection[] {
  if (structure.rootSection) {
    const sections: ContentStructureSection[] = getDescendentSections(
      structure.rootSection
    );
    sections.push(structure.rootSection);
    sections.map((e: ContentStructureSection) => Object.assign(e));
    return sections;
  }
  return [];
}

function getDescendentSections(
  section: ContentStructureSection
): ContentStructureSection[] {
  const descendents = section.sections.reduce(
    (acc: ContentStructureSection[], cur: ContentStructureSection) => {
      return acc.concat(getDescendentSections(cur));
    },
    []
  );
  return section.sections.concat(descendents);
}
function getWorkflowStatesForSection(
  product: ProductItem,
  workflowId: number
): WorkflowState[] {
  if (!product) {
    return [];
  }
  const currentComponent = product.component_definitions.find(
    (component: ContentTemplate): boolean => {
      return component.workflow_id === workflowId;
    }
  );
  return currentComponent ? currentComponent.workflow_states : [];
}

function getCurrentState(
  product: ProductItem,
  stateTransitions: StateTransition[],
  workflowId: number
): WorkflowState | undefined {
  const workflowStates = getWorkflowStatesForSection(product, workflowId);
  const lastTransition = getMostRecentStateTransition(stateTransitions);

  return lastTransition
    ? workflowStates.find((state: WorkflowState) => {
        return state.id === lastTransition.state_id;
      })
    : workflowStates[0];
}
function getMostRecentStateTransition(
  stateTransitions: StateTransition[]
): StateTransition {
  return stateTransitions.reduce((acc, cur) => {
    if (cur.created_on_millis === acc.created_on_millis) {
      // if two events happen in the same second, order by id number
      return cur.id > acc.id ? cur : acc;
    }
    return cur.created_on_millis > acc.created_on_millis ? cur : acc;
  }, stateTransitions[0]);
}
function getBatchDeadlineForWorkflow(
  section: ContentStructureSection,
  batches: ContentStructureBatch[],
  workflowState: WorkflowState
): BatchDeadline | undefined {
  const batch = batches.find(
    (batch: ContentStructureBatch) => batch.id === section.batch_id
  );
  if (!batch) {
    return undefined;
  }
  return batch.deadlines.find(
    (deadline: BatchDeadline) => deadline.workflow_state_id === workflowState.id
  );
}
// need to remove duplicate files_to_section records
function findEnabledFiles(files: SectionFile[]): SectionFile[] {
  return files.filter((file: SectionFile) => {
    if (!file.link_deleted_on && !file.file_deleted_on) {
      return file;
    }
  });
}
export default that;
