import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpperHeader = _resolveComponent("UpperHeader")!
  const _component_LowerHeader = _resolveComponent("LowerHeader")!

  return (_openBlock(), _createElementBlock("header", {
    id: "main-header",
    class: _normalizeClass({ 'is-sidebar-open': _ctx.uiGeneralStore.isSidebarOpen })
  }, [
    _createVNode(_component_UpperHeader),
    (_ctx.productId)
      ? (_openBlock(), _createBlock(_component_LowerHeader, {
          key: 0,
          links: _ctx.productLinks
        }, null, 8, ["links"]))
      : _createCommentVNode("", true),
    (_ctx.isContentPage)
      ? (_openBlock(), _createBlock(_component_LowerHeader, {
          key: 1,
          links: _ctx.contentLinks
        }, null, 8, ["links"]))
      : _createCommentVNode("", true),
    (_ctx.isProductPermissionsPage)
      ? (_openBlock(), _createBlock(_component_LowerHeader, {
          key: 2,
          links: _ctx.productPermissionsLinks
        }, null, 8, ["links"]))
      : _createCommentVNode("", true)
  ], 2))
}