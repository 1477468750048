<template>
  <div class="sidebar-title-wrapper" data-testing="sidebar-wrapper">
    <h3 class="sidebar-title">{{ title }}</h3>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    title: {
      required: true,
      type: String
    }
  },
  name: "RightSidebarTitle"
});
</script>
<style lang="scss" scoped>
.sidebar-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.sidebar-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  padding-bottom: 0;
}
</style>
