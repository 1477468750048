<template>
  <div class="path">
    <span :title="internalPath">
      <router-link
        v-if="isClickable"
        :to="`/product/${product.id}`"
        class="btn-unstyled"
      >
        {{ product.name }}
      </router-link>
      <span v-else>{{ product.name }}</span>
      &nbsp;/
      <span v-if="path.length && isClickable">
        <button
          v-for="item in path"
          :key="item.id"
          class="btn-unstyled"
          @click="
            uiContentStore.openContentSidebar({
              sectionId: item.id,
              productId: product.id,
              workflowStateId: 0,
              hasCloseButton: true
            })
          "
        >
          <SectionIcon
            class="mr-1"
            :icon-id="item.iconId"
            :size="16"
          ></SectionIcon>
          <span v-if="showAsExternal" class="path-btn-text">{{
            item.externalId
          }}</span>
          <span v-else>
            <span v-if="isLongPathNameEnabled" class="path-btn-text">{{
              item.name
            }}</span
            ><span v-else class="path-btn-text"
              >{{ productShortName }}-{{ item.id }}</span
            > </span
          >&nbsp;/&nbsp;
        </button>
      </span>
      <span v-if="path.length && !isClickable">
        <span v-for="item in path" :key="item.id">
          <SectionIcon
            class="mr-1"
            :icon-id="item.iconId"
            :size="16"
          ></SectionIcon>
          <span v-if="showAsExternal" class="path-btn-text">{{
            item.externalId
          }}</span>
          <span v-else>
            <span v-if="isLongPathNameEnabled" class="path-btn-text">{{
              item.name
            }}</span
            ><span v-else class="path-btn-text"
              >{{ productShortName }}-{{ item.id }}</span
            > </span
          >&nbsp;/&nbsp;
        </span>
      </span>
      <span class="current-section">
        <SectionIcon class="mr-1" :icon-id="sectionIconId" :size="16" />
        <span v-if="showAsExternal">
          <span>{{ sectionExternalId }}</span>
        </span>
        <span v-else>
          <span v-if="isLongPathNameEnabled">{{ sectionName.trim() }}</span>
          <span v-else>{{ productShortName }}-{{ sectionId }}</span>
        </span>
      </span>
    </span>
  </div>
</template>
<script lang="ts">
import ContentPathMixin from "@/mixins/content-path";
import { defineComponent } from "vue";
import { mapStores } from "pinia";
import { useUIContentStore } from "@/stores/ui-content.store";

export default defineComponent({
  name: "ContentLocationPath",
  mixins: [ContentPathMixin],
  props: {
    showAsExternal: {
      default: false,
      type: Boolean,
      required: false
    },
    isClickable: {
      default: true,
      type: Boolean,
      required: false
    },
    isLongPathNameEnabled: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    ...mapStores(useUIContentStore),
  }
});
</script>
<style scoped lang="scss">
.path {
  vertical-align: middle;
  font-size: 1rem;
  button {
    white-space: nowrap;
    &:hover,
    &:active {
      .path-btn-text {
        text-decoration: underline;
      }
    }
  }
  .current-section {
    white-space: nowrap;
  }
}
</style>
