import DAO from "@/api/DAO";
import { DOCUMENT_URL } from "@/api/URL-constants";
import { DocumentToUpdate } from "@/models/api/document.model";
import { AxiosResponse } from "axios";

const update = (payload: DocumentToUpdate): Promise<AxiosResponse> => {
  return DAO().put(DOCUMENT_URL, payload);
};

export { update };
