<template>
  <h3
    data-testing="edit-section-name"
    v-editable="canEditContent"
    @edited="saveContentName($event)"
    v-text="contentName"
  ></h3>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import cnst from "@/utils/constants";

export default defineComponent({
  name: "ContentName",
  props: {
    contentName: {
      required: true,
      type: String
    },
    sectionId: {
      required: true,
      type: Number
    },
    productId: {
      required: true,
      type: Number
    },
    canEditContent: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    ...mapActions("products", ["upsertSections"]),
    saveContentName(event: CustomEvent<{ textContent: string }>): void {
      let sName = event.detail.textContent.trim();
      if (sName !== "" && sName !== this.contentName) {
        this.upsertSections({
          productIds: [this.productId],
          sections: [
            {
              id: this.sectionId,
              name: sName
            }
          ]
        }).catch(error => {
          this.$buefy.toast.open({
            message: `${cnst.section.nameNodeUpdated}: ${error.message}`,
            type: "is-danger",
            duration: 3000
          });
        });
      }
    }
  }
});
</script>
