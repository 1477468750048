import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-18df8dfd"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "sidebar-title-wrapper",
  "data-testing": "sidebar-wrapper"
}
const _hoisted_2 = { class: "sidebar-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}