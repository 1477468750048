/**
 * Generates a query for the report table from a set of filters.
 * @param filters An array of filters
 * @param filters.field
 * @param filters.value
 * @param filters.comparison
 * @returns The URL query matching the filters
 */
import {
  ReportTableFilter,
  ReportTableComparison
} from "@/models/report-table.model";

export function generateFilterQuery(filters: ReportTableFilter[]): string {
  return filters.reduce((acc, cur, index) => {
    const separator = index === 0 ? "" : "__";
    return acc + separator + generateSingleFilterQuery(cur);
  }, "");
}

function generateSingleFilterQuery(filter: ReportTableFilter): string {
  return `${filter.field}--${filter.value}--${filter.comparison}`;
}

export function generateMultipleFilter(
  field: string,
  values: string[],
  comparison: ReportTableComparison
): ReportTableFilter[] {
  const filters: ReportTableFilter[] = [];
  values.forEach(item => {
    filters.push({
      field,
      value: item,
      comparison
    });
  });
  return filters;
}
