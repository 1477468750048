import ls from "@/services/local-storage";
import cnst from "@/utils/constants";
import API from "@/api/index";
import { AxiosResponse } from "axios";
import { LoginResponse } from "@/models/api/login.model";
import { UserPermission } from "@/models/user.model";
import {
  GetAllResponse,
  GetAllResponseCurrentUser,
  GetAllResponseUser
} from "@/models/api/users.model";
import { ActionContext, Commit } from "vuex";
import { CurrentUser, UserState } from "@/models/store/user-state.model";
import { RootState } from "@/models/store/root-state.model";

export default {
  namespaced: true,
  state: {
    currentUser: {
      id: null,
      permissions: [],
      loading: true,
      isLoggedIn: ls.isTokenSet(),
      displayName: "",
      updating: false
    },
    type: null
  } as UserState,
  getters: {
    currentUser(state: UserState): CurrentUser {
      return state.currentUser;
    },
    canManageUsers(state: UserState): boolean {
      return state.currentUser.permissions.some((e: UserPermission) => {
        return e.id === cnst.orgPermissions.manageUsers;
      });
    },
    canManageProducts(state: UserState): boolean {
      return state.currentUser.permissions.some((e: UserPermission) => {
        return e.id === cnst.orgPermissions.manageProducts;
      });
    },
    canManageWorkflows(state: UserState): boolean {
      return state.currentUser.permissions.some((e: UserPermission) => {
        return e.id === cnst.orgPermissions.manageWorkflows;
      });
    },
    isLoggedIn(state: UserState): boolean {
      return state.currentUser.isLoggedIn;
    }
  },
  actions: {
    populateCurrentUser(
      context: ActionContext<UserState, RootState>
    ): Promise<any> {
      if (
        context.getters.currentUser.loading ||
        context.getters.currentUser.updating
      ) {
        return context.dispatch("fetchCurrentUser");
      }

      return Promise.resolve({});
    },
    fetchCurrentUser({ commit }: { commit: Commit }): Promise<void> {
      return API.user
        .getAll()
        .then((response: AxiosResponse<GetAllResponse>) => {
          commit("setCurrentUser", response.data.data);
        });
    },
    login(
      { commit }: { commit: Commit },
      { username, password }: { username: string; password: string }
    ): Promise<AxiosResponse<LoginResponse> | undefined> {
      return API.auth
        .validateLogin(username, password)
        .then((response: AxiosResponse<LoginResponse>) => {
          if (response.status === 201) {
            commit("setLoggedIn");
            return response;
          }
        });
    },
    authorizeByToken({ commit }: { commit: Commit }, token: string): boolean {
      ls.setToken(token);
      commit("setLoggedIn");
      return true;
    },
    clearLoggedIn({ commit }: { commit: Commit }): void {
      commit("clearLoggedIn");
    },
    flagCurrentUserUpdating({ commit }: { commit: Commit }): void {
      commit("flagCurrentUserUpdating");
    }
  },
  mutations: {
    setCurrentUser(state: UserState, data: GetAllResponseCurrentUser): void {
      const currentUser = data.users.find((e: GetAllResponseUser) => {
        return e.id === data.id;
      });
      if (currentUser) {
        state.currentUser.id = data.id;
        state.currentUser.permissions = data.permissions;
        state.currentUser.loading = false;
        state.currentUser.displayName =
          currentUser.firstname + " " + currentUser.lastname;
        state.type = currentUser.type;
      }
    },
    setLoggedIn(state: UserState): void {
      state.currentUser.isLoggedIn = true;
    },
    clearLoggedIn(state: UserState): void {
      state.currentUser.isLoggedIn = false;
    },
    flagCurrentUserUpdating(state: UserState): void {
      state.currentUser.updating = true;
    }
  }
};
