import {
  ProductItem,
  ProductPermission,
  ProductSummaryItem
} from "@/models/product.model";
import { UserPermission } from "@/models/user.model";
import constants from "@/utils/constants";

export const checkOrgProductPermissions = (
  currentUserPermissions: UserPermission[]
): boolean => {
  if (currentUserPermissions.length > 0) {
    const orgPermissions = currentUserPermissions.filter(
      (permission: ProductPermission) => {
        return (
          permission.id === constants.orgPermissions.manageWorkflows ||
          permission.id === constants.orgPermissions.manageProducts
        );
      }
    );
    return orgPermissions.length > 0;
  }
  return false;
};

export const getAllowedProducts = (
  productSummaries: ProductSummaryItem[],
  currentUserPermissions: UserPermission[]
): ProductSummaryItem[] => {
  return checkOrgProductPermissions(currentUserPermissions)
    ? productSummaries
    : productSummaries.filter((product: ProductSummaryItem) => {
        return (
          product.allocated_permissions.length > 0 || product.contains_tasks
        );
      });
};

export const getUnarchivedProducts = <
  T extends ProductItem | ProductSummaryItem
>(
  productArr: Array<T>
): Array<T> => {
  return productArr.filter((product: T) => {
    return product.status !== "Archive";
  });
};

export const sortProductsById = <T extends ProductItem | ProductSummaryItem>(
  productArr: Array<T>
): Array<T> => {
  return productArr.sort((a: T, b: T) => {
    return b.id - a.id;
  });
};

export const getProductsForDisplay = (
  productsArr: ProductSummaryItem[],
  currentUserPermissions: UserPermission[]
): ProductSummaryItem[] => {
  const products = getAllowedProducts(productsArr, currentUserPermissions);
  const unarchivedProducts = getUnarchivedProducts(products);
  return sortProductsById(unarchivedProducts);
};
