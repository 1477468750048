<template>
  <div class="upper-header padding-h-standard">
    <h1 data-testing="page-header">{{ uiGeneralStore.pageHeading }}</h1>
    <b-dropdown
      class="is-block"
      aria-role="menu"
      position="is-bottom-left"
      hoverable
      :mobile-modal="false"
      data-testing="account-dropdown"
    >
      <template v-slot:trigger>
        <button class="btn-3 right-icon">
          <SVGIcon :path="mdiAccount" :size="36" />
        </button>
      </template>

      <b-dropdown-item aria-role="menu-item" :focusable="false" :custom="true">
        <SVGIcon :path="mdiAccount" class="margin-r-half" />
        {{ currentUser.displayName }}
      </b-dropdown-item>
      <b-dropdown-item aria-role="menu-item" :focusable="false" :custom="true">
        <button data-testing="logout-button" class="btn-3" @click="logout()">
          <SVGIcon :path="mdiLogout" class="margin-r-half" />
          Log out
        </button>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ls from "@/services/local-storage";
import { mapGetters, mapActions } from "vuex";
import { mdiLogout, mdiAccount } from "@mdi/js";
import { mapStores } from "pinia";
import { useUIGeneralStore } from "@/stores/ui-general.store";

export default defineComponent({
  name: "UpperHeader",
  data() {
    return {
      mdiLogout,
      mdiAccount
    };
  },
  computed: {
    ...mapStores(useUIGeneralStore),
    ...mapGetters("currentUser", ["currentUser"])
  },
  methods: {
    ...mapActions("currentUser", ["clearLoggedIn"]),
    logout(): void {
      ls.clearToken();
      this.clearLoggedIn();
      window.location.reload();
    }
  }
});
</script>
<style lang="scss" scoped>
.upper-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  &:only-child {
    box-shadow: 0 0.25rem 0.25rem -0.125rem $grey20;
  }
  .hamburger {
    float: left;
    width: 1.5rem;
    position: relative;
    top: 1.6rem;
    &:hover:not(:active),
    &:focus:not(:active) {
      rect {
        fill: $dark;
      }
    }
  }
}
</style>
