import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7bcf00a3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lower-header padding-h-standard" }
const _hoisted_2 = {
  key: 0,
  class: "lower-header-link-disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderLink = _resolveComponent("HeaderLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link) => {
      return (_openBlock(), _createElementBlock("span", {
        key: link.title
      }, [
        (!link.isEnabled)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(link.title), 1))
          : (_openBlock(), _createBlock(_component_HeaderLink, {
              key: 1,
              link: link
            }, null, 8, ["link"]))
      ]))
    }), 128))
  ]))
}