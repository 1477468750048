import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f5aeb564"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "margin-b-standard" }
const _hoisted_2 = { class: "available-files margin-b-standard" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { "data-testing": "existing-file-name" }
const _hoisted_5 = ["data-testing", "value"]
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.attachExistingFile && _ctx.attachExistingFile(...args)), ["prevent"]))
  }, [
    _createElementVNode("label", _hoisted_1, [
      _createTextVNode(" Search your library of attachments "),
      _withDirectives(_createElementVNode("input", {
        onKeyup: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clearExistingFileSelection && _ctx.clearExistingFileSelection(...args))),
        id: "existing-file-filter",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.existingFileFilter) = $event)),
        "data-testing": "attach-existing-file-name"
      }, null, 544), [
        [_vModelText, _ctx.existingFileFilter]
      ])
    ]),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filesData, (file) => {
        return (_openBlock(), _createElementBlock("li", {
          value: file.id,
          key: file.id
        }, [
          _createElementVNode("label", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              "data-testing": `existing-file-name-${file.id}`,
              type: "radio",
              value: file.id,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.existingFileId) = $event))
            }, null, 8, _hoisted_5), [
              [_vModelRadio, _ctx.existingFileId]
            ]),
            _createTextVNode(" " + _toDisplayString(file.name), 1)
          ])
        ], 8, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("button", {
      "data-testing": "attach-existing-file-btn",
      disabled: !_ctx.existingFileId,
      class: "btn-1 full-width submit-btn"
    }, [
      (_ctx.uiFilesStore.shouldBeSetAsMediaContent)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Set as Media Content"))
        : (_openBlock(), _createElementBlock("span", _hoisted_8, "Attach content"))
    ], 8, _hoisted_6)
  ], 32))
}