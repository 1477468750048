import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-38094447"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-testing"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WorkflowIconItem = _resolveComponent("WorkflowIconItem")!

  return (_ctx.stateCount && _ctx.stateCount > 1 && _ctx.stateCount < 9)
    ? (_openBlock(), _createElementBlock("ul", {
        key: 0,
        class: "workflow-icons",
        "data-testing": 'state-' + _ctx.state.name.toLowerCase().replace(/ /g, '-')
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stateCount, (item) => {
          return (_openBlock(), _createBlock(_component_WorkflowIconItem, {
            key: item,
            "state-rank": _ctx.state.rank,
            "current-index": item,
            "is-final-step": _ctx.state.isFinalState
          }, null, 8, ["state-rank", "current-index", "is-final-step"]))
        }), 128))
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}