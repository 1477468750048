<template>
  <RightSidebar
    @closeSidebar="uiFilesStore.closeFileSidebar"
    :is-sidebar-open="!!displayFile"
    :data-testing="'display-file-sidebar'"
    :close-btn-data-testing="'close-file-sb'"
    :is-close-btn-visible="true"
    :is-updating="false"
    :css-classes="['display-file-sidebar']"
  >
    <template v-slot:title>
      <h3
        data-testing="edit-file-name"
        v-editable="uiGeneralStore.isEditModeEnabled"
        @edited="updateName"
        v-text="displayFile.name"
      ></h3>
    </template>
    <template v-slot:body>
      <b-dropdown
        class="is-block sidebar-dropdown-menu"
        aria-role="menu"
        position="is-bottom-left"
        hoverable
        :mobile-modal="false"
        v-if="uiGeneralStore.isEditModeEnabled && canRemoveFile"
      >
        <template #trigger>
          <button
            class="btn-3 right-icon dropdown-btn"
            data-testing="file-menu"
          >
            <SVGIcon :path="mdiDotsHorizontal" :size="24" />
          </button>
        </template>
        <span>
          <b-dropdown-item
            aria-role="menu-item"
            :focusable="false"
            :custom="true"
            ><button
              class="btn-unstyled btn-3 full-width"
              type="button"
              data-testing="delete-file-btn"
              @click="confirmRemoval"
            >
              <SVGIcon :path="mdiDelete" />
              Delete Attachment
            </button>
          </b-dropdown-item>
        </span>
      </b-dropdown>

      <div
        class="margin-b-half"
        data-testing="file-path-sb"
        v-if="displayFile.is_external"
      >
        <RightSidebarTitle :title="fileLocationTitle + ':'" />
        <a
          v-if="!fileLocationIsLocal"
          class="location-link"
          data-testing="location-link"
          :href="displayFile.location"
          target="_blank"
        >
          {{ displayFile.location }}
        </a>
        <a
          v-if="fileLocationIsLocal"
          title="Copy path"
          @click="copyToClipboard(displayFile.location)"
        >
          <SVGIcon :path="mdiContentCopy" />
          {{ displayFile.location }}
        </a>
      </div>
      <div class="margin-b-half" data-testing="files-attached-sb">
        <RightSidebarTitle :title="'Attached to:'" />
        <ul class="pl-0">
          <li v-if="attachments.length === 0"> Not attached to any content </li>
          <li
            v-for="attachment in attachments"
            :key="attachment.index"
            data-testing="attached-to"
          >
            {{ attachment.name }}
          </li>
        </ul>
      </div>
      <div class="margin-b-half" v-if="!displayFile.is_external">
        <RightSidebarTitle :title="'Versions:'" />
        <ul class="versions margin-b-half" data-testing="versions-container">
          <li
            class="version"
            data-testing="version"
            v-for="version in versions"
            :key="version.id"
          >
            <small class="date" data-testing="blackout"
              >{{ version.displayDateTime }}
            </small>
            <small v-if="version.userDisplayName">
              {{ version.userDisplayName }}:
            </small>
            <a :href="version.location" target="_blank">view</a>
          </li>
        </ul>
      </div>
      <div class="margin-b-half" v-if="!displayFile.is_external">
        <RightSidebarTitle :title="'Upload a new Version:'" />
        <form @submit.prevent="uploadNewVersion">
          <input
            class="margin-b-quarter"
            type="file"
            ref="new-version"
            @change="prepareNewVersion"
          />
          <button
            class="btn-1 full-width"
            ref="upload-new-version"
            v-if="newVersion"
            @click="uploadNewVersion"
          >
            Submit
          </button>
        </form>
      </div>
    </template>
  </RightSidebar>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import FilesMixin from "@/mixins/files";
import CopyToClipboardMixin from "@/mixins/copy-to-clipboard";
import { isAValidPathToTheLocalFile } from "@/utils/file-helpers";
import RightSidebar from "@/components/right-sidebars/ui/RightSidebar.vue";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import {
  LibraryFile,
  LibraryFileSection,
  LibraryFileVersion,
  LibraryFileProduct
} from "@/models/files.model";
import { OrganizationUser } from "@/models/user.model";
import { ProductItem, ProductPermission } from "@/models/product.model";
import cnst from "@/utils/constants";
import { defineComponent } from "vue";
import { formatFine } from "@/utils/formatTimestamp";
import { mdiContentCopy, mdiDelete, mdiDotsHorizontal } from "@mdi/js";
import { mapStores } from "pinia";
import { useUIGeneralStore } from "@/stores/ui-general.store";
import { useUIFilesStore } from "@/stores/ui-files.store";

interface Data {
  newVersion: boolean;
  fileToUpload: any;
  mdiContentCopy: string;
  mdiDelete: string;
  mdiDotsHorizontal: string;
}
interface Version {
  displayDateTime: string;
  userDisplayName: string;
  location: string;
}
interface Attachment {
  name: string;
  index: number;
}

export default defineComponent({
  name: "FileSB",
  mixins: [FilesMixin, CopyToClipboardMixin],
  data(): Data {
    return {
      newVersion: false,
      fileToUpload: undefined,
      mdiContentCopy,
      mdiDelete,
      mdiDotsHorizontal
    };
  },
  components: {
    RightSidebar,
    RightSidebarTitle
  },
  computed: {
    ...mapStores(useUIGeneralStore, useUIFilesStore, useUIFilesStore),
    ...mapGetters("organizations", ["users"]),
    ...mapGetters("files", ["files"]),
    ...mapGetters("products", ["productDetails"]),
    displayFile(): LibraryFile | undefined {
      return this.files.data.find((e: LibraryFile) => {
        return e.id === this.uiFilesStore.fileForDisplay;
      });
    },
    fileLocationTitle(): string {
      return this.fileLocationIsLocal ? "Path" : "Location";
    },
    fileLocationIsLocal(): boolean {
      return this.displayFile
        ? isAValidPathToTheLocalFile(this.displayFile.location)
        : false;
    },
    section(): LibraryFileSection | undefined {
      if (!this.displayFile) {
        return undefined;
      }
      return this.displayFile.sections.find((e: LibraryFileSection) => {
        return e.file_id === this.uiFilesStore.fileForDisplay;
      });
    },
    productIds(): number[] {
      let ids: Set<number> = new Set([]);
      if (this.displayFile) {
        const products = this.displayFile.products.map(
          (product: LibraryFileProduct) => {
            return product.product_id;
          }
        );
        const productsFromSections = this.displayFile.sections.map(
          (section: LibraryFileSection) => {
            return section.product_id;
          }
        );
        ids = new Set([...products, ...productsFromSections]);
      }
      return Array.from(ids);
    },
    canRemoveFile(): boolean {
      if (this.productDetails) {
        const products = this.productDetails.data.filter(
          (product: ProductItem) => {
            return this.productIds.some((prodId: number) => {
              return prodId === product.id;
            });
          }
        );
        return products.every((product: ProductItem) => {
          return product.allocated_permissions.some(
            (perm: ProductPermission) => {
              return perm.id === cnst.productPermissions.editContentStructure;
            }
          );
        });
      }
      return false;
    },
    attachments(): Attachment[] {
      if (!this.displayFile) {
        return [];
      }
      const sections = this.displayFile.sections
        .map((section: LibraryFileSection) => {
          const productName = this.getProductName(section.product_id);
          return {
            name: productName ? `${productName}/${section.name}` : "",
            index: 0
          };
        })
        .filter((section: { name: string }) => {
          return section.name !== "";
        });
      const products = this.displayFile.products
        .map((product: LibraryFileProduct) => {
          return {
            name: this.getProductName(product.product_id) || "",
            index: 0
          };
        })
        .filter((product: { name: string }) => {
          return product.name !== "";
        });
      const attachments: Attachment[] = sections.concat(products);
      attachments.forEach((attachment: Attachment, index: number) => {
        attachment.index = index;
      });
      return attachments;
    },
    versions(): Version[] {
      if (!this.displayFile) {
        return [];
      }
      const file = this.displayFile;
      const versionsWithFileUpdate: LibraryFileVersion[] = file.versions.filter(
        (v: LibraryFileVersion, i: number, a: LibraryFileVersion[]) => {
          if (i === 0) {
            return true;
          }
          return a[i].location !== a[i - 1].location;
        }
      );

      return versionsWithFileUpdate.map((version: LibraryFileVersion) => {
        return {
          displayDateTime: formatFine(version.created_on),
          userDisplayName: this.getUserDisplayName(version.created_by),
          location: version.location
        };
      });
    }
  },
  methods: {
    updateName(event: CustomEvent<{ textContent: string }>): void {
      if (
        this.displayFile &&
        this.displayFile.name !== event.detail.textContent
      ) {
        this.renameAttachment(
          {
            id: this.displayFile.id,
            name: event.detail.textContent
          },
          this.displayFile
        );
      }
    },
    getUserDisplayName(userId: number): string {
      const user = this.users.data.find(
        (e: OrganizationUser): boolean => e.id == userId
      );
      return user ? user.displayName : "";
    },
    prepareNewVersion(): void {
      this.newVersion = true;
      const fileInput = this.$refs["new-version"] as HTMLInputElement;
      if (fileInput.files) {
        this.fileToUpload = fileInput.files[0];
        setTimeout(() => {
          const btn = this.$refs["upload-new-version"] as HTMLButtonElement;
          if (btn) {
            btn.focus();
          }
        }, 100);
      }
    },
    uploadNewVersion(): void {
      this.newVersion = false;
      if (this.displayFile && !this.displayFile.is_external) {
        const formData = new FormData();
        formData.append("file", this.fileToUpload);
        formData.append("id", `${this.uiFilesStore.fileForDisplay}`);
        if (this.section) {
          this.uploadFileVersion({
            formData: formData,
            productId: this.section.product_id
          });
        } else {
          this.uploadFileVersion({
            formData: formData
          });
        }
        (this.$refs["new-version"] as HTMLInputElement).value = "";
      } else {
        console.log("update path for external file");

        //        this.upsertFile({
        //          id: this.uiFilesStore.fileForDisplay,
        //          location: newFile location,
        //          productId: this.section.product_id
        //        })
      }
    },
    confirmRemoval(): void {
      this.$buefy.dialog.confirm({
        message: "Are you sure you want to continue?",
        cancelText: "No",
        confirmText: "Yes",
        onConfirm: () => {
          if (this.displayFile) {
            this.removeFile(this.displayFile).then(() => {
              this.uiFilesStore.closeFileSidebar();
            });
          }
        }
      });
    },
    getProductName(productId: number): string {
      const product = this.productDetails.data.find((e: ProductItem) => {
        return e.id === productId;
      });
      return product ? product.name : "";
    }
  },
  watch: {
    $route(to, from) {
      if (to.name === "files") {
        // The sidebar should remain open during a transition to the 'Files' page.
        // This allows for the 'more file details' feature.
        return;
      }
      if (to.path !== from.path) {
        this.uiFilesStore.closeFileSidebar();
      }
    }
  }
});
</script>

<style lang="scss" scoped>
span.editable {
  height: 23px;
  width: 100%;
  line-height: 23px;
  display: inline-block;
}
select.editable {
  padding-top: 0;
  padding-bottom: 0;
}
input.editable {
  padding-top: 0;
  padding-bottom: 0;
}
.versions {
  padding-left: 0;
}
.location-link {
  overflow-wrap: break-word;
}
</style>
