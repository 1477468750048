import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useUIContentStore } from "@/stores/ui-content.store";
import { useUIProgrammesStore } from "@/stores/ui-programmes.store";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useUIUsersStore } from "@/stores/ui-users.store";
import { useUICurriculumsStore } from "@/stores/ui-curriculums.store";
import { useUIContentTemplatesStore } from "@/stores/ui-content-template.store";
import { useUIAssetsStore } from "@/stores/ui-assets.store";

export const useUIGeneralStore = defineStore("uiGeneral", () => {
  const pageHeading = ref<string>("Thoth");
  const setPageHeading = (newHeading: string) => {
    pageHeading.value = newHeading || "Thoth";
  };

  const isEditModeEnabled = ref<boolean>(false);
  const toggleEditModeEnabled = () => {
    isEditModeEnabled.value = !isEditModeEnabled.value;
  };

  const isSidebarOpen = ref<boolean>(false);
  const openSidebar = () => {
    isSidebarOpen.value = true;
  };
  const closeSidebar = () => {
    isSidebarOpen.value = false;
  };
  const toggleSidebarWidth = () => {
    isSidebarOpen.value = !isSidebarOpen.value;
  };

  const uiContentStore = useUIContentStore();
  const uiProgrammesStore = useUIProgrammesStore();
  const uiFilesStore = useUIFilesStore();
  const uiUsersStore = useUIUsersStore();
  const uiCurriculumsStore = useUICurriculumsStore();
  const uiContentTemplatesStore = useUIContentTemplatesStore();
  const uiAssetsStore = useUIAssetsStore();
  const isRightSidebarOpen = computed<boolean>(() => {
    return (
      !!uiContentStore.sectionToDisplay ||
      !!uiContentStore.newSectionDetails ||
      !!uiProgrammesStore.programmeForDisplay ||
      !!uiFilesStore.fileForDisplay ||
      !!uiUsersStore.userForDisplay ||
      !!uiCurriculumsStore.curriculumForDisplay ||
      !!uiContentTemplatesStore.contentTemplateForDisplay ||
      uiAssetsStore.isBulkSidebarVisible ||
      uiAssetsStore.isMetadataManagerSidebarVisible
    );
  });
  const closeAllSidebarsExceptFiles = () => {
    uiContentStore.sectionToDisplay = null;
    uiContentStore.newSectionDetails = null;
    uiProgrammesStore.programmeForDisplay = null;
    uiUsersStore.userForDisplay = null;
    uiCurriculumsStore.curriculumForDisplay = null;
    uiContentTemplatesStore.contentTemplateForDisplay = null;
    uiAssetsStore.isBulkSidebarVisible = false;
    uiAssetsStore.isMetadataManagerSidebarVisible = false;
  };
  const closeAllSidebars = () => {
    closeAllSidebarsExceptFiles();
    uiFilesStore.fileForDisplay = null;
  };

  return {
    pageHeading,
    setPageHeading,
    isEditModeEnabled,
    toggleEditModeEnabled,
    isSidebarOpen,
    openSidebar,
    closeSidebar,
    toggleSidebarWidth,
    isRightSidebarOpen,
    closeAllSidebarsExceptFiles,
    closeAllSidebars
  };
});
