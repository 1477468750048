<template>
  <div>
    <RightSidebarTitle :title="'Attachments'" v-if="isAuthoringTool">
      <template v-slot>
        <Hint :type="'info'" :position="'bottom'">
          <p>Any files/links required for authoring this content</p>
        </Hint>
        <button
          class="btn-unstyled ml-auto"
          type="button"
          @click="openFileModal"
          title="Add an attachment"
          data-testing="sb-add-attachment-btn"
        >
          <SVGIcon :path="mdiPlus" />
        </button>
      </template>
    </RightSidebarTitle>
    <FilesList
      v-if="filesToDisplay.length > 0"
      :filesToDisplay="filesToDisplay"
      :section="section"
      @previewAttachment="previewAttachment"
    />
    <div class="has-text-right">
      <button class="btn-3" v-if="enableShowMoreBtn" @click="toggleFilesList()">
        <span v-if="showFullList">
          <SVGIcon :path="mdiChevronUp" />
          Show fewer
        </span>
        <span v-else>
          <SVGIcon :path="mdiChevronDown" />
          Show more
        </span>
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import Hint from "@/components/Hint.vue";
import FilesList from "@/components/right-sidebars/content-sidebar/authoring-tool/FilesList.vue";
import { ContentStructureSection } from "@/models/content-structure.model";
import { SectionFile } from "@/models/product-section.model";
import { mdiChevronDown, mdiChevronUp, mdiPlus } from "@mdi/js";
import { mapStores } from "pinia";
import { useUIFilesStore } from "@/stores/ui-files.store";

const FILE_DISPLAY_LIMIT = 5;
interface Data {
  showFullList: boolean;
  mdiChevronDown: string;
  mdiChevronUp: string;
  mdiPlus: string;
}

export default defineComponent({
  name: "Attachments",
  data: (): Data => {
    return {
      showFullList: false,
      mdiChevronDown,
      mdiPlus,
      mdiChevronUp
    };
  },
  props: {
    section: {
      type: Object as PropType<ContentStructureSection>,
      required: true
    }
  },
  components: {
    RightSidebarTitle,
    Hint,
    FilesList
  },
  computed: {
    ...mapStores(useUIFilesStore),
    filesToDisplay(): SectionFile[] {
      let files = [...this.section.enabled_files];
      if (files.length > FILE_DISPLAY_LIMIT && !this.showFullList) {
        return files.slice(0, FILE_DISPLAY_LIMIT);
      }
      return files;
    },
    isAuthoringTool(): boolean {
      return this.section.type.tool_id > 0;
    },
    enableShowMoreBtn(): boolean {
      return this.section.enabled_files.length > FILE_DISPLAY_LIMIT;
    }
  },
  methods: {
    toggleFilesList(): void {
      this.showFullList = !this.showFullList;
    },
    openFileModal(): void {
      this.uiFilesStore.openAttachFileModal({ section: this.section });
    },
    previewAttachment(fileLocation: string): void {
      this.$emit("previewAttachment", fileLocation);
    }
  }
});
</script>
<style scoped lang="scss"></style>
