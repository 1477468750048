import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGIcon = _resolveComponent("SVGIcon")!

  return (_openBlock(), _createBlock(_component_SVGIcon, {
    title: _ctx.name,
    path: _ctx.iconPath,
    size: _ctx.size
  }, null, 8, ["title", "path", "size"]))
}