import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  type: "button",
  class: "btn-unstyled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGIcon = _resolveComponent("SVGIcon")!
  const _component_tippy = _resolveComponent("tippy")!

  return (_openBlock(), _createBlock(_component_tippy, {
    class: _normalizeClass(['hint', _ctx.type]),
    placement: _ctx.position,
    interactive: true,
    arrow: true,
    theme: 'hint'
  }, {
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    default: _withCtx(() => [
      _createElementVNode("button", _hoisted_1, [
        (_ctx.type === 'info')
          ? (_openBlock(), _createBlock(_component_SVGIcon, {
              key: 0,
              path: _ctx.mdiInformationOutline,
              size: _ctx.iconSize,
              type: "is-light"
            }, null, 8, ["path", "size"]))
          : (_ctx.type === 'warning')
            ? (_openBlock(), _createBlock(_component_SVGIcon, {
                key: 1,
                path: _ctx.mdiAlert,
                size: _ctx.iconSize,
                type: "is-warning"
              }, null, 8, ["path", "size"]))
            : (_ctx.type === 'error')
              ? (_openBlock(), _createBlock(_component_SVGIcon, {
                  key: 2,
                  path: _ctx.mdiAlert,
                  size: _ctx.iconSize,
                  type: "is-danger"
                }, null, 8, ["path", "size"]))
              : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["class", "placement"]))
}