// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { mapStores } from "pinia";
import { useUIGeneralStore } from "@/stores/ui-general.store";
import { useUIFilesStore } from "@/stores/ui-files.store";

const RouteManagementMixin = {
  computed: {
    ...mapStores(useUIGeneralStore, useUIFilesStore),
  },
  watch: {
    $route: function (to, from) {
      if (to.meta.heading) {
        this.uiGeneralStore.setPageHeading(to.meta.heading);
      }
      if (to.query.sidebar && to.query.sidebar.match(/file-([0-9]*)/)) {
        const fileId = Number(to.query.sidebar.match(/file-([0-9]*)/)[1]);
        this.uiFilesStore.openFileSidebar(fileId);
      }
      if (to.name === "files" && to.path !== from.path) {
        this.uiGeneralStore.closeAllSidebarsExceptFiles();
      } else if (to.path !== from.path) {
        this.uiGeneralStore.closeAllSidebars();
      }
    }
  }
};
export default RouteManagementMixin;
