// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

const CopyToClipboardMixin = {
  methods: {
    copyToClipboard(text: string): void {
      navigator.clipboard.writeText(text).then(
        () => {
          this.$buefy.toast.open("Copied!");
        },
        (err: Error) => {
          this.$buefy.toast.open("Couldn't copy text!");
          console.error(err);
        }
      );
    }
  }
};
export default CopyToClipboardMixin;
