// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import * as microsoftTeams from "@microsoft/teams-js";
import PopulateStoreMixin from "@/mixins/populate-store";
import { mapActions, mapGetters } from "vuex";
import { TrackJS } from "trackjs";
import cnst from "@/utils/constants";

interface Data {
  teamsUserName: string | undefined;
}

const TeamsMixin = {
  data(): Data {
    return {
      teamsUserName: ""
    };
  },
  mixins: [PopulateStoreMixin],
  computed: { ...mapGetters("currentUser", ["currentUser"]) },
  methods: {
    attemptTeamsSSO(loginCallback?: Function): void {
      const that = this;
      const authTokenRequest = {
        successCallback(result: string): void {
          that.exchangeClientTokenForServerToken(result).then((): void => {
            that.$store
              .dispatch("currentUser/populateCurrentUser")
              .then((): void => {
                TrackJS.configure({
                  userId: `${that.currentUser.id}`
                });
                TrackJS.addMetadata("logged-in-via", "teams");
                loginCallback && loginCallback();
              });
          });
        },
        failureCallback(error: unknown): void {
          console.error(cnst.failure + " " + error);
        }
      };
      microsoftTeams.authentication.getAuthToken(authTokenRequest);
      microsoftTeams.getContext(context => {
        this.teamsUserName =
          Object.keys(context).length > 0 ? context["upn"] : "";
      });
    },
    async exchangeClientTokenForServerToken(token: string): Promise<void> {
      const serverURL = `${process.env.VUE_APP_API_URL}getAccessToken?ssoToken=${token}`;
      const response = (await fetch(serverURL).catch(
        this.unhandledFetchError
      )) as Response;

      const data = await response.json().catch(this.unhandledFetchError);

      if (!response.ok && data.code === 65001) {
        this.showConsentDialog();
      } else if (!response.ok) {
        console.error(cnst.failure + " " + data);
      } else {
        if (data && data.data && data.data.auth && data.data.auth.token) {
          this.authorizeByToken(data.data.auth.token);
          this.populateStoreWithGeneralData();
        }
      }
    },
    unhandledFetchError(err: Error): void {
      console.error(cnst.unhandledError + " ", err);
    },
    showConsentDialog(): void {
      microsoftTeams.authentication.authenticate({
        url: window.location.origin + "/auth-start",
        width: 600,
        height: 535,
        successCallback: (result: string | undefined): void => {
          this.consentSuccess(result);
        },
        failureCallback: (reason: string | undefined): void => {
          this.consentFailure(reason);
        }
      });
    },
    consentSuccess(result: string | undefined): void {
      // console.log("Success: " + result);
    },
    consentFailure(reason: string | undefined): void {
      console.error(cnst.consentFailed + " ", reason);
    },
    ...mapActions("currentUser", ["authorizeByToken"])
  } // methods
};
export default TeamsMixin;
