<template>
  <div class="modal-card" data-testing="upload-file-card">
    <div class="modal-card-header">
      <h3>
        Upload a new version of <i>{{ file.name }}</i>
      </h3>
    </div>
    <form
      id="upload-file-version"
      class="modal-card-content"
      @submit.prevent="handleFileUpload"
    >
      <label class="margin-b-half">
        Select an Attachment
        <input
          type="file"
          id="file-version-input"
          ref="file-version-input"
          data-testing="file-version-input"
          @change="fetchLocalFile"
        />
      </label>
    </form>
    <div class="modal-card-footer">
      <button
        data-testing="upload-file-version-btn"
        :disabled="!fileToUpload"
        class="btn-1 full-width"
        form="upload-file-version"
      >
        Upload new version
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import FilesMixin from "@/mixins/files";
import { SectionFile } from "@/models/product-section.model";
import { mapStores } from "pinia";
import { useUIFilesStore } from "@/stores/ui-files.store";

interface Data {
  fileToUpload: File | undefined;
}

export default defineComponent({
  props: {
    file: {
      required: true,
      type: Object as PropType<SectionFile>
    },
    productId: {
      required: true,
      type: [Number, String]
    }
  },
  mixins: [FilesMixin],
  data: (): Data => {
    return {
      fileToUpload: undefined
    };
  },
  computed: {
    ...mapStores(useUIFilesStore)
  },
  methods: {
    fetchLocalFile(): void {
      const fileInput = this.$refs["file-version-input"] as HTMLInputElement;
      if (fileInput.files) {
        this.fileToUpload = fileInput.files[0];
      }
    },
    handleFileUpload(): void {
      if (!this.file.is_external && this.fileToUpload) {
        const formData = new FormData();
        formData.append("file", this.fileToUpload);
        formData.append("id", String(this.file.id));
        this.uploadFileVersion({
          formData: formData,
          productId: Number(this.productId)
        });
      } else {
        console.log("update path for external file");

        //        this.upsertFile({
        //          id: this.uiFilesStore.fileForDisplay,
        //          location: newFile location,
        //          productId: this.displayFile.section.product_id
        //        })
      }
      if (this.$parent) {
        (this.$parent as unknown as { close: () => void }).close();
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.modal-card {
  width: auto;
}
form {
  min-width: 23rem;
}
</style>
