import { toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-57fd27f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["textContent"]
const _hoisted_2 = {
  class: "btn-3 right-icon dropdown-btn",
  "data-testing": "file-menu"
}
const _hoisted_3 = {
  key: 1,
  class: "margin-b-half",
  "data-testing": "file-path-sb"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  class: "margin-b-half",
  "data-testing": "files-attached-sb"
}
const _hoisted_6 = { class: "pl-0" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 2,
  class: "margin-b-half"
}
const _hoisted_9 = {
  class: "versions margin-b-half",
  "data-testing": "versions-container"
}
const _hoisted_10 = {
  class: "date",
  "data-testing": "blackout"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["href"]
const _hoisted_13 = {
  key: 3,
  class: "margin-b-half"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGIcon = _resolveComponent("SVGIcon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_RightSidebarTitle = _resolveComponent("RightSidebarTitle")!
  const _component_RightSidebar = _resolveComponent("RightSidebar")!
  const _directive_editable = _resolveDirective("editable")!

  return (_openBlock(), _createBlock(_component_RightSidebar, {
    onCloseSidebar: _ctx.uiFilesStore.closeFileSidebar,
    "is-sidebar-open": !!_ctx.displayFile,
    "data-testing": 'display-file-sidebar',
    "close-btn-data-testing": 'close-file-sb',
    "is-close-btn-visible": true,
    "is-updating": false,
    "css-classes": ['display-file-sidebar']
  }, {
    title: _withCtx(() => [
      _withDirectives(_createElementVNode("h3", {
        "data-testing": "edit-file-name",
        onEdited: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateName && _ctx.updateName(...args))),
        textContent: _toDisplayString(_ctx.displayFile.name)
      }, null, 40, _hoisted_1), [
        [_directive_editable, _ctx.uiGeneralStore.isEditModeEnabled]
      ])
    ]),
    body: _withCtx(() => [
      (_ctx.uiGeneralStore.isEditModeEnabled && _ctx.canRemoveFile)
        ? (_openBlock(), _createBlock(_component_b_dropdown, {
            key: 0,
            class: "is-block sidebar-dropdown-menu",
            "aria-role": "menu",
            position: "is-bottom-left",
            hoverable: "",
            "mobile-modal": false
          }, {
            trigger: _withCtx(() => [
              _createElementVNode("button", _hoisted_2, [
                _createVNode(_component_SVGIcon, {
                  path: _ctx.mdiDotsHorizontal,
                  size: 24
                }, null, 8, ["path"])
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("span", null, [
                _createVNode(_component_b_dropdown_item, {
                  "aria-role": "menu-item",
                  focusable: false,
                  custom: true
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("button", {
                      class: "btn-unstyled btn-3 full-width",
                      type: "button",
                      "data-testing": "delete-file-btn",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.confirmRemoval && _ctx.confirmRemoval(...args)))
                    }, [
                      _createVNode(_component_SVGIcon, { path: _ctx.mdiDelete }, null, 8, ["path"]),
                      _createTextVNode(" Delete Attachment ")
                    ])
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.displayFile.is_external)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_RightSidebarTitle, {
              title: _ctx.fileLocationTitle + ':'
            }, null, 8, ["title"]),
            (!_ctx.fileLocationIsLocal)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "location-link",
                  "data-testing": "location-link",
                  href: _ctx.displayFile.location,
                  target: "_blank"
                }, _toDisplayString(_ctx.displayFile.location), 9, _hoisted_4))
              : _createCommentVNode("", true),
            (_ctx.fileLocationIsLocal)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  title: "Copy path",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.copyToClipboard(_ctx.displayFile.location)))
                }, [
                  _createVNode(_component_SVGIcon, { path: _ctx.mdiContentCopy }, null, 8, ["path"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.displayFile.location), 1)
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_RightSidebarTitle, { title: 'Attached to:' }),
        _createElementVNode("ul", _hoisted_6, [
          (_ctx.attachments.length === 0)
            ? (_openBlock(), _createElementBlock("li", _hoisted_7, " Not attached to any content "))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachments, (attachment) => {
            return (_openBlock(), _createElementBlock("li", {
              key: attachment.index,
              "data-testing": "attached-to"
            }, _toDisplayString(attachment.name), 1))
          }), 128))
        ])
      ]),
      (!_ctx.displayFile.is_external)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_RightSidebarTitle, { title: 'Versions:' }),
            _createElementVNode("ul", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.versions, (version) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "version",
                  "data-testing": "version",
                  key: version.id
                }, [
                  _createElementVNode("small", _hoisted_10, _toDisplayString(version.displayDateTime), 1),
                  (version.userDisplayName)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_11, _toDisplayString(version.userDisplayName) + ": ", 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("a", {
                    href: version.location,
                    target: "_blank"
                  }, "view", 8, _hoisted_12)
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.displayFile.is_external)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_RightSidebarTitle, { title: 'Upload a new Version:' }),
            _createElementVNode("form", {
              onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.uploadNewVersion && _ctx.uploadNewVersion(...args)), ["prevent"]))
            }, [
              _createElementVNode("input", {
                class: "margin-b-quarter",
                type: "file",
                ref: "new-version",
                onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.prepareNewVersion && _ctx.prepareNewVersion(...args)))
              }, null, 544),
              (_ctx.newVersion)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn-1 full-width",
                    ref: "upload-new-version",
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.uploadNewVersion && _ctx.uploadNewVersion(...args)))
                  }, " Submit ", 512))
                : _createCommentVNode("", true)
            ], 32)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onCloseSidebar", "is-sidebar-open"]))
}