import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4a261276"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal-card attach-file",
  "data-testing": "attach-file-card"
}
const _hoisted_2 = { class: "modal-card-header" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "modal-card-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadForm = _resolveComponent("UploadForm")!
  const _component_b_tab_item = _resolveComponent("b-tab-item")!
  const _component_OnlineForm = _resolveComponent("OnlineForm")!
  const _component_FileLibraryForm = _resolveComponent("FileLibraryForm")!
  const _component_b_tabs = _resolveComponent("b-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, [
      (_ctx.uiFilesStore.shouldBeSetAsMediaContent)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Set Media Content for: "))
        : (_openBlock(), _createElementBlock("span", _hoisted_4, "Attach content to ")),
      _createElementVNode("i", null, _toDisplayString(_ctx.name), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_b_tabs, {
        "data-testing": "tabs",
        modelValue: _ctx.activeTab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
        "destroy-on-hide": true,
        animated: false,
        position: 'is-centered',
        expanded: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_tab_item, { label: "Upload" }, {
            default: _withCtx(() => [
              _createVNode(_component_UploadForm)
            ]),
            _: 1
          }),
          _createVNode(_component_b_tab_item, { label: "Link to online content" }, {
            default: _withCtx(() => [
              _createVNode(_component_OnlineForm)
            ]),
            _: 1
          }),
          _createVNode(_component_b_tab_item, { label: "Select from Library" }, {
            default: _withCtx(() => [
              _createVNode(_component_FileLibraryForm)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}