<template>
  <form @submit.prevent="attachNewFile">
    <label class="margin-b-standard">
      Link to online content
      <Hint :type="'info'" :position="'right'">
        <p>This could be a link to a sharepoint doc, or even a youtube video</p>
      </Hint>
      <input
        id="file-location"
        ref="first-input"
        :class="{ error: isLocationInValid }"
        @focus="resetLocationValidator"
        @blur="onLocationBlur"
        v-model="fileLocation"
        data-testing="attach-file-location"
      />
      <small v-if="isLocationInValid" class="error">
        <SVGIcon :path="mdiAlert" />
        Must be a valid address
      </small>
    </label>
    <label class="margin-b-standard">
      Name
      <Hint :type="'info'" :position="'right'">
        <p>Good naming makes it easier to find files/references later</p>
      </Hint>
      <input v-model="fileName" data-testing="attach-new-file-name" />
    </label>

    <button
      data-testing="attach-file-btn"
      :disabled="disableButton"
      class="btn-1 full-width submit-btn"
    >
      <span v-if="uiFilesStore.shouldBeSetAsMediaContent">Set as Media Content</span>
      <span v-else>Attach content</span>
    </button>
  </form>
</template>
<script lang="ts">
import FilesMixin from "@/mixins/files";
import Hint from "@/components/Hint.vue";
import { mapGetters } from "vuex";
import fileHelpers from "@/utils/file-helpers";
import {
  OnlineFileToAttachToSection,
  OnlineFileToAttachToProduct
} from "@/models/api/files.model";
import { defineComponent } from "vue";
import { mdiAlert } from "@mdi/js";
import { mapStores } from "pinia";
import { useUIFilesStore } from "@/stores/ui-files.store";

interface Data {
  fileName: string;
  fileLocation: string;
  isLocationInValid: boolean;
  isFileAttached: boolean;
  fileToSetAsMediaContentId: number | undefined;
  mdiAlert: string;
}

export default defineComponent({
  name: "OnlineForm",
  mixins: [FilesMixin],
  data(): Data {
    return {
      fileName: "",
      fileLocation: "",
      isLocationInValid: false,
      isFileAttached: true,
      fileToSetAsMediaContentId: undefined,
      mdiAlert
    };
  },
  components: {
    Hint
  },
  computed: {
    ...mapStores(useUIFilesStore),
    ...mapGetters("files", ["files"]),
    isCurrentLocationInValid(): boolean {
      return (
        !fileHelpers.isAValidURL(this.fileLocation) &&
        !fileHelpers.isAValidPathToTheLocalFile(this.fileLocation)
      );
    },
    disableButton(): boolean {
      return (
        !this.fileName || !this.fileLocation || this.isCurrentLocationInValid
      );
    }
  },
  methods: {
    validateFileLocation(): void {
      if (this.fileLocation.length > 0) {
        this.isLocationInValid = this.isCurrentLocationInValid;
      }
    },
    onLocationBlur(): void {
      if (!this.fileName && !this.isCurrentLocationInValid) {
        this.fileName = fileHelpers.getFileNameFromURL(this.fileLocation);
      }
      this.validateFileLocation();
    },
    resetLocationValidator(): void {
      this.isLocationInValid = false;
    },
    attachNewFileToSection(): void {
      if (this.uiFilesStore.sectionToAttachFile) {
        const payload = {
          productId: this.uiFilesStore.sectionToAttachFile.product_id,
          name: this.fileName,
          location: this.fileLocation,
          section_id: this.uiFilesStore.sectionToAttachFile.id,
          is_media_content: this.uiFilesStore.shouldBeSetAsMediaContent
        } as OnlineFileToAttachToSection;
        this.addOnlineAttachment(payload);
      }
    },
    attachNewFileToProduct(): void {
      if (this.uiFilesStore.productToAttachFile) {
        this.addOnlineAttachment({
          productId: this.uiFilesStore.productToAttachFile.id,
          product_id: this.uiFilesStore.productToAttachFile.id,
          name: this.fileName,
          location: this.fileLocation,
          attachment_type: this.uiFilesStore.fileAttachmentType
        } as OnlineFileToAttachToProduct);
      }
    },
    attachNewFile(): void {
      if (this.isLocationInValid) {
        return;
      }
      if (this.uiFilesStore.sectionToAttachFile) {
        this.attachNewFileToSection();
      } else {
        this.attachNewFileToProduct();
      }
      if (this.isFileAttached) {
        this.uiFilesStore.closeAttachFileModal();
      }
    },
    focusOnFileLocation(): void {
      const firstInput = document.getElementById("file-location");
      if (firstInput) {
        firstInput.focus();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.focusOnFileLocation();
    });
  }
});
</script>
<style lang="scss" scoped></style>
