<template>
  <ul
    v-if="stateCount && stateCount > 1 && stateCount < 9"
    class="workflow-icons"
    :data-testing="'state-' + state.name.toLowerCase().replace(/ /g, '-')"
  >
    <WorkflowIconItem
      v-for="item in stateCount"
      :key="item"
      :state-rank="state.rank"
      :current-index="item"
      :is-final-step="state.isFinalState"
    />
  </ul>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import WorkflowIconItem from "@/components/WorkflowIconItem.vue";
import { WorkflowState } from "@/models/workflow.model";

export default defineComponent({
  props: {
    stateCount: {
      type: Number,
      required: true
    },
    state: {
      required: true,
      type: Object as PropType<WorkflowState>
    }
  },
  components: {
    WorkflowIconItem
  }
});
</script>

<style lang="scss" scoped>
$circleWidth: 1rem;
ul.workflow-icons {
  float: left;
  position: relative;
  width: calc(#{$circleWidth} * 8 + 0.5rem);
  height: calc(#{$circleWidth} + 0.5rem);
  margin: 0;
  padding: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0.25rem;
    right: 0.25rem;
    z-index: 1;
    margin-top: -0.5px;
    height: 1px;
    background-color: $dark;
  }
}
</style>
