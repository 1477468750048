<template>
  <div class="margin-b-standard">
    <RightSidebarTitle :title="'Media Content'" />
    <div v-if="filesToDisplay.length > 0">
      <FilesList
        :filesToDisplay="filesToDisplay"
        :section="section"
        :is-attachments-table="false"
      />
      <button
        class="btn-3"
        type="button"
        @click="$emit('previewAttachment', filesToDisplay[0].location)"
      >
        <SVGIcon :path="mdiPresentationPlay" />
        Preview
      </button>
    </div>
    <div v-if="isStartMessageVisible" class="start-message">
      <p
        >Add media content in one of the supported formats (upload/external
        link)</p
      >
      <button class="btn-3" type="button" @click="openFileModal">
        <SVGIcon :path="mdiPlus" />
        Add Content
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import RightSidebarTitle from "@/components/right-sidebars/ui/RightSidebarTitle.vue";
import FilesList from "@/components/right-sidebars/content-sidebar/authoring-tool/FilesList.vue";
import { LibraryFile } from "@/models/files.model";
import { mapGetters } from "vuex";
import { ContentStructureSection } from "@/models/content-structure.model";
import { SectionFile } from "@/models/product-section.model";
import { mdiPresentationPlay, mdiPlus } from "@mdi/js";
import { mapStores } from "pinia";
import { useUIFilesStore } from "@/stores/ui-files.store";

type FileToDisplay = SectionFile | LibraryFile | UnavailableFile;

interface Data {
  isFilesToDisplayLoading: boolean;
  filesToDisplay: FileToDisplay[];
  mdiPresentationPlay: string;
  mdiPlus: string;
}
interface UnavailableFile {
  name: string;
  location: string;
  is_external: boolean;
}

export default defineComponent({
  name: "MediaContent",
  props: {
    section: {
      type: Object as PropType<ContentStructureSection>,
      required: true
    }
  },
  data: (): Data => {
    return {
      mdiPresentationPlay,
      mdiPlus,
      isFilesToDisplayLoading: true,
      filesToDisplay: [] as FileToDisplay[]
    };
  },
  components: {
    RightSidebarTitle,
    FilesList
  },
  computed: {
    ...mapStores(useUIFilesStore),
    ...mapGetters("files", ["files"]),
    isStartMessageVisible(): boolean {
      return !this.isFilesToDisplayLoading && this.filesToDisplay.length === 0;
    }
  },
  methods: {
    openExternalContent(): void {
      window.open(this.filesToDisplay[0].location, "_blank");
    },
    getFileById<T extends SectionFile | LibraryFile>(
      filesArray: T[]
    ): T | undefined {
      // If a file was attached after the media content logic changes
      return filesArray.find((file: T): boolean => {
        return (
          file.id === this.section.authored_content.externalContent.attachmentId
        );
      });
    },
    getFileByLocation<T extends SectionFile | LibraryFile>(
      filesArray: T[]
    ): T | undefined {
      // If a file was attached before the media content logic changes
      return filesArray.find((file: T): boolean => {
        return file.location === this.section.authored_content.externalContent;
      });
    },
    getFilesToDisplay(): void {
      this.filesToDisplay = [];
      this.isFilesToDisplayLoading = true;
      if (
        !!this.section.authored_content &&
        !!this.section.authored_content.externalContent
      ) {
        let currentFile: LibraryFile | SectionFile | undefined = undefined;
        // This is needed due to a change in the logic of setting a file as media content.
        // First, we look for whether our file is in the enabled files in a section,
        // so that we can detach the file from the section using the file drop-down menu
        // in the media content section
        if (this.section.enabled_files.length > 0) {
          if (
            this.section.authored_content.externalContent.hasOwnProperty(
              "attachmentId"
            )
          ) {
            currentFile = this.getFileById(this.section.enabled_files);
          } else {
            // If the file was attached before the logic changes
            currentFile = this.getFileByLocation(this.section.enabled_files);
          }
        }

        // If a file is not in the enabled files of a section,
        // we look for it in the files library to get the necessary information for the file
        if (!currentFile) {
          if (
            this.section.authored_content.externalContent.hasOwnProperty(
              "attachmentId"
            )
          ) {
            currentFile = this.getFileById(this.files.data);
          } else {
            currentFile = this.getFileByLocation(this.files.data);
          }
        }
        if (currentFile) {
          this.filesToDisplay.push(currentFile);
        } else {
          // If the file is not in our file library and was attached before the media content logic changes
          this.filesToDisplay.push({
            name: this.section.authored_content.externalContent,
            location: this.section.authored_content.externalContent,
            is_external: true
          });
        }
      }
      this.isFilesToDisplayLoading = false;
    },
    openFileModal(): void {
      this.uiFilesStore.openAttachFileModal({
        section: this.section,
        shouldBeSetAsMediaContent: true
      });
    }
  },
  mounted() {
    this.getFilesToDisplay();
  },
  watch: {
    "section.authored_content"() {
      this.getFilesToDisplay();
    }
  }
});
</script>
<style scoped lang="scss">
.start-message {
  p {
    padding-bottom: 0;
    font-size: 0.875rem;
  }
}
</style>
