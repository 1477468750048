import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_autocomplete = _resolveComponent("b-autocomplete")!
  const _component_b_field = _resolveComponent("b-field")!
  const _component_TagsList = _resolveComponent("TagsList")!
  const _component_Collapse = _resolveComponent("Collapse")!

  return (_openBlock(), _createBlock(_component_Collapse, null, {
    title: _withCtx(() => [
      _createTextVNode("Curriculum Tags")
    ]),
    content: _withCtx(() => [
      (_ctx.showConceptsSelector)
        ? (_openBlock(), _createBlock(_component_b_field, {
            key: 0,
            label: "Concepts"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_autocomplete, {
                modelValue: _ctx.typedConcept,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.typedConcept) = $event)),
                data: _ctx.filteredConceptsByTopics,
                onSelect: _ctx.processSelectedConcept,
                placeholder: "e.g. Adding fractions",
                "group-field": "topicName",
                "group-options": "conceptsNames",
                "confirm-keys": ['Enter'],
                "keep-first": "",
                "open-on-focus": "",
                clearable: "",
                "clear-on-select": ""
              }, null, 8, ["modelValue", "data", "onSelect"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.showConceptsSelector)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.message), 1))
        : _createCommentVNode("", true),
      (_ctx.showTags)
        ? (_openBlock(), _createBlock(_component_TagsList, {
            key: 2,
            tags: _ctx.sectionTagsWithNames,
            onRemoveTag: _ctx.removeConceptFromSection,
            "can-edit-content": _ctx.canEditContent
          }, null, 8, ["tags", "onRemoveTag", "can-edit-content"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}