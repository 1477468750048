<template>
  <transition name="sidebar-slide">
    <div
      v-if="isSidebarOpen"
      :class="['right-sidebar', { updating: isUpdating }, ...cssClasses]"
      :data-testing="dataTesting"
    >
      <slot name="additional-content"></slot>

      <div class="sidebar">
        <slot name="expand-btn"></slot>
        <div class="sidebar-top">
          <div class="sidebar-top-in">
            <slot name="title"></slot>
            <slot name="dropdown"></slot>
            <button
              v-if="isCloseBtnVisible"
              type="button"
              class="close-btn btn-3"
              title="close"
              aria-label="close"
              @click="$emit('closeSidebar')"
              :data-testing="closeBtnDataTesting"
            >
              <SVGIcon :path="mdiClose" type="is-white" :size="24" />
            </button>
          </div>
          <slot name="subtitle"></slot>
        </div>

        <div class="right-sidebar-body no-sticky-buttons">
          <slot name="body"></slot>
        </div>
      </div>

      <slot name="modals"></slot>
    </div>
  </transition>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { mdiClose } from "@mdi/js";

export default defineComponent({
  name: "RightSidebar",
  data() {
    return {
      mdiClose
    };
  },
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false,
      required: true
    },
    isUpdating: {
      type: Boolean,
      default: false,
      required: false
    },
    isCloseBtnVisible: {
      default: true,
      required: false,
      type: Boolean
    },
    dataTesting: {
      type: String,
      required: false,
      default: ""
    },
    closeBtnDataTesting: {
      type: String,
      required: false,
      default: "close-assets-sidebar-btn"
    },
    cssClasses: {
      type: Array as PropType<string[]>,
      required: false
    }
  }
});
</script>
<style lang="scss" scoped>
button.close-btn {
  svg {
    display: block;
  }
}
</style>
