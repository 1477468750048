<template>
  <div class="lower-header padding-h-standard">
    <span v-for="link in links" :key="link.title">
      <span v-if="!link.isEnabled" class="lower-header-link-disabled">{{
        link.title
      }}</span>
      <HeaderLink v-else :link="link" />
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import HeaderLink from "@/components/header/HeaderLink.vue";
import { HeaderNavigationLink } from "@/models/header.model";

export default defineComponent({
  name: "LowerHeader",
  props: {
    links: {
      required: true,
      type: Array as PropType<HeaderNavigationLink[]>
    }
  },
  components: {
    HeaderLink
  }
});
</script>
<style lang="scss" scoped>
.lower-header {
  display: flex;
  justify-content: flex-start;
  height: 1.5rem;
  box-shadow: 0 0.25rem 0.25rem -0.125rem $grey20;
  + .lower-header {
    padding-top: 0.5rem;
    height: 2rem;
  }
  &-link-disabled {
    position: relative;
    top: 0.1875rem;
    margin-right: 1rem;
    font-size: 0.9rem;
    color: $grey40;
    user-select: none;
  }
}
</style>
