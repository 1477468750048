import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["d", "fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.size,
    height: _ctx.size,
    viewBox: "0 0 24 24",
    class: _normalizeClass(_ctx.customClass)
  }, [
    _createElementVNode("path", {
      d: _ctx.path,
      fill: _ctx.fillColor
    }, null, 8, _hoisted_2)
  ], 10, _hoisted_1))
}