import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7653253d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upper-header padding-h-standard" }
const _hoisted_2 = { "data-testing": "page-header" }
const _hoisted_3 = { class: "btn-3 right-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVGIcon = _resolveComponent("SVGIcon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.uiGeneralStore.pageHeading), 1),
    _createVNode(_component_b_dropdown, {
      class: "is-block",
      "aria-role": "menu",
      position: "is-bottom-left",
      hoverable: "",
      "mobile-modal": false,
      "data-testing": "account-dropdown"
    }, {
      trigger: _withCtx(() => [
        _createElementVNode("button", _hoisted_3, [
          _createVNode(_component_SVGIcon, {
            path: _ctx.mdiAccount,
            size: 36
          }, null, 8, ["path"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_dropdown_item, {
          "aria-role": "menu-item",
          focusable: false,
          custom: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SVGIcon, {
              path: _ctx.mdiAccount,
              class: "margin-r-half"
            }, null, 8, ["path"]),
            _createTextVNode(" " + _toDisplayString(_ctx.currentUser.displayName), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_b_dropdown_item, {
          "aria-role": "menu-item",
          focusable: false,
          custom: true
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              "data-testing": "logout-button",
              class: "btn-3",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout()))
            }, [
              _createVNode(_component_SVGIcon, {
                path: _ctx.mdiLogout,
                class: "margin-r-half"
              }, null, 8, ["path"]),
              _createTextVNode(" Log out ")
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}