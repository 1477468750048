<template>
  <div>
    <table class="simple-table mb-0">
      <thead>
        <tr>
          <th>Name</th>
          <th>Size</th>
          <th>Date Added</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <FilesListItem
          v-for="file in filesToDisplay"
          :key="file.id ? file.id : file.location"
          :file="file"
          :is-attachment-table="isAttachmentsTable"
          :hasExternalContentAuthoring="hasExternalContentAuthoring"
          :is-set-as-media-content="
            isFileSetAsMediaContent(file.location, file.id)
          "
          @launchRenamePopup="launchRenamePopup"
          @openUploadVersionModal="openUploadVersionModal"
          @showFileInLibrary="showFileInLibrary"
          @launchRemoveAttachmentDialog="launchRemoveAttachmentDialog"
          @setAsMediaContent="
            setAsMediaContent(file.location, file.id, section)
          "
          @unsetAsMediaContent="unsetAsMediaContent(section)"
          @previewAttachment="previewAttachment"
        />
      </tbody>
    </table>

    <b-modal
      :model-value="isFileVersionModalOpen"
      :has-modal-card="true"
      :trap-focus="true"
      aria-role="dialog"
      :aria-modal="true"
      @cancel="uiFilesStore.toggleFileModal"
    >
      <UploadFileVersion
        :file="fileForNewVersion"
        :productId="section.product_id"
      ></UploadFileVersion>
    </b-modal>

    <b-modal
      :model-value="isRemoveAttachmentPopupOpen"
      :has-modal-card="true"
      :trap-focus="true"
      aria-role="dialog"
      :aria-modal="true"
      @cancel="uiFilesStore.toggleFileModal"
    >
      <RemoveAttachment
        :file="fileForRemoveLink"
        :section="section"
        :shouldBeUnsetAsMediaContent="
          fileForRemoveLink &&
          isFileSetAsMediaContent(
            fileForRemoveLink.location,
            fileForRemoveLink.id
          )
        "
      ></RemoveAttachment>
    </b-modal>

    <b-modal
      :model-value="isRenameAttachmentPopupOpen"
      :file="fileForRenaming"
      :has-modal-card="true"
      :trap-focus="true"
      aria-role="dialog"
      :aria-modal="true"
      @cancel="uiFilesStore.toggleFileModal"
    >
      <RenameAttachment :file="fileForRenaming"></RenameAttachment>
    </b-modal>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import FilesMixin from "@/mixins/files";
import cnst from "@/utils/constants";
import FilesListItem from "@/components/right-sidebars/content-sidebar/authoring-tool/FilesListItem.vue";
import UploadFileVersion from "@/components/popups/attachments/UploadFileVersion.vue";
import RemoveAttachment from "@/components/popups/attachments/RemoveAttachment.vue";
import RenameAttachment from "@/components/popups/attachments/RenameAttachment.vue";
import { LibraryFile } from "@/models/files.model";
import { mapGetters } from "vuex";
import { ContentStructureSection } from "@/models/content-structure.model";
import { SectionFile } from "@/models/product-section.model";
import { mapStores } from "pinia";
import { useUIFilesStore } from "@/stores/ui-files.store";

interface Data {
  fileForNewVersion: SectionFile | LibraryFile | undefined;
  fileForRemoveLink: SectionFile | undefined;
  fileForRenaming: LibraryFile | undefined;
  isFileVersionModalOpen: boolean;
  isRemoveAttachmentPopupOpen: boolean;
  isRenameAttachmentPopupOpen: boolean;
}
interface UnavailableFile {
  name: string;
  location: string;
  is_external: boolean;
}

export default defineComponent({
  name: "FilesList",
  mixins: [FilesMixin],
  data: (): Data => {
    return {
      fileForNewVersion: undefined,
      fileForRemoveLink: undefined,
      fileForRenaming: undefined,
      isFileVersionModalOpen: false,
      isRemoveAttachmentPopupOpen: false,
      isRenameAttachmentPopupOpen: false
    };
  },
  props: {
    section: {
      type: Object as PropType<ContentStructureSection>,
      required: true
    },
    filesToDisplay: {
      required: true,
      type: Array as PropType<SectionFile[] | LibraryFile[] | UnavailableFile[]>
    },
    isAttachmentsTable: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  components: {
    FilesListItem,
    UploadFileVersion,
    RemoveAttachment,
    RenameAttachment
  },
  computed: {
    ...mapStores(useUIFilesStore),
    ...mapGetters("files", ["files"]),
    hasExternalContentAuthoring(): boolean {
      return cnst.isExternalContentAuthoringToolId(
        this.section && this.section.type.tool_id
      );
    }
  },
  methods: {
    openUploadVersionModal(file: SectionFile | LibraryFile): void {
      this.uiFilesStore.toggleFileModal();
      this.isFileVersionModalOpen = true;
      this.fileForNewVersion = file;
    },
    launchRenamePopup(file: SectionFile | LibraryFile): void {
      this.uiFilesStore.toggleFileModal();
      this.fileForRenaming = (this as any).getFileObject(
        file.id
      ) as LibraryFile;
      this.isRenameAttachmentPopupOpen = true;
    },
    launchRemoveAttachmentDialog(file: SectionFile): void {
      this.uiFilesStore.toggleFileModal();
      this.isRemoveAttachmentPopupOpen = true;
      this.fileForRemoveLink = file;
    },
    showFileInLibrary(file: SectionFile | LibraryFile): void {
      const validURLFileName = file.name.split(".")[0];
      const query = {
        filters: `name--${validURLFileName}--default`,
        sidebar: `attachment-${file.id}`
      };

      this.$router.push({
        path: "/attachments",
        query: query
      });
    },
    getFileObject(fileId: number): LibraryFile {
      return this.files.data.find((f: LibraryFile): boolean => f.id === fileId);
    },
    isFileSetAsMediaContent(fileLocation: string, fileId: number): boolean {
      return (
        this.hasExternalContentAuthoring &&
        !!this.section.authored_content &&
        (fileId ===
          this.section.authored_content.externalContent.attachmentId ||
          fileLocation === this.section.authored_content.externalContent)
      );
    },
    previewAttachment(fileLocation: string): void {
      this.$emit("previewAttachment", fileLocation);
    }
  }
});
</script>
<style scoped lang="scss"></style>
