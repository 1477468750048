import DAO from "@/api/DAO";
import { PERMISSIONS_URL, USER_URL } from "@/api/URL-constants";
import { AxiosResponse } from "axios";
import { GetAllResponse } from "@/models/api/users.model";
import { PermissionPayload } from "@/models/available-permissions.model";

// todo we don't need this request because we have getAllUsers request
const getAvailableOrgPermissions = (): Promise<
  AxiosResponse<GetAllResponse>
> => {
  return DAO().get(USER_URL);
};

const addUserPermissions = (
  payload: PermissionPayload
): Promise<AxiosResponse> => {
  return DAO().put(PERMISSIONS_URL, payload);
};

const removeUserPermissions = (
  payload: PermissionPayload
): Promise<AxiosResponse> => {
  const product = payload.product_id ? `/${payload.product_id}` : "";
  return DAO().delete(
    PERMISSIONS_URL + `/${payload.user_id}/${payload.permission_id}${product}`
  );
};

export {
  getAvailableOrgPermissions,
  addUserPermissions,
  removeUserPermissions
};
