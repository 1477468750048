import DAO from "@/api/DAO";
import { PROGRAMS_URL } from "@/api/URL-constants";
import cnst from "@/utils/constants";
import { AxiosResponse } from "axios";
import {
  GetAllProgramsResponse,
  ProgramToCreate,
  ProgramToCreateResponse,
  ProgramToUpdate
} from "@/models/api/program.model";
import { Programme } from "@/models/programme.model";

const getAll = (): Promise<Programme[]> => {
  return DAO()
    .get(PROGRAMS_URL)
    .then((response: AxiosResponse<GetAllProgramsResponse>): Programme[] => {
      if (response.status === 200) {
        return response.data.data;
      } else if (response.status === 204) {
        return [];
      } else {
        throw new Error(cnst.productError.loading + "s");
      }
    });
};

const create = (
  payload: ProgramToCreate
): Promise<AxiosResponse<ProgramToCreateResponse>> => {
  return DAO().post(PROGRAMS_URL, payload);
};

const update = (payload: ProgramToUpdate): Promise<AxiosResponse> => {
  return DAO().put(PROGRAMS_URL, payload);
};

const remove = (programId: number): Promise<AxiosResponse> => {
  return DAO().delete(`${PROGRAMS_URL}/${programId}`);
};

export { getAll, create, update, remove };
