<template>
  <li data-testing="state-circle">
    <SVGIcon
      v-if="isStepCompleted"
      title="complete"
      :path="mdiCheckboxMarkedCircleOutline"
      type="is-success"
      data-testing="complete"
    />
    <SVGIcon
      v-else-if="stateRank === currentIndex"
      title="on track"
      :path="mdiCircle"
      type="is-warning"
      data-testing="on-track"
    />
    <SVGIcon
      v-else
      title="on track"
      :path="mdiCircleOutline"
      type="is-dark"
      data-testing="on-track"
    />
  </li>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {
  mdiMenuDown,
  mdiMenuUp,
  mdiCircleOutline,
  mdiCircle,
  mdiCheckboxMarkedCircleOutline
} from "@mdi/js";

export default defineComponent({
  name: "WorkflowIconsItem",
  data() {
    return {
      mdiMenuDown,
      mdiMenuUp,
      mdiCircleOutline,
      mdiCircle,
      mdiCheckboxMarkedCircleOutline
    };
  },
  props: {
    stateRank: {
      required: true,
      type: Number
    },
    currentIndex: {
      required: true,
      type: Number
    },
    isFinalStep: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    isStepCompleted(): boolean {
      return (
        this.stateRank > this.currentIndex ||
        (this.stateRank === this.currentIndex && this.isFinalStep)
      );
    }
  }
});
</script>
<style lang="scss" scoped>
$circleWidth: 1rem;
li {
  position: relative;
  z-index: 2;
  list-style: none;
  margin: 0;
  padding: 0;
  width: $circleWidth;
  height: $circleWidth;
  border-radius: 50%;
  background-color: #ffffff;
}
svg {
  display: block;
  line-height: 1;
}
</style>
