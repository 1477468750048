<template>
  <div class="modal-card attach-file" data-testing="attach-file-card">
    <h3 class="modal-card-header">
      <span v-if="uiFilesStore.shouldBeSetAsMediaContent">Set Media Content for:&nbsp;</span>
      <span v-else>Attach content to&nbsp;</span>
      <i>{{ name }}</i>
    </h3>
    <div class="modal-card-content">
      <b-tabs
        data-testing="tabs"
        v-model="activeTab"
        :destroy-on-hide="true"
        :animated="false"
        :position="'is-centered'"
        :expanded="true"
      >
        <b-tab-item label="Upload">
          <UploadForm />
        </b-tab-item>
        <b-tab-item label="Link to online content">
          <OnlineForm />
        </b-tab-item>
        <b-tab-item label="Select from Library">
          <FileLibraryForm />
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ProductItem } from "@/models/product.model";
import OnlineForm from "@/components/popups/attachments/attach-file/OnlineForm.vue";
import UploadForm from "@/components/popups/attachments/attach-file/UploadForm.vue";
import FileLibraryForm from "@/components/popups/attachments/attach-file/FileLibraryForm.vue";
import { ContentStructureSection } from "@/models/content-structure.model";
import { mapStores } from "pinia";
import { useUIFilesStore } from "@/stores/ui-files.store";

const UPLOAD_FILE = 0;
const ONLINE_ATTACHMENT = 1;
const EXISTING_ATTACHMENT = 2;

interface Data {
  activeTab: number;
}

export default defineComponent({
  name: "AttachFile",
  data(): Data {
    return {
      activeTab: UPLOAD_FILE
    };
  },
  components: {
    OnlineForm,
    UploadForm,
    FileLibraryForm
  },
  computed: {
    ...mapStores(useUIFilesStore),
    target(): ContentStructureSection | ProductItem | null {
      if (this.uiFilesStore.sectionToAttachFile) {
        return this.uiFilesStore.sectionToAttachFile;
      }
      if (this.uiFilesStore.productToAttachFile) {
        return this.uiFilesStore.productToAttachFile;
      }
      return null;
    },
    name(): string {
      return this.target ? this.target.name : "";
    }
  }
});
</script>

<style lang="scss" scoped>
.attach-file {
  width: auto;
  min-width: 31rem;
}
</style>
<style lang="scss">
.attach-file {
  .b-tabs {
    .tabs ul {
      padding-left: 0;
    }
    .tab-content {
      padding: 1rem 0;
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-width: 23rem;
      min-height: 17.5rem;
      .submit-btn {
        margin-top: auto;
      }
    }
  }
}
</style>
