<template>
  <header id="main-header" :class="{ 'is-sidebar-open': uiGeneralStore.isSidebarOpen }">
    <UpperHeader />
    <LowerHeader v-if="productId" :links="productLinks" />
    <LowerHeader v-if="isContentPage" :links="contentLinks" />
    <LowerHeader
      v-if="isProductPermissionsPage"
      :links="productPermissionsLinks"
    />
  </header>
</template>

<script lang="ts">
import GetTasksQueryMixin from "@/mixins/get-tasks-query";
import FeatureFlagsMixin from "@/mixins/feature-flags";
import UserInterfaceFeatureFlaggerMixin from "@/mixins/user-interface-feature-flagger";
import UpperHeader from "@/components/header/UpperHeader.vue";
import LowerHeader from "@/components/header/LowerHeader.vue";
import { mapGetters } from "vuex";
import { HeaderNavigationLink } from "@/models/header.model";
import { ProductItem, ProductStatus } from "@/models/product.model";
import { defineComponent } from "vue";
import { mapStores } from "pinia";
import { useUIGeneralStore } from "@/stores/ui-general.store";

export default defineComponent({
  name: "main-header",
  mixins: [
    GetTasksQueryMixin,
    FeatureFlagsMixin,
    UserInterfaceFeatureFlaggerMixin
  ],
  components: {
    UpperHeader,
    LowerHeader
  },
  computed: {
    ...mapStores(useUIGeneralStore),
    ...mapGetters("currentUser", ["currentUser"]),
    ...mapGetters("products", ["productDetails"]),
    productId(): number | undefined {
      const pId = this.$route.params.productId;
      return pId ? Number(pId) : undefined;
    },
    product(): ProductItem | undefined {
      if (!this.productId) {
        return undefined;
      }
      return this.productDetails.data.find((product: ProductItem): boolean => {
        return product.id === this.productId;
      });
    },
    isProductArchived(): boolean {
      return !!this.product && this.product.status === ProductStatus.ARCHIVE;
    },
    currentRoute(): string | symbol | undefined {
      return this.$route.name;
    },
    isContentPage(): boolean {
      return (
        this.currentRoute === "content" ||
        this.currentRoute === "content-templates" ||
        this.currentRoute === "assets"
      );
    },
    isProductPermissionsPage(): boolean {
      return (
        this.currentRoute === "product-permissions" ||
        this.currentRoute === "feature-management"
      );
    },
    isContentAuthoringTier1UserFeatureEnabled(): boolean {
      return this.productId
        ? this.isContentAuthoringTier1FeatureEnabled(this.productId)
        : false;
    },
    isAssetManagementUserFeatureEnabled(): boolean {
      return this.productId
        ? this.isAssetManagementFeatureEnabled(this.productId)
        : false;
    },
    isWorkflowManagementUserFeatureEnabled(): boolean {
      return this.productId
        ? this.isWorkflowManagementFeatureEnabled(this.productId)
        : false;
    },
    productLinks(): HeaderNavigationLink[] {
      const defaultLinks = [
        {
          title: "Product",
          isHighlighted: this.currentRoute === "product",
          to: {
            name: "product",
            params: { productId: this.productId }
          },
          isEnabled: true
        },
        {
          title: "Content",
          dataTesting: "navigate-to-content",
          isHighlighted: this.isContentPage,
          to: {
            name: this.isContentAuthoringTier1UserFeatureEnabled
              ? "content"
              : "content-templates",
            params: { productId: this.productId }
          },
          isEnabled:
            this.isContentAuthoringTier1UserFeatureEnabled &&
            this.isAssetManagementUserFeatureEnabled &&
            !this.isProductArchived
        },
        {
          title: "Tasks",
          dataTesting: "navigate-to-tasks",
          isHighlighted: this.currentRoute === "productTasks",
          to: {
            name: "productTasks",
            params: { productId: this.productId },
            query: this.getTasksQuery() as unknown as Record<string, string>
          },
          isEnabled:
            this.isWorkflowManagementUserFeatureEnabled &&
            !this.isProductArchived
        },
        {
          title: "Progress",
          dataTesting: "navigate-to-progress",
          isHighlighted: this.currentRoute === "progress",
          to: {
            name: "progress",
            params: { productId: this.productId }
          },
          isEnabled:
            this.isWorkflowManagementUserFeatureEnabled &&
            !this.isProductArchived
        },
        {
          title: "Planning",
          dataTesting: "navigate-to-planning",
          isHighlighted: this.currentRoute === "planning",
          to: {
            name: "planning",
            params: { productId: this.productId }
          },
          isEnabled:
            this.isWorkflowManagementUserFeatureEnabled &&
            !this.isProductArchived
        },
        {
          title: "Product Permissions",
          isHighlighted:
            this.currentRoute === "product-permissions" ||
            this.currentRoute === "feature-management",
          to: {
            name: "product-permissions",
            params: { productId: this.productId }
          },
          isEnabled: !this.isProductArchived
        }
      ];
      if (!!this.product && this.product.adaptive_learning_systems.length > 0) {
        return [
          ...defaultLinks,
          {
            title: "Adaptive Learning Systems",
            isHighlighted:
              this.currentRoute === "adaptive-learning-systems-list" ||
              this.currentRoute === "adaptive-learning-system",
            to: {
              name: "adaptive-learning-systems-list",
              params: { productId: this.productId }
            },
            isEnabled:
              !!this.product &&
              this.product.adaptive_learning_systems.length > 0
          }
        ];
      }
      return defaultLinks;
    },
    contentLinks(): HeaderNavigationLink[] {
      return [
        {
          title: "Navigation",
          isHighlighted: this.currentRoute === "content",
          to: {
            name: "content",
            params: { productId: this.productId }
          },
          isEnabled: this.isContentAuthoringTier1UserFeatureEnabled
        },
        {
          title: "Assets",
          dataTesting: "navigate-to-workflows",
          isHighlighted:
            this.currentRoute === "content-templates" ||
            this.currentRoute === "assets",
          to: {
            name: "content-templates",
            params: { productId: this.productId }
          },
          isEnabled: this.isAssetManagementUserFeatureEnabled
        }
      ];
    },
    productPermissionsLinks(): HeaderNavigationLink[] {
      return [
        {
          title: "User Permissions",
          isHighlighted: this.currentRoute === "product-permissions",
          to: {
            name: "product-permissions",
            params: { productId: this.productId }
          },
          isEnabled: true
        },
        {
          title: "Feature Management",
          dataTesting: "feature-management",
          isHighlighted: this.currentRoute === "feature-management",
          to: {
            name: "feature-management",
            params: { productId: this.productId }
          },
          isEnabled: true
        }
      ];
    }
  }
});
</script>

<style lang="scss" scoped>
header {
  padding-left: $sidebar-width-sm;
  width: 100%;
  background-color: $bg-color;
  transition: padding-left 0.3s ease-out;
  &.is-sidebar-open {
    padding-left: $sidebar-width;
    @media (max-width: $breakpoint-md) {
      padding-left: $sidebar-width-md;
    }
  }
}
</style>
